import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ContentCode } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';
import { RemoteAPIService } from 'src/services/RemoteAPIService';

type ContentViewModel = {label: string, key: string}

@Component({
  selector: 'select-content-code',
  moduleId: module.id,
  templateUrl: 'select.content.code.component.html',
  styleUrls: ['select.content.code.component.css']
})
export class SelectContentCodeComponent implements OnInit {
    
  @Input() initialContent: any = null
  @Output() contentChanged = new EventEmitter<ContentCode>()

  remoteContentList: ContentCode[];
  contentList: ContentViewModel[]
  content: ContentViewModel = { label: null, key: null }

  constructor(private remote: RemoteAPIService) { }

  ngOnInit() {
    if (typeof this.initialContent === "object") {
      this.content = { label: this.initialContent.Name, key: this.initialContent._id };
    }
    
    console.log("Initialized");
    this.updateContentListFromRemote();
  }

  updateContentListFromRemote() {
    this.remote.get_list_contentCodes().toPromise().then( Contents => {
      this.remoteContentList = Contents.map( Content => {
        return Content
      });
      this.contentList = Contents.map( Content => ( {label: Content.Name, key: Content._id}) );    
      if(typeof this.initialContent === "string"){
        this.onContentChanged(this.initialContent);
      }
    });
  }

  onContentChanged(ContentKey) {
    console.log(ContentKey);
    this.content = this.contentList.find( Content => Content.key === ContentKey );
    const remoteContent = this.remoteContentList.find( Content => Content._id === ContentKey);
    this.contentChanged.emit(remoteContent);
  }
}
