import { Component, OnInit } from '@angular/core';
import { Localization } from '@gsegames/arena.models';
import { Editor } from '../editor';

@Component({
  selector: 'app-editor-translation',
  templateUrl: './editor-translation.component.html',
  styleUrls: ['./editor-translation.component.scss']
})
export class EditorTranslationComponent extends Editor<Localization> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
