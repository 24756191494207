import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ShakeEntry, ShakeType, CampaignEntry, RewardType, EnvelopeType, StickerGroup, Question, EnvelopedPrize, PrizeSet, PrizeType } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { injectTranslation, getTranslations, mainLanguage } from 'src/core/translations';
import { translationPrefix } from 'src/constants/translation-prefixes';
import { ValidationChain } from 'src/core/validations';
import { ViewModelState } from 'src/models/ViewModel';
import { SelectDatetimeComponent } from '../../selectors/select-datetime/select-datetime.component';
import { FilterWithDateTime } from '../../selectors/field-filter-with-datetime/field-filter-with-datetime.component';
import { RemoteAPIService } from 'src/services/RemoteAPIService';
import { RemoteService } from 'src/services/RemoteService';
import { element } from 'protractor';

@Component({
  selector: 'editor-shake-entry',
  templateUrl: './editor-shake-entry.component.html',
  styleUrls: ['./editor-shake-entry.component.scss']
})
export class EditorShakeEntryComponent extends Editor<ShakeEntry> implements OnInit {

  ShakeType = ShakeType // make the enum visible to the view

  shakeTypeNames: string[];
  selectedShakeType: ShakeType;

  envelopeTypes = EnvelopeType;
  rewardTypes = RewardType;

  couponCount = {};
  envelopePrizeList: EnvelopedPrize[];
  lastEnvelopedPrize: EnvelopedPrize;
  lockedBehindContent: boolean = false;
  verified : {Id: string, state: boolean }[] = [];

  translationPrefixMap = {
    'NameKey': translationPrefix.Shake.Name,
  };

  @ViewChildren(SelectDatetimeComponent) childrenSelectDate: QueryList<SelectDatetimeComponent>;

  constructor(private remoteAPI : RemoteAPIService, private remote: RemoteService) {
    super()

    this.shakeTypeNames = [
      'Sponsored',
      'Event',
      'Question',
      'Time',
      'Lightning'
    ]
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.envelopePrizeList) {
      this.envelopePrizeList = [];
      this.viewModel.envelopedPrizes = this.envelopePrizeList;
      this.remote.getPrizeSets().then(response =>{
        let prizeSets = response;
        prizeSets.forEach( element => {
          if(element.Type == PrizeType.Coupon){
          this.remoteAPI.get_prizeSetNumberAvailable(element._id).toPromise().then(response => {
            this.couponCount[element._id] = response.count;
          },
          err => {
            console.log(err.statusText);
          });
        }
        })
      },
      err =>{
        console.log(err.statusText);
      });
      console.log(this.couponCount);
      console.log("It is being created");
    }

    this.selectedShakeType = this.model.ShakeType;
    if (!this.model.CampaignId) {
      this.model.CampaignId = "";
    }

    if (this.model.LockedByContentId && this.model.LockedByContentId != null) {
      this.lockedBehindContent = true;
    }

    this.model.Date = new Date(this.model.Date);
    this.model.EndDate = new Date(this.model.EndDate);
    this.viewModel.validators = {
      'ShakeType': new ValidationChain('ShakeType').min(0),
      'NameKey': new ValidationChain('NameKey').translation(),
      'lockedBehindContent': new ValidationChain('lockedBehindContent').notNull(),
      'ImageSponsoredUrl': new ValidationChain('ImageSponsoredUrl').notEmpty().requiredIf(() => this.model.ShakeType == ShakeType.Sponsored || this.model.ShakeType == ShakeType.Lightning),
      'ImageTeam1Url': new ValidationChain('ImageTeam1Url').notEmpty().requiredIf(() => this.model.ShakeType == ShakeType.Event),
      'ImageTeam2Url': new ValidationChain('ImageTeam2Url').notEmpty().requiredIf(() => this.model.ShakeType == ShakeType.Event),
      'Question': new ValidationChain('Question').notNull().requiredIf(() => this.model.ShakeType == ShakeType.Question),
      'ImageBackground': new ValidationChain('ImageBackground').notEmpty().requiredIf(() => this.model.ShakeType != ShakeType.Lightning),
      'FilterWithDate': new ValidationChain('FilterWithDate').notNull().withFilter().requiredIf(() => this.model.ShakeType != ShakeType.Lightning && this.lockedBehindContent == false),
      'Date': new ValidationChain('Date').notNull().requiredIf(() => this.model.ShakeType == ShakeType.Lightning ),
      'EndDate': new ValidationChain('EndDate').notNull().requiredIf(() => this.model.ShakeType == ShakeType.Lightning),
      'CloseDate': new ValidationChain('CloseDate').notNull().notEmpty().requiredIf(() => this.model.ShakeType == ShakeType.Question),
      'LightningEnergy': new ValidationChain('LightningEnergy').min(0).requiredIf(() => this.model.ShakeType != ShakeType.Lightning),
      'EnvelopeCartItems': new ValidationChain('EnvelopeCartItems').notEmpty().requiredIf(() => this.model.RewardType == RewardType.Coupon && this.viewModel.state == ViewModelState.NEW),
      'EnvelopeNegative': new ValidationChain('EnvelopeNegative').notEmpty().requiredIf(() => this.model.RewardType == RewardType.Coupon),
      'EnvelopeSticker': new ValidationChain('EnvelopeSticker').notEmpty().requiredIf(() => this.model.RewardType == RewardType.Sticker),
      'VerifyShake': new ValidationChain('VerifyShake').bool(),
      'Stickers-Group': new ValidationChain('Stickers-Group').notEmpty().requiredIf(() => this.model.RewardType == RewardType.Sticker),
      'RewardType': new ValidationChain('RewardType').notNull(),
      'Campaign': new ValidationChain('Campaign').notNull().requiredIf(() => this.model.ShakeType != ShakeType.Lightning && this.lockedBehindContent == false),
      'CampaignPosition': new ValidationChain('CampaignPosition').notNull().requiredIf(() => this.model.ShakeType != ShakeType.Lightning && this.lockedBehindContent == false)
    };
    this.viewModel.validators['ShakeType'].execute(this.selectedShakeType);
    this.viewModel.validators['NameKey'].execute(this.model.NameKey);
    this.viewModel.validators['ImageSponsoredUrl'].execute(this.model.ImageSponsoredUrl);
    this.viewModel.validators['ImageTeam1Url'].execute(this.model.ImageTeam1Url);
    this.viewModel.validators['ImageTeam2Url'].execute(this.model.ImageTeam2Url);
    this.viewModel.validators['Question'].execute(this.model.Question);
    if (this.model.ImageBackground) {
      this.viewModel.validators['ImageBackground'].execute(this.model.ImageBackground);
    }
    if (this.filterWithDateTime) {
      this.viewModel.validators['FilterWithDate'].execute(this.filterWithDateTime);
    }
    this.viewModel.validators['LightningEnergy'].execute(this.model.LightningEnergy);
    if (this.model.RewardType) {
      this.viewModel.validators['RewardType'].execute(this.model.RewardType);
      
    }
    if (!this.model.TranslationsBundle) {
      this.model.TranslationsBundle = { Translations: [], Version: 0 }
    }
    if (!this.viewModel.lastEnvelopedPrize && !this.viewModel.fromRemote) {
      this.viewModel.lastEnvelopedPrize = new EnvelopedPrize();
      this.viewModel.lastEnvelopedPrize.Infinite = true;
    }
    if(this.viewModel.fromRemote == false){
      this.viewModel.validators['VerifyShake'].execute(true);
    }
  }

  translationFor(key) {
    let translation;
    if (this.model.TranslationsBundle) {
      translation = this.model.TranslationsBundle.Translations.find(element => element.FieldName == key);
    }
    return translation;
  }

  onNameChanged(translation) {
    this.viewModel.validators['NameKey'].execute(translation);
    this.model.NameKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }

  onShakeTypeChange(shakeTypeIndex) {
    this.selectedShakeType = shakeTypeIndex;
    this.model.ShakeType = Number(shakeTypeIndex);
    this.viewModel.validators['ShakeType'].execute(this.model.ShakeType);
    this.viewModel.validators['ImageSponsoredUrl'].execute(this.model.ImageSponsoredUrl);
    this.viewModel.validators['ImageTeam1Url'].execute(this.model.ImageTeam1Url);
    this.viewModel.validators['ImageTeam2Url'].execute(this.model.ImageTeam2Url);
    if (!this.model.Question && ShakeType.Question == this.model.ShakeType) {
      this.model.Question = new Question();
    }
    this.viewModel.validators['Question'].execute(this.model.Question);
    this.emitChanges();
  }

  onSponsoredUrlImageChanged(url) {
    this.viewModel.validators['ImageSponsoredUrl'].execute(url);
    this.model.ImageSponsoredUrl = url
    this.emitChanges();
  }

  onTeam1ImageChanged(url) {
    this.viewModel.validators['ImageTeam1Url'].execute(url);
    this.model.ImageTeam1Url = url;
    this.emitChanges();
  }

  onTeam2ImageChanged(url) {
    this.viewModel.validators['ImageTeam2Url'].execute(url);
    this.model.ImageTeam2Url = url
    this.emitChanges();
  }

  onQuestionChanged(question) {
    this.viewModel.validators['Question'].execute(question);
    this.model.Question = question;
    console.log(this.model.Question);
    this.emitChanges();
  }

  onAddPrizeSet() {
    this.envelopePrizeList.push({} as EnvelopedPrize);
  }

  onRemovePrizeSet(index, id?) {
    this.envelopePrizeList.splice(index, 1);
    let ind = this.verified.findIndex(element => element.Id == id);
    if(ind > -1){
      this.verified.splice(ind, 1);
    }
    this.viewModel.validators['VerifyShake'].execute(false);
  }

  onPrizeSetChanged(prizeSet, index) {
    if (index > this.envelopePrizeList.length) {
      this.viewModel.lastEnvelopedPrize.PrizeSetId = prizeSet._id;
    }
    else {
      this.envelopePrizeList[index].PrizeSetId = prizeSet._id;
      this.viewModel.envelopedPrizes = this.envelopePrizeList;
    }
    this.viewModel.validators['VerifyShake'].execute(false);
    console.log(this.viewModel.validators['VerifyShake']);
    this.emitChanges();
  }

  onPrizeNumberChanged(element, index) {
    this.envelopePrizeList[index].Count = Number(element.target.value);
    this.viewModel.envelopedPrizes = this.envelopePrizeList;
    this.viewModel.validators['VerifyShake'].execute(false);
    
    this.emitChanges();
  }

  onEnvelopeChanged(envelope, index) {
    if (index > this.envelopePrizeList.length) {
      this.viewModel.lastEnvelopedPrize.EnvelopeId = envelope._id;
    }
    else {
      this.envelopePrizeList[index].EnvelopeId = envelope._id;
      this.viewModel.envelopedPrizes = this.envelopePrizeList;
    }
    this.viewModel.validators['VerifyShake'].execute(false);
    console.log(this.viewModel.validators['VerifyShake']);
    this.emitChanges();
  }

  onBackgroundImageChanged(url) {
    this.viewModel.validators['ImageBackground'].execute(url);
    this.model.ImageBackground = url;
    this.emitChanges();
  }

  onCampaignChanged(Campaign) {
    this.viewModel.validators['Campaign'].execute(Campaign);
    this.model.CampaignId = Campaign.key;
    this.model.CampaignPosition = Campaign.position;
    this.emitChanges();
  }

  get CampaignIdAndPosition() {
    if (this.viewModel.fromRemote) {
      return {
        key: this.model.CampaignId,
        position: this.model.CampaignPosition,
      };
    }
  }

  translationVerification(translation) {
    if (!this.model.TranslationsBundle) {
      this.model.TranslationsBundle = { Translations: [], Version: 0 }
    };
    let dataFound = this.model.TranslationsBundle.Translations.find(element => element.FieldName == translation.FieldName);

    if (dataFound)
      this.model.TranslationsBundle.Translations[this.model.TranslationsBundle.Translations.indexOf(dataFound)] = translation;
    else
      this.model.TranslationsBundle.Translations.push(translation);

    if (this.viewModel.fromRemote) {
      if (this.viewModel.remoteModel.TranslationsBundle.Version == this.model.TranslationsBundle.Version) {
        this.model.TranslationsBundle.Version++;
      }
    }
    console.log(this.model.TranslationsBundle);
  }

  onFilterChanged(filter) {
    this.viewModel.validators['Filter'].execute(filter);
    this.model.Filter = filter;

    const startDate = filter.StartDate ? new Date(filter.StartDate) : null;
    const endDate = filter.EndDate ? new Date(filter.EndDate) : null;

    this.childrenSelectDate
      .forEach(dateSelect => {
        if (dateSelect.identifier && dateSelect.identifier === 'publish-date') {
          dateSelect.raiseMinimum(startDate);
          dateSelect.lowerMaximum(endDate);
        } else if (dateSelect.identifier && dateSelect.identifier === 'removal-date') {
          dateSelect.raiseMinimum(startDate);
          dateSelect.lowerMaximum(endDate);
        }
      });
    this.model.EndDate = endDate;
    this.model.Date = startDate;
    this.emitChanges();
  }

  onPublishDateChanged(date) {
    this.viewModel.validators['Date'].execute(date);
    this.model.Date = date

    this.childrenSelectDate
      .filter(item => item.identifier && item.identifier === 'removal-date')
      .forEach(dateSelect => {
        dateSelect.raiseMinimum(date);
      });

    this.emitChanges();
  }

  onRemovalDateChanged(date) {
    this.viewModel.validators['EndDate'].execute(date);
    this.model.EndDate = date;
    this.emitChanges();

  }

  get filterWithDateTime(): FilterWithDateTime[] {
    if (this.viewModel.fromRemoteAndTemplate()) {
      return [{
        filter: this.model.Filter,
        start: this.model.Date,
        end: this.model.EndDate
      }];
    } else if (this.viewModel.multipleFilterPackages.length > 0) {
      return this.viewModel.multipleFilterPackages;
    } else {
      return null;
    }
  }

  onFilterWithDateTimeChanged(filterPackages: FilterWithDateTime[]) {
    this.viewModel.validators['FilterWithDate'].execute(filterPackages);
    this.viewModel.multipleFilterPackages = filterPackages;
    if (filterPackages.length == 1) {
      this.model.Date = filterPackages[0].start;
      this.model.EndDate = filterPackages[0].end;
    }
    this.emitChanges();
  }

  getClass(id){
    let index = this.verified.findIndex( element => element.Id == id);
    let classes = {
      'bg-danger': index > -1 ? !this.verified[index].state : false,
      'p-3': index > -1 ? !this.verified[index].state : false
    };
    return classes;
  }


  //NOTE : Please Check this function later in regards to the validation!
  onVerifyShake(){
    let bool ;
    let result = [];
    this.envelopePrizeList.reduce((res, element) =>{  
      if (!res[element.PrizeSetId]) {
        res[element.PrizeSetId] = new EnvelopedPrize();
        res[element.PrizeSetId].Count = 0;
        res[element.PrizeSetId].PrizeSetId = element.PrizeSetId;
        res[element.PrizeSetId].EnvelopeId = element.EnvelopeId; 
        result.push(res[element.PrizeSetId])
      }
      res[element.PrizeSetId].Count = res[element.PrizeSetId].Count + element.Count;
      return res;
    }, {});

    for(let element of result){
      if(element && this.couponCount[element.PrizeSetId]){
      bool = element.Count < this.couponCount[element.PrizeSetId]? true : false;
      let index = this.verified.findIndex(row => row.Id == element.PrizeSetId);
        if(index < 0){
          this.verified.push({ Id:element.PrizeSetId, state: bool});
        }
        else{
          this.verified[index] = { Id:element.PrizeSetId, state: bool};
        }
      }
    }
    let check = this.verified.reduce((state ,element)  => element.state && state, true);
    this.viewModel.validators['VerifyShake'].execute(check);
  }

  onStartDateChanged(date) {
    this.viewModel.multipleFilterPackages = [];
    this.viewModel.validators['Date'].execute(date);
    this.model.Date = date;
    this.emitChanges();
  }

  onEndDateChanged(date) {
    this.viewModel.multipleFilterPackages = [];
    this.viewModel.validators['EndDate'].execute(date);
    this.model.EndDate = date;
    this.emitChanges();
  }

  onCloseDateChanged(date) {
    this.viewModel.validators['CloseDate'].execute(date);
    this.model.CloseDate = date;
    this.emitChanges();
  }

  onContentCodeChanged(content){
    
    this.model.LockedByContentId = content._id;
    this.emitChanges();
  }

  onLightningEnergyChanged(energy) {
    this.viewModel.validators['LightningEnergy'].execute(energy);
    this.emitChanges();
  }


}
