import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Competitor } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';

type CompetitorViewModel = {label: string, key: string}

@Component({
  selector: 'select-competitor',
  templateUrl: './select-competitor.component.html',
  styleUrls: ['./select-competitor.component.scss']
})
export class SelectCompetitorComponent implements OnInit {

  @Input() initialCompetitor: Competitor = null;
  @Input() editDisabled: boolean;
  @Output() competitorChanged = new EventEmitter<Competitor>();

  remoteCompetitorList: Competitor[]
  competitorList: CompetitorViewModel[]
  competitor: CompetitorViewModel = { label: null, key: null }

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if (this.initialCompetitor) {
      this.competitor = { label: this.initialCompetitor.Name, key: this.initialCompetitor._id }
    }

    this.updateCompetitorListFromRemote();
  }

  updateCompetitorListFromRemote() {
    this.remote.getCompetitors().then(competitors => {
      this.remoteCompetitorList = competitors;
      this.competitorList = competitors.map( competitor => ({ label: competitor.Name, key: competitor._id }) )
    })
  }

  onCompetitorChanged(competitorKey) {
    this.competitor = this.competitorList.find(competitor => competitor.key == competitorKey);
    let competitor = this.remoteCompetitorList.find(remoteCompetitor => remoteCompetitor._id == this.competitor.key)
    this.competitorChanged.emit(competitor);
  }

}
