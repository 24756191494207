import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RemoteService } from 'src/services/RemoteService';
import { TranslationViewModel, translationKeyPrefixed, mainLanguage } from 'src/core/translations';
import { guidGenerator } from 'src/core/utilities';
import { Translation } from '@gsegames/arena.models/lib/Abstraction/Translation';
import { EntryType } from '@gsegames/arena.models';

@Component({
  selector: 'field-translation',
  templateUrl: './field-translation.component.html',
  styleUrls: ['./field-translation.component.scss']
})
export class FieldTranslationComponent implements OnInit {

  @Input() initialTranslation: Translation;
  @Input() fieldName = '';
  @Input() folder : string;
  @Input() mainLanguage = mainLanguage;

  @Input() contentType: 'text' | 'media' = 'text';
  @Input() large: boolean = false;

  @Output() translationChanged = new EventEmitter<Translation>();

  translation = { FieldName: "", Identifier: "", Translations: {}};
  id: string;

  get mainText() {
    return this.translation.Translations[this.mainLanguage] ? this.translation.Translations[this.mainLanguage] : 'add translation';
  }
  languageList: string[] = ["pt-BR", this.mainLanguage];

  constructor(private remote: RemoteService) {
  }

  ngOnInit() {
    if (this.initialTranslation) {
      this.translation = this.initialTranslation;
    } else {
      this.translation = this.createEmptyTranslation();
    }

    this.translationChanged.emit(this.translation);
  }

  onTranslationChanged(language, translation) {
    this.translation.Translations[language] = translation;
    this.translationChanged.emit(this.translation);
  }

  CheckTranslation(){
    const availableLanguages = ['en', 'pt-BR'];
    let count = 0;
          availableLanguages.forEach(language => {
              const translation = this.translation.Translations[language]
              if (translation && translation !== '') {
                  ++count;
              }
          });
          
          return count > 1 ? false : true;
}

  private createEmptyTranslation() {
    this.id = guidGenerator();
    return {
      Identifier: this.id,
      FieldName: this.fieldName,
      Translations: this.languageList.reduce((translationObject, languageKey) => {
        translationObject[languageKey] = '';
        return translationObject;
      }, {})
    };
  }
}
