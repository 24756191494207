import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Coupon,
    Competitor,
    Envelope,
    Filter,
    Goal,
    HighlightEntry,
    QuestionEntry,
    Question,
    ShakeEntry,
    Sport,
    Tournament,
    ContentCode,
    Answer,
    Localization,
    Language,
    QuizEntry,
    QuizQuestionLevel,
    EventEntry,
    Entry,
    Dashboard,
    CampaignEntry,
    League,
    StickerGroup,
    Sticker,
    EntryType,
    LeagueDashboard,
    TournamentTable,
    PrizeSet,
    Prize
} from '@gsegames/arena.models';
import { RemoteEnvironmentService } from './RemoteEnvironmentService';
import { Observable } from 'rxjs';
import { RemoteFeedPayload, isByFilterIdData, isByDashboardIdData, isByCampaign } from 'src/core/remote-feed';
import { browser } from 'protractor';
import { TemplateComponent } from 'src/development/template/template.component';
import { Client } from '@gsegames/arena.models/lib/Client';
import { AreaChartNormalizedComponent } from '@swimlane/ngx-charts';

/**
 * The [[RemoteAPIService]] is a simple service.
 * It's the bridge between the other services and the actual network request.
 *
 * Request functions pattern:
 *   <request-method>[_<intent>][_<dataName>]([<parameters_needed>])
 *
 * Note about delete requests:
 *   why http.request() and not a http.delete() ?
 *   to be REST compliant, a delete would have been via an id in the url
 *   the current API requests a body with the id and the id of the associated translations
 *   so the more generic request() is used, as it allows a body in a DELETE.
 */
@Injectable()
export class RemoteAPIService {

    constructor(private http: HttpClient, private remoteEnvironment: RemoteEnvironmentService) { }

    public setHeader(token : string){
        localStorage.setItem('token', token);
        location.reload(); 
    }

    public get_environment() {
        return this.http.get(this.remoteEnvironment.apiURL('/api/system/enviroment'), {
            responseType: 'text'
        });
    }

    public get_verify(authorization: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: authorization
            })
        };
        return this.http.get(this.remoteEnvironment.apiURL('/api/verify'), httpOptions);
    }

    public post_authenticate(username: string, password: string) {
        return this.http.post<{
            token: string, username: string
        }>(this.remoteEnvironment.apiURL('/api/authenticate'), {
            username, password
        });
    }

    public get_Folders(prefix: string = ""){
        return this.http.get(this.remoteEnvironment.apiURL(`/api/media/files?prefix=${prefix}&delimiter=/`));
    }

    public get_mediaFiles(prefix: string = '', onlyFolders: boolean = false): Observable<any> {
        return this.http.get(this.remoteEnvironment.apiURL(`/api/media/files?prefix=${prefix}&delimiter=${onlyFolders ? '/' : ''}`));
    } 
    
    public post_uploadMedia(images: FormData, folder: string) {
        images.forEach(value => console.log(value));
        return this.http.post(this.remoteEnvironment.apiURL('/api/media/upload?folder='+ folder), images);
    }

    public get_localization() {
        return this.http.get<{ Localization: Localization, Languages: Language[] }>(
            this.remoteEnvironment.apiURL('/api/localization')
        );
    }
    public post_getkey(keyName: string) {
        return this.http.post<{ [keyName: string]: any }>(
            this.remoteEnvironment.apiURL('/api/localization'),
            { keyName }
        );
    }
    public get_languages() {
        return this.http.get<Language[]>(this.remoteEnvironment.apiURL(`/api/localization/languages`));
    }
    public post_language(language: Language) {
        return this.http.post<Language>(this.remoteEnvironment.apiURL(`/api/localization/languages`), language);
    }
    public put_updateKeys(language: { [languageKey: string]: string }) {
        return this.http.put<{ [keyName: string]: any }>('/api/localization/updateKeys', language);
    }

    public get_search_contentCodes(limit: number = 10, skip: number = 0, term: string) {
        return this.http.get<ContentCode[]>(
            this.remoteEnvironment.apiURL(`/api/content-Code/search?term=${term}&limit=${limit}&skip=${skip}`)
        );
    }
    public get_list_contentCodes(limit: number = 10, skip: number = 0) {
        return this.http.get<ContentCode[]>(this.remoteEnvironment.apiURL(`/api/content-Code?limit=${limit}&skip=${skip}`));
    }
    public get_contentCode(id) {
        return this.http.get<ContentCode>(this.remoteEnvironment.apiURL(`/api/content-Code/${id}`));
    }
    public put_contentCode(contentCode: ContentCode, number: number) {
        return this.http.put<ContentCode>(this.remoteEnvironment.apiURL('/api/content-Code'), { item: contentCode, localizations: {}, numberOfKeys: number});
    }
    public post_contentCode(contentCode: ContentCode, number: number) {
        return this.http.post<ContentCode>(this.remoteEnvironment.apiURL('/api/content-Code'), { item: contentCode, localizations: {}, numberOfKeys: number});
    }
    public delete_contentCode(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/content-Code/'+id), {
            body: { id, keys: [] }
        });
    }

    public get_ContentCodeKeysNumber(contentCodeId){
        return this.http.get<number>(this.remoteEnvironment.apiURL(`/api/content-code/${contentCodeId}/keys/count`));
    }

    public get_ContentCodeKeysList(contentCodeId){
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/content-code/${contentCodeId}/keys`));
    }

    public get_generate_code(size: number) : Promise<any> {
        return this.http.get(this.remoteEnvironment.apiURL(`/api/code/generate/${size}`)).toPromise();
    }
    public get_isUsed_code(code: string) {
        return this.http.get(`/api/code/available/${code}`);
    }

    public get_list_entries(payload: RemoteFeedPayload, page: number, limit : number, StartDate : string, EndDate : string) {
        const params = [ `mode=${payload.mode}`, `pageNumber=${page}`, `limit=${limit}`, `startDate=${StartDate}`, `endDate=${EndDate}`];
        if (isByFilterIdData(payload)) { params.push(`filterId=${payload.filterId}`); }
        if (isByDashboardIdData(payload)) { params.push(`dashboardId=${payload.dashboardId}`); }
        if (isByCampaign(payload)) {params.push(`campaignId=${payload.campaignId}`);}

        return this.http.get<Entry[]>(this.remoteEnvironment.apiURL(`/api/search-entries?${params.join('&')}`));
    }

    public legacy_get_list_coupons() {
        return this.http.get<Coupon[]>(this.remoteEnvironment.apiURL('/api/coupons'));
    }
    public legacy_get_coupon(id) {
        return this.http.get<Coupon>(this.remoteEnvironment.apiURL(`/api/coupons/${id}`));
    }
    public legacy_put_coupon(coupon: Coupon) {
        return this.http.put<Coupon>(this.remoteEnvironment.apiURL('/api/coupons'), coupon);
    }
    public legacy_post_coupon(coupon: Coupon) {
        return this.http.post<Coupon>(this.remoteEnvironment.apiURL('/api/coupons'), coupon);
    }
    public legacy_delete_coupons(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/coupons'), {
            body: { id, keys: [] }
        });
    }

    public get_list_competitors() {
        return this.http.get<Competitor[]>(this.remoteEnvironment.apiURL('/api/competitors'));
    }
    public get_competitor(id) {
        return this.http.get<Competitor>(this.remoteEnvironment.apiURL('/api/competitors/'+id));
    }
    public put_competitor(competitor: Competitor) 
    {
        return this.http.put<Competitor>(this.remoteEnvironment.apiURL('/api/competitors'), { item: competitor, localizations: {}});
    }
    public post_competitor(competitor: Competitor) {
        return this.http.post<Competitor>(this.remoteEnvironment.apiURL('/api/competitors'), {item: competitor, localizations: {}});
    }
    public delete_competitors(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/competitors'), {
            body: { id, keys: [] }
        });
    }

    public legacy_get_list_envelopes() {
        return this.http.get<Envelope[]>(this.remoteEnvironment.apiURL('/api/envelopes'));
    }
    public legacy_get_envelope(id) {
        return this.http.get<Envelope>(`/api/envelopes/${id}`);
    }
    public legacy_put_envelope(envelope: Envelope) {
        return this.http.put<Envelope>(this.remoteEnvironment.apiURL('/api/envelopes'), {item: envelope, localizations: {}});
    }
    public legacy_post_envelope(envelope: Envelope) {
        return this.http.post<Envelope>(this.remoteEnvironment.apiURL('/api/envelopes'), {item: envelope, localizations: {}});
    }
    public legacy_delete_envelopes(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/envelopes'), {
            body: { id, keys: [] }
        });
    }

    public get_list_events() {
        return this.http.get<EventEntry[]>(this.remoteEnvironment.apiURL('/api/events'));
    }
    public get_event(id) {
        return this.http.get<EventEntry>(`/api/events/${id}`);
    }
    public put_event(event: EventEntry, localizations) {
        return this.http.put<EventEntry>(this.remoteEnvironment.apiURL(`/api/events/${event._id}`), {
            event, localizations
        });
    }
    public post_event(event: EventEntry, localizations) {
        return this.http.post<EventEntry>(this.remoteEnvironment.apiURL(`/api/events`), {
            event, localizations
        });
    }
    public delete_events(eventId: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/events'), {
            body: { eventId, keys: [] }
        });
    }

    public post_determineEventOutcome(idEvent: any, answers: any) {
        return this.http.post<any>(this.remoteEnvironment.apiURL('/api/events/determineEventOutcome'), {
            idEvent, answers
        });
    }
    public post_calculatescore(eventId: string, realAnswers: Answer[]) {
        return this.http.post<{
            OK: string
        }>(this.remoteEnvironment.apiURL('/api/events/calculatescore'), {
            eventId, realAnswers
        });
    }
    public post_startCompiler(eventId: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json', 'Content-Type': 'application/json'
            })
        };
        return this.http.post<boolean>(
            this.remoteEnvironment.apiURL('/api/events/startCompiler'),
            { eventId },
            httpOptions
        );
    }
    public get_compilerStatus(eventId: string) {
        return this.http.get<boolean>(this.remoteEnvironment.apiURL(`/api/events/compilerstatus/${eventId}`));
    }

    public get_list_filters() {
        return this.http.get<Filter[]>(this.remoteEnvironment.apiURL('/api/filters'));
    }
    public get_filter(id) {
        return this.http.get<Filter>(this.remoteEnvironment.apiURL(`/api/filter/${id}`));
    }
    public put_filter(filter: Filter, localization) {
        return this.http.put<Filter>(this.remoteEnvironment.apiURL('/api/filters'), {item: filter, localizations: localization});
    }
    public post_filter(filter: Filter , localization) {
        return this.http.post<Filter>(this.remoteEnvironment.apiURL('/api/filters'),{item: filter, localizations: localization});
    }
    public delete_filters(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/filters'), {
            body: { id, keys: [] }
        });
    }

    public get_list_goals() {
        return this.http.get<Goal[]>(this.remoteEnvironment.apiURL('/api/goals'));
    }
    public get_goal(id) {
        return this.http.get<Goal>(this.remoteEnvironment.apiURL(`/api/goals/${id}`));
    }
    public put_goal(goal: Goal, translation) {
        return this.http.put<Goal>(this.remoteEnvironment.apiURL('/api/goals'), {item: goal, localizations: translation});
    }
    public post_goal(goal: Goal, translation) {
        return this.http.post<Goal>(this.remoteEnvironment.apiURL('/api/goals'), {item: goal, localizations: translation});
    }
    public delete_goals(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/goals'), {
            body: { id, keys: [] }
        });
    }

    public get_list_highlights() {
        return this.http.get<HighlightEntry[]>(this.remoteEnvironment.apiURL('/api/highlights'));
    }
    public get_highlight(id) {
        return this.http.get<HighlightEntry>(`/api/highlights/${id}`);
    }
    public put_highlight(highlight: HighlightEntry, localizations) {
        return this.http.put<HighlightEntry>(this.remoteEnvironment.apiURL(`/api/highlights`), {
            item: highlight, localizations: {}
        });
    }
    public post_highlight(highlight: HighlightEntry, localizations) {
        return this.http.post<HighlightEntry>(this.remoteEnvironment.apiURL(`/api/highlights`), {
            item: highlight, localizations: {}
        });
    }
    public delete_highlights(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/highlights'), {
            body: { id, keys: [] }
        });
    }

    public get_list_questions() {
        return this.http.get<QuestionEntry[]>(this.remoteEnvironment.apiURL('/api/questions'));
    }
    public get_question(id) {
        return this.http.get<QuestionEntry>(`/api/questions/${id}`);
    }
    public put_question(question: QuestionEntry) {
        return this.http.put<QuestionEntry>(this.remoteEnvironment.apiURL('/api/questions'), {
            item: question, translations: {}
        });
    }
    public post_question(question: QuestionEntry, localizations) {
        return this.http.post<QuestionEntry>(this.remoteEnvironment.apiURL('/api/questions'), {
            item: question, localizations
        });
    }
    public delete_questions(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/questions'), {
            body: { id, keys: [] }
        });
    }

    public get_list_questionOptions() {
        return this.http.get<Question[]>(this.remoteEnvironment.apiURL('/api/questionOptions'));
    }
    public get_questionOption(id) {
        return this.http.get<Question>(this.remoteEnvironment.apiURL(`/api/questionOptions/${id}`));
    }
    public put_questionOption(questionOption: Question, translation) {
        return this.http.put<Question>(this.remoteEnvironment.apiURL('/api/questionOptions'), {item: questionOption, localizations: translation});
    }
    public post_questionOption(questionOption: Question, translation) {
        return this.http.post<Question>(this.remoteEnvironment.apiURL('/api/questionOptions'), {item: questionOption, localizations: translation});
    }
    public delete_questionOptions(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/questionOptions'), {
            body: { id, keys: [] }
        });
    }

    public get_list_quizes() {
        return this.http.get<QuizEntry[]>(this.remoteEnvironment.apiURL('/api/quizes'));
    }
    public put_quiz(quiz: QuizEntry, localizations) {
        return this.http.put<QuizEntry>(this.remoteEnvironment.apiURL('/api/quizes'), {
            item: quiz, localizations
        });
    }
    public post_quiz(quiz: QuizEntry, localizations) {
        return this.http.post<QuizEntry>(this.remoteEnvironment.apiURL('/api/quizes'), {
            item: quiz, localizations
        });
    }
    public delete_quiz(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/quizes'), {
            body: { id, keys: [] }
        });
    }

    public get_quizQuestionLevels() {
        return this.http.get<QuizQuestionLevel[]>(this.remoteEnvironment.apiURL('/api/quizQuestionLevels'));
    }

    public get_list_shakes() {
        return this.http.get<ShakeEntry[]>(this.remoteEnvironment.apiURL('/vnext/api/shake'));
    }
    public get_shake(id) {
        return this.http.get<ShakeEntry>(`/vnext/api/shake/${id}`);
    }
    public put_shake(shake: ShakeEntry, envelopedPrize, localizations) {
        return this.http.put<ShakeEntry>(this.remoteEnvironment.apiURL('/vnext/api/shake'), {
            item: shake, envelopedPrizes
            : envelopedPrize, localizations
        });
    }
    public post_shake(shake: ShakeEntry, envelopedPrize, localizations) {
        return this.http.post<ShakeEntry>(this.remoteEnvironment.apiURL('/vnext/api/shake'), {
            item: shake, envelopedPrizes: envelopedPrize, localizations
        });
    }
    public delete_shakes(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/vnext/api/shake'), {
            body: { id, keys: [] }
        });
    }

    public get_list_sports() {
        return this.http.get<Sport[]>(this.remoteEnvironment.apiURL('/api/sports'));
    }

    // TO DO : Add remoteEnvioment to add the full path
    
    public get_sport(id) {
        return this.http.get<Sport>(this.remoteEnvironment.apiURL(`/api/sports/${id}`));
    }
    public put_sport(sport: Sport, translation) {
        return this.http.put<Sport>(this.remoteEnvironment.apiURL('/api/sports'), {item: sport , localizations: translation});
    }
    public post_sport(sport: Sport, translation) {
        return this.http.post<Sport>(this.remoteEnvironment.apiURL('/api/sports'), {item: sport , localizations: translation});
    }
    public delete_sports(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/sports'), {
            body: { id, keys: [] }
        });
    }

    public get_list_tournaments() {
        return this.http.get<Tournament[]>(this.remoteEnvironment.apiURL('/api/tournaments'));
    }
    public get_tournament(id) {
        return this.http.get<Tournament>(this.remoteEnvironment.apiURL(`/api/tournaments/${id}`));
    }
    public put_tournament(tournament: Tournament, translation) {
        return this.http.put<Tournament>(this.remoteEnvironment.apiURL('/api/tournaments'), {item: tournament, localizations: translation });
    }
    public post_tournament(tournament: Tournament, translation) {
        return this.http.post<Tournament>(this.remoteEnvironment.apiURL('/api/tournaments'), {item: tournament, localizations: translation });
    }
    public delete_tournaments(id: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/tournaments'), {
            body: { id, keys: [] }
        });
    }

    public associate_competitor_tournaments(table , tournamentId) {
        return this.http.post<TournamentTable>(this.remoteEnvironment.apiURL('/api/tournament/'+tournamentId+'/register/competitor/'+table.Competitor), {});
    }

    public get_list_dashboards() {
        return this.http.get<Dashboard[]>(this.remoteEnvironment.apiURL(`/api/dashboards`));
    }

    public get_list_campaigns(){
        return this.http.get<CampaignEntry[]>(this.remoteEnvironment.apiURL(`/api/campaigns/`));
    }

    public get_campaign(id: string) {
        return this.http.get<CampaignEntry>(this.remoteEnvironment.apiURL(`/api/campaigns/${id}`));
    }

    public post_campaign(campaign : CampaignEntry, localizations){
        return this.http.post<CampaignEntry>(this.remoteEnvironment.apiURL('/api/campaigns'), { item: campaign, localizations: {} });
    }

    public put_campaign(campaign : CampaignEntry, localizations){
        return this.http.put<CampaignEntry>(this.remoteEnvironment.apiURL('/api/campaigns'), { item: campaign, localizations: {} });
    }

    public delete_campaign(id : string){
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/campaigns'), { body: {id, keys:[] }});
    }

    public get_list_leagues(){
        return this.http.get<League[]>(this.remoteEnvironment.apiURL('/api/user-leagues'));
    }

    public get_league(id: string){
        return this.http.get<League>(this.remoteEnvironment.apiURL('/api/user-leagues/'+id)).toPromise();
    }

    public get_league_participants(id_league: string){
        return this.http.get<LeagueDashboard[]>(this.remoteEnvironment.apiURL(`/api/user-leagues/${id_league}/participants`)).toPromise();
    }

    public delete_league(id : string){
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/user-leagues/'+id), { body: {id, keys:[] }});
    }

    public post_league(league : League, numberKeys: number){
        console.log(numberKeys);
        return this.http.post<League>(this.remoteEnvironment.apiURL('/api/user-leagues/new'), { league: league , numberOfKeys : numberKeys});
    }

    public put_league(league : League, numberKeys: number){
        return this.http.put<League>(this.remoteEnvironment.apiURL('/api/user-leagues/edit'), { league: league , numberOfKeys: numberKeys});
    }

    public put_leaguePublic(leagueId: string, mode: 'public' | 'private') {
        return this.http.put<League>(this.remoteEnvironment.apiURL(`/api/user-leagues/${leagueId}/${mode}`), { id: leagueId });
    }

    public accept_league_participant(leagueId: string, participants: string[]){
        return this.http.post<League>(this.remoteEnvironment.apiURL(`/api/user-leagues/${leagueId}/accept`), { participantsIds: participants });
    }

    public reject_league_participant(leagueId: string, participants: string[]){
        return this.http.post<League>(this.remoteEnvironment.apiURL(`/api/user-leagues/${leagueId}/reject`), { participantsIds: participants });
    }

    public expel_league_participant(leagueId: string, participants: string[]){
        return this.http.post<League>(this.remoteEnvironment.apiURL(`/api/user-leagues/${leagueId}/expel`), { participantsIds: participants });
    }

    public get_league_accessKeys(id_league){
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/user-leagues/${id_league}/keys`)).toPromise();
    }

    public get_list_sticker_group(){
        return this.http.get<StickerGroup[]>(this.remoteEnvironment.apiURL('/api/stickers-group'));
    }

    public get_sticker_group(id: string){
        return this.http.get<StickerGroup>(this.remoteEnvironment.apiURL('/api/stickers-group/'+id));
    }

    public delete_sticker_group(id : string){
        return this.http.request('delete', this.remoteEnvironment.apiURL('/api/stickers-group'), { body: {id: id }});
    }

    public post_sticker_group(sticker_group : StickerGroup){
        console.log(sticker_group);
        return this.http.post<StickerGroup>(this.remoteEnvironment.apiURL('/api/stickers-group'), { stickerGroup: sticker_group });
    }

    public put_sticker_group(sticker_group : StickerGroup){
        return this.http.put<StickerGroup>(this.remoteEnvironment.apiURL('/api/stickers-group'), { stickerGroup: sticker_group });
    }

    public post_sticker(sticker_group_id: string , sticker : Sticker[]){
        return this.http.post<Sticker[]>(this.remoteEnvironment.apiURL(`/api/stickers-group/${sticker_group_id}/sticker`), { stickers: sticker });
    }

    public put_sticker(sticker_group_id: string , sticker : Sticker) {
        return this.http.put<Sticker>(this.remoteEnvironment.apiURL(`/api/stickers-group/${sticker_group_id}/sticker`), { sticker: sticker });
    }

    public delete_sticker(sticker_group_id: string , stickers_ids : string[]) {
        return this.http.request('delete' ,this.remoteEnvironment.apiURL(`/api/stickers-group/${sticker_group_id}/sticker`), { body: { stickerIds: stickers_ids }});
    }

    public get_number_of_shakes_sticker(id: string){
        return this.http.get<{availableCount: number}>(this.remoteEnvironment.apiURL(`/api/shake/available-count-sticker-group/${id}`));
    }

    public get_templates(){
        return this.http.get<Entry[]>(this.remoteEnvironment.apiURL('/api/templates'));
    }
    public post_template(template, localizations){
        return this.http.post<Entry>(this.remoteEnvironment.apiURL('/api/templates'), { item : template, localizations});
    }

    public put_template(template, localizations){
        return this.http.put<Entry>(this.remoteEnvironment.apiURL('/api/templates'), { item : template , localizations});
    }

    public delete_template(id:string){
        return this.http.request('delete' , this.remoteEnvironment.apiURL('/api/templates'), { body : {id: id}});
    }

    public get_prizeSets() {
        return this.http.get<PrizeSet[]>(this.remoteEnvironment.apiURL(`/vnext/api/set`));
    }

    public get_prizeSetById(id: string) {
        return this.http.get(this.remoteEnvironment.apiURL(`/vnext/api/set/${id}`));
    }

    public get_prizeSetNumberAvailable(id: string) {
        return this.http.get<any>(this.remoteEnvironment.apiURL(`/vnext/api/set/${id}/count`));
    }

    public post_prizeSet(prizeSet: SetSave, newPrizes: PrizeSave[] = []) {
        return this.http.post(this.remoteEnvironment.apiURL(`/vnext/api/set/create`), { item: prizeSet, prizes: newPrizes })
    }

    public put_prizeSet(prizeSet: SetUpdate, newPrizes: PrizeSave[] = []) {
        return this.http.put(this.remoteEnvironment.apiURL(`/vnext/api/set/update`), { item: prizeSet, prizes: newPrizes })
    }

    public delete_prizeSet(prizeSetId: string) {
        return this.http.request('delete' , this.remoteEnvironment.apiURL(`/vnext/api/set/delete`), { body : {id: prizeSetId} })
    }

    public vnext_get_envelopes() {
        return this.http.get(this.remoteEnvironment.apiURL(`/vnext/api/envelope`));
    }

    public get_envelopeSetById(id: string) {
        return this.http.get(this.remoteEnvironment.apiURL(`/vnext/api/envelope/${id}`));
    }

    public vnext_post_envelope(envelope: EnvelopeSave) {
        return this.http.post(this.remoteEnvironment.apiURL(`/vnext/api/envelope/create`), { item: envelope })
    }

    public vnext_put_envelope(envelope: EnvelopeUpdate) {
        return this.http.put(this.remoteEnvironment.apiURL(`/vnext/api/envelope/update`), { item: envelope })
    }

    public vnext_delete_envelope(envelopeId: string) {
        return this.http.put(this.remoteEnvironment.apiURL(`/vnext/api/envelope/delete`), { id: envelopeId })
    }
    
    public vnext_get_prizes_from_prizeSet(prizeSetId: string) {
        return this.http.get(this.remoteEnvironment.apiURL(`/vnext/api/prize/from/${prizeSetId}`))
    }
    public vnext_get_prize() {
        return this.http.get(this.remoteEnvironment.apiURL(`/vnext/api/prize`));
    }

    public vnext_post_prizes(prize: PrizeSave) {
        return this.http.post(this.remoteEnvironment.apiURL(`/vnext/api/prize/create`), { item: prize })
    }

    public vnext_put_prize(prize: PrizeUpdate) {
        return this.http.put(this.remoteEnvironment.apiURL(`/vnext/api/prize/update`), { item: prize })
    }

    public vnext_delete_prize(prizeId: string) {
        return this.http.request('delete', this.remoteEnvironment.apiURL(`/vnext/api/prize/delete`), { body: {id: prizeId }});
    }
    
    public get_stream(name: string) {
        return this.http.get<Stream>(this.remoteEnvironment.apiURL(`/api/livestreams/${name}`)).toPromise();
    }

    public get_streams() {
        return this.http.get<Stream[]>(this.remoteEnvironment.apiURL(`/api/livestreams`)).toPromise();
    }
    
    public post_stream(stream: Stream) {
        return this.http.post(this.remoteEnvironment.apiURL('/api/livestreams'), { stream }).toPromise();
    }

    public put_stream(stream: Stream) {
        return this.http.put(this.remoteEnvironment.apiURL('/api/livestreams'), { stream }).toPromise();
    }
    public get_users_by_filter(FilterId: string, limit: number , skip: number){
        return this.http.get<Dashboard[]>(this.remoteEnvironment.apiURL(`/api/statistics/dashboardsByFilter/${FilterId}?limit=${limit}&skip=${skip}`))
    }

    public get_clients(){
        return this.http.get<Client[]>(this.remoteEnvironment.apiURL('/api/clients'));
    }

    public post_client(client: Client, localizations = {}) {
        return this.http.post(this.remoteEnvironment.apiURL('/api/clients'), { item : client, localizations}).toPromise();
    }

    public put_client(client: Client, localizations = {}) {
        return this.http.put(this.remoteEnvironment.apiURL('/api/clients'), { item: client, localizations }).toPromise();
    }

    public delete_client(id: string , localizations = []) {
        return this.http.request('delete', this.remoteEnvironment.apiURL(`/api/clients`), { body: {id: id, keys : localizations }});
    }

    public get_info_Filter(id_Filter : string, dateStart , dateEnd, limit, page){
        const params = [`pageNumber=${page}`, `limit=${limit}`, `startDate=${dateStart}`, `endDate=${dateEnd}`];
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/statistic/filter/${id_Filter}?${params.join('&')}`));
    }
    
    public get_info_Filter_Count(id_Filter : string, dateStart , dateEnd){
        const params = [`startDate=${dateStart}`, `endDate=${dateEnd}`];
        return this.http.get<{count: number}>(this.remoteEnvironment.apiURL(`/api/statistic/filter/${id_Filter}/count?${params.join('&')}`));
    }

    public get_Total_Dashboards(){
        return this.http.get<number>(this.remoteEnvironment.apiURL("/api/statistic/dashboard/count")).toPromise();
    }

    public get_Dashboards_Online(){
        return this.http.get<number>(this.remoteEnvironment.apiURL("/api/statistic/dashboard/online/count")).toPromise();
    }

    public get_campaign_info(id_campaign : string, meta: string){
        console.log(id_campaign);
        return this.http.get<any>(this.remoteEnvironment.apiURL(`/api/statistic/campaign/${id_campaign}?meta=${meta}`));
    }

    public get_campaign_infoRange(id_campaign : string, dateStart, dateEnd){
        const params = [`startDate=${dateStart}`, `endDate=${dateEnd}`];
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/statistic/campaign/${id_campaign}/Range?${params.join('&')}`)).toPromise();
    }

    public get_campaigns_statistic(){
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/statistic/campaign`));
    }

    public get_campaigns_dashboardEvent(id_event : string){
        console.log(id_event);
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/statistic/campaign/dashboardEvent/${id_event}`)).toPromise();
    }

    public get_campaigns_dashboardShake(id_shake : string){
        console.log(id_shake);
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/statistic/campaign/dashboardShake/${id_shake}`)).toPromise();
    }

    public get_campaigns_dashboardQuestion(id_question : string){
        console.log(id_question);
        return this.http.get<any[]>(this.remoteEnvironment.apiURL(`/api/statistic/campaign/dashboardQuestion/${id_question}`)).toPromise();
    }

    public get_number_dashboardCampaign(id_campaign:string){
        return this.http.get<string>(this.remoteEnvironment.apiURL(`/api/statistic/dashboard/campaign/${id_campaign}/count`)).toPromise();
    }

    public get_Graph_Info(id_campaign, dateStart, dateEnd, interval){
        console.log(dateStart);
        const params = [`startDate=${dateStart}`, `endDate=${dateEnd}`, `interval=${interval}`];
        return this.http.get<any>(this.remoteEnvironment.apiURL(`/api/statistic/dashboard/campaign/${id_campaign}/graph/Range?${params.join('&')}`)).toPromise();
    }

    public get_push_notifications(){
        return this.http.get<any>(this.remoteEnvironment.apiURL("/api/push-notifications")).toPromise();
    }

    public post_push_notifications(notification){
        return this.http.post(this.remoteEnvironment.apiURL('/api/push-notifications'), { ...notification }).toPromise();
    }
}

type EnvelopeSave = Pick<Envelope, "Name" | "Media">;
type EnvelopeUpdate = Pick<Envelope, "_id" | "Name" | "Media">;

type PrizeSave = Pick<Prize, "Type" | "Set">;
type PrizeUpdate = Pick<Prize, "_id" | "Set">;

type SetSave = Pick<PrizeSet, "Name" | "Type">;
type SetUpdate = Pick<PrizeSet, "_id" | "Name">;

type Stream = { Name: string, Stream: string, Chat: string }
