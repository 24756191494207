import { Component, OnInit } from '@angular/core';
import { Editor } from '../editor';
import { CampaignEntry } from '@gsegames/arena.models/lib/Entries/CampaignEntry';
import { ValidationChain } from 'src/core/validations';
import { FilterWithDateTime } from '../../selectors/field-filter-with-datetime/field-filter-with-datetime.component';

@Component({
  selector: 'app-editor-campaign-entry',
  templateUrl: './editor-campaign-entry.component.html',
  styleUrls: ['./editor-campaign-entry.component.css']
})
export class EditorCampaignEntryComponent extends Editor<CampaignEntry> implements OnInit {

  static index: number = 0;
  idCampaign = 0;
  constructor() { super(); }

  ngOnInit() {
    super.ngOnInit();

    this.model.Date = new Date(this.model.Date);
    this.model.EndDate = new Date(this.model.EndDate);

    this.viewModel.validators = {
      Name: new ValidationChain('Name').notEmpty(),
      SponsorIconUrl: new ValidationChain('SponsorIconUrl').notEmpty(),
      DescriptionImageUrl: new ValidationChain('DescriptionImageUrl').notEmpty(),
      TermsAndConditionsUrl: new ValidationChain('TermsAndConditionsUrl').notEmpty().regex(/(https*:\/\/.+)|(arena:\/\/.+)/),
      ConsentText: new ValidationChain('ConsentText').notEmpty(),
      ConsentImage: new ValidationChain('ConsentImage').notEmpty(),
      FilterWithDate: new ValidationChain('FilterWithDate').notNull().notEmpty().withFilter(),
      LightningEnergy: new ValidationChain('LightningEnergy').notNull(),
      // FilterWithDate: new ValidationChain('FilterWithDate').notNull().recursive([
      //   new ValidationChain('filter').notNull(),
      //   new ValidationChain('start').debug(),
      //   new ValidationChain('end').debug(),
      // ]),
    };

    this.viewModel.validators['Name'].execute(this.model.Name);
    this.viewModel.validators['SponsorIconUrl'].execute(this.model.SponsorIconUrl);
    this.viewModel.validators['FilterWithDate'].execute(this.viewModel.multipleFilterPackages);
    this.viewModel.validators['DescriptionImageUrl'].execute(this.model.DescriptionImageUrl);
    this.viewModel.validators['TermsAndConditionsUrl'].execute(this.model.TermsAndConditionsUrl);
    this.viewModel.validators['ConsentText'].execute(this.model.ConsentText);
    this.viewModel.validators['ConsentImage'].execute(this.model.ConsentImage);
    this.viewModel.validators['LightningEnergy'].execute(this.model.LightningEnergy);

    EditorCampaignEntryComponent.index++;
    this.idCampaign = EditorCampaignEntryComponent.index;
  }

  onNameChanged(name: string) {
    this.viewModel.validators['Name'].execute(name);
    this.model.Name = name;
    this.emitChanges();
  }
  onConsentTextChanged(text: string) {
    this.viewModel.validators['ConsentText'].execute(text);
    this.model.ConsentText = text;
    this.emitChanges();
  }

  onConsentImageChanged(url: string) {
    this.viewModel.validators['ConsentImage'].execute(url);
    this.model.ConsentImage = url;
    this.emitChanges();
  }
  
  onImageURLChanged(url) {
    this.viewModel.validators['SponsorIconUrl'].execute(url);
    this.model.SponsorIconUrl = url;
    this.emitChanges();
  }

  onImageDescriptionURLChanged(url) {
    this.viewModel.validators['DescriptionImageUrl'].execute(url);
    this.model.DescriptionImageUrl = url;
    this.emitChanges();
  }

  onFilterWithDateTimeChanged(filterPackages: FilterWithDateTime[]) {
    this.viewModel.validators['FilterWithDate'].execute(filterPackages);
    this.viewModel.multipleFilterPackages = filterPackages;
    if(filterPackages.length == 1){
      this.model.Date = filterPackages[0].start;
      this.model.EndDate = filterPackages[0].end;
    }
    this.emitChanges();
  }

  get filterWithDateTime(): FilterWithDateTime[] {
    if (this.viewModel.fromRemoteAndTemplate()) {
      return [{
        filter: this.model.Filter,
        start: this.model.Date,
        end: this.model.EndDate,
      }];
    } else if (this.viewModel.multipleFilterPackages.length > 0) {
      return this.viewModel.multipleFilterPackages;
    } else {
      return null;
    }
  }

  onLinkUrlChanged(url: string) {
    this.viewModel.validators['TermsAndConditionsUrl'].execute(url);
    this.model.TermsAndConditionsUrl = url;
    this.emitChanges();
  }

  onLightningEnergyChanged(energy : number) {
    this.viewModel.validators['LightningEnergy'].execute(this.model.LightningEnergy);
    this.model.LightningEnergy = energy;
    this.emitChanges();
  }
}
