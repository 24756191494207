import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RemoteService } from 'src/services/RemoteService';
import { RemoteAPIService } from 'src/services/RemoteAPIService';
import { QuizQuestionLevel } from '@gsegames/arena.models';


interface levelModel { label: string; key: string;}

@Component({
  selector: 'select-questionLevel',
  moduleId: module.id,
  templateUrl: 'select.question.level.component.html',
  styleUrls: ['select.question.level.component.css']
})


export class SelectQuestionLevelComponent implements OnInit {
  @Input() initialLevel: QuizQuestionLevel = null;
  @Input() editDisabled: boolean;
  @Output() levelChanged = new EventEmitter<any>();

  remoteLevelList: QuizQuestionLevel[];
  levelList: levelModel[];
  level: levelModel = { label: '', key: null }

  constructor(private remote: RemoteAPIService) { }

  ngOnInit() {
    if (this.initialLevel) {
      this.level = { label: this.initialLevel.NameKey, key: this.initialLevel._id }
    }
    this.updatelevelListFromRemote();
  }

  updatelevelListFromRemote() {
    this.remote.get_quizQuestionLevels().toPromise().then(levels => {
      this.remoteLevelList = levels;  
      this.levelList = levels.map( level => ({ label: level.NameKey, key: level._id }));
    })
  }

  onLevelChanged(levelKey) {
    this.level = this.levelList.find(level => level.key == levelKey);
    let level = this.remoteLevelList.find(remotelevel => remotelevel._id == this.level.key);
    this.levelChanged.emit(level);
  }

}
