import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Goal, Tournament } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';


export interface GoalViewModel { label: string; key: string; goal: Goal; }
export interface TournamentGoals { sportKey: string; goalPackages : { name: string , goals : Goal[] } [] };
@Component({
  selector: 'select-goals',
  templateUrl: './select-goals.component.html',
  styleUrls: ['./select-goals.component.scss']
})
export class SelectGoalsComponent implements OnInit {

  @Input() initialGoals: Goal[] = [];
  @Input() editDisabled = false;
  @Input() tournamentKey : Tournament;
  @Output() goalsChanged = new EventEmitter<Goal[]>();

  remoteGoalList: Goal[];
  goalNull: boolean;
  goalList: TournamentGoals;
  goals: GoalViewModel[];

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if (this.initialGoals) {
      this.goals = this.initialGoals.map(goal => ({ label: goal.Name, key: goal._id, goal }));
    }

    this.updateGoalListFromRemote();
  }

  updateGoalListFromRemote() {
    this.remote.getSportGoals().then(tournamentGoals => {
      this.goalList = tournamentGoals.find(element => element.sportKey == this.tournamentKey.Sport.NameKey);
      this.goalNull = false;
      if(!this.goalList){
        this.goalList = tournamentGoals.find(element => element.sportKey == '*');
        this.goalNull = true;
      }
    });
  }

  onGoalAdded(goals: Goal[]) {
    this.goals = goals.map(goal => ({ label: goal.Name, key: goal._id, goal }))

    this.goalsChanged.emit(this.goals.map(goal => goal.goal));
  }

}
