import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { CampaignEntry } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';
import * as _ from 'lodash';
import * as moment from 'moment';

interface CampaignViewModel { label: string; key: string;}

@Component({
  selector: 'select-campaign',
  templateUrl: './select-campaign.component.html',
  styleUrls: ['./select-campaign.component.css']
})

export class SelectCampaignComponent implements OnInit {

  @Input() initialCampaign ;
  @Input() editDisabled : boolean ;
  @Input() entryOnly: boolean;
  @Output() campaignChanged = new EventEmitter<any>();

  remoteCampaignList: CampaignEntry[];
  campaignList: CampaignViewModel[];
  campaign = { label: null, key: null };
  positionEntry: number;
  sendParent = { key: '', position: null};

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if(this.initialCampaign && this.initialCampaign.key && this.initialCampaign.key != '') {
        this.remote.getCampaign(this.initialCampaign.key).then(campaign => {
          this.campaign.label = campaign.Name; 
          this.campaign.key = campaign._id;
          this.sendParent = this.initialCampaign;
        })
    }
    else{
      this.campaign.label = "-- No Campaign --";
      this.campaign.key = '';
    }
    this.updateCampaignListFromRemote();
  }

  updateCampaignListFromRemote() {
    if(this.entryOnly && this.entryOnly == true){
    this.remote.getCampaigns().then(campaign => {
      this.remoteCampaignList = campaign;
      this.campaignList = campaign.filter(campaign => moment(campaign.EndDate).isAfter()).map(campaign => ({ label: campaign.Name, key: campaign._id}));
    });
  }
  else{
    this.remote.getCampaigns().then(campaign => {
      this.remoteCampaignList = campaign;
      this.campaignList = campaign.map(campaign => ({ label: campaign.Name, key: campaign._id}));
    });
  }
  }

  onCampaignChanged(campaignKey: string) {
    if(campaignKey != ''){
      this.campaign = this.campaignList.find(campaign => campaign.key === campaignKey);
      const campaign = this.remoteCampaignList.find(remoteCampaign => remoteCampaign._id === this.campaign.key);
      this.campaign.key = campaign._id;
      this.campaign.label = campaign.Name;
      this.sendParent.key = campaign._id;
    }
    else{
      this.sendParent.key = "";
      this.sendParent.position = null;
    }
      this.campaignChanged.emit(this.sendParent);
  }

  onCampaignPositionChanged(value: number) {
    this.sendParent.position = value;
    this.campaignChanged.emit(this.sendParent);
}

}
