import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewModel } from 'src/models/ViewModel';
import { RemoteService } from 'src/services/RemoteService';
import { StorageService } from 'src/services/StorageService';
import { FeedInfoService } from 'src/services/FeedInfo.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { EventEntry } from '@gsegames/arena.models';
import { SelectMediaComponent } from '../feed/viewmodel-controller/selectors/select-media/select-media.component';

@Component({
  selector: 'save-queue',
  templateUrl: './save-queue.component.html',
  styleUrls: ['./save-queue.component.scss']
})
export class SaveQueueComponent implements OnInit {

  queue: ViewModel<any>[] = [];
  modalRef: BsModalRef;
  message: string;

  
  constructor(private storage: StorageService, private remote: RemoteService, private feed: FeedInfoService, private modal : BsModalService) {
    storage.queueStream.subscribe(next => this.onAddOne(next));
  }

  ngOnInit() {
    this.message = "All entries will be discarded to from the queue. Proceed ?";
  }

  onAddOne(newViewModel: ViewModel<any>) {
    this.queue = this.queue.filter(viewModel => viewModel.id != newViewModel.id);
    this.queue.push(newViewModel);
  }

  openModalDiscard(template: TemplateRef<any>){ 
    this.modalRef = this.modal.show(template, {class:'modal-dialog-centered', ignoreBackdropClick: true });
  }

  checkConfirmation(validation){
    this.modalRef.hide();
    console.log(validation);
    if(validation == true){
      this.discardAll();
    }
  }

  saveAll() {
    this.remote.save(this.queue); 
    this.queue = [];
  }

  discardOne(index) {
    let viewmodel : ViewModel<any>[] = [];
    viewmodel.push(this.queue[index]);
    this.feed.RemoveFromQueueEntriesData(viewmodel[0]);
    this.remote.afterDiscardSoloCallbacks.forEach(callback => callback(viewmodel));
    this.queue.splice(index, 1);
  }


  discardAll() {
    this.remote.afterDiscardCallbacks.forEach(callback => callback(this.queue));
    this.queue.forEach(viewmodel => { this.feed.RemoveFromQueueEntriesData(viewmodel); });
    this.queue = []; 
  }

  restoreViewModelToRemote(viewmodel?: ViewModel<any>)  {
    viewmodel.localModel = viewmodel.clone(viewmodel.remoteModel);
  }

  trackById(index, viewModel: ViewModel<any>) {
    return viewModel.id;
  }
}
