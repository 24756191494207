import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { RemoteAPIService } from '../../services/RemoteAPIService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogService } from 'src/services/LogService';

@Component({
  selector: 'login-nav',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor(formBuilder : FormBuilder, private req : RemoteAPIService, private log: LogService) {
    this.form = formBuilder.group({
      User : new FormControl('', Validators.required),
      Password : new FormControl('', Validators.required)
    });
   }

   SubmitLogin(){
      this.req.post_authenticate(this.form.value['User'], this.form.value['Password']).toPromise()
      .then((response) => {
        if (response)
          this.req.setHeader(response.token);
    })
    .catch((err) => {
        this.log.error(err.error);
    });
      
   }

   VerifyAuthentication() : boolean {
     return localStorage.getItem('token') != null && localStorage.getItem('token') != '' ? true : false;
   }

   LogOut(){
     if(this.VerifyAuthentication())
        localStorage.removeItem('token');
        location.reload();
   }

  ngOnInit() {}

}
