import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslationViewModel, mainLanguage, translationKeyPrefixed } from 'src/core/translations';
import { guidGenerator } from 'src/core/utilities';
import { RemoteService } from 'src/services/RemoteService';

@Component({
  selector: 'filed-translation-old',
  moduleId: module.id,
  templateUrl: 'filed.translation.old.component.html',
  styleUrls: ['filed.translation.old.component.css']
})
export class FiledTranslationOldComponent implements OnInit {

  @Input() initialTranslationKey: string = null;
  @Input() initialTranslation: TranslationViewModel = null;
  @Input() keyPrefix = '';
  @Input() mainLanguage = mainLanguage;

  @Input() contentType: 'text' | 'media' = 'text';

  @Output() translationChanged = new EventEmitter<any>();

  translation: TranslationViewModel = { key: null, path: null, translations: {}, isKeyEditable: true, guid: guidGenerator(), data: {}, oldKey: null };
  translationSend :{[key:string]: { [language:string] : string }};

  get mainText() {
    return this.translation.translations[this.mainLanguage] ? this.translation.translations[this.mainLanguage] : 'add translation';
  }
  languageList: string[] = ["pt-BR", this.mainLanguage];

  constructor(private remote: RemoteService) {

  }

  ngOnInit() {
    if (this.initialTranslation) {
      this.translation = this.initialTranslation;
      this.translationChanged.emit(this.translation);
    } else if (this.initialTranslationKey) {
      const completeKey = translationKeyPrefixed( this.initialTranslationKey, this.keyPrefix );
      this.remote.getTranslation(completeKey).then((response: any) => {
        if(response){
          this.translation = {
            key: this.initialTranslationKey, path: this.keyPrefix, translations: response, isKeyEditable: false, guid: 'remote' , data: { [completeKey] : response } , oldKey: ''
          };
          this.translationChanged.emit(this.translation);
        } else {
          this.translation = this.createEmptyTranslation();
          this.translation.data[translationKeyPrefixed(this.translation.key, this.keyPrefix)] = this.languageList.reduce((translationObject, languageKey) => {
            translationObject[languageKey] = '';
            return translationObject;
          }, {})
          this.translationChanged.emit(this.translation);
        }
      });
    } else {
      this.translation = this.createEmptyTranslation();
      this.translation.data[translationKeyPrefixed(this.translation.key, this.keyPrefix)] = this.languageList.reduce((translationObject, languageKey) => {
        translationObject[languageKey] = '';
        return translationObject;
      }, {})
      this.translationChanged.emit(this.translation);
    }
  }

  onTranslationChanged(language, translation) {
    if (this.translation.isKeyEditable && language === mainLanguage) {
      this.translation.oldKey = this.translation.key;
      this.translation.key = translation.replace(/[^\w\s]/gi, '');
    }
    this.translation.translations[language] = translation;
    const completeKey = translationKeyPrefixed( this.translation.key, this.keyPrefix );
    
    this.translation.data = {  [completeKey] : this.translation.translations  }; 
    this.translationChanged.emit(this.translation);
  }

  CheckTranslation(){
          const availableLanguages = ['en', 'pt-BR'];
          let count = 0;
                availableLanguages.forEach(language => {
                    const translation = this.translation.translations[language]
                    if (translation && translation !== '') {
                        ++count;
                    }
                });

                return count > 1 ? false : true;
  }

  private createEmptyTranslation() {
    const guid = guidGenerator();
    const Completekey : string = translationKeyPrefixed(guid , this.keyPrefix);
    return {
      key: guid,
      guid,
      isKeyEditable: true,
      path: this.keyPrefix,
      translations: this.languageList.reduce((translationObject, languageKey) => {
        translationObject[languageKey] = '';
        return translationObject;
      }, {}),
      data: {  },
      oldKey : ''
    };
  }
}
