import { Injectable } from '@angular/core';
import { RemoteAPIService } from './RemoteAPIService';
import { FilterService } from './FilterService';
import { ViewModel, ViewModelType, ViewModelState, mapEntryTypeToViewModelType } from 'src/models/ViewModel';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';

import * as _ from 'lodash';
import { LogService } from './LogService';
import { Localization, Language, EventEntry, Sport, Goal, Entry, CampaignEntry, League, EntryType, StickerGroup, EnvelopedPrize } from '@gsegames/arena.models';
import { deleteTranslations, getTranslations, TranslationViewModel, translationsToRemoteTranslationModel } from 'src/core/translations';
import { template } from '@angular/core/src/render3';
import { ViewModelControllerComponent } from 'src/shell/feed/viewmodel-controller/viewmodel-controller.component';
import { FeedManagerService } from './FeedManager.service';
import { guidGenerator } from 'src/core/utilities';


function mapModelsIntoViewModels<T>(models: T[], modelType: ViewModelType): ViewModel<T>[] {
    const clone = (model) => _.cloneDeep(model);
    const diff = (source: T, dest: T) => ({ changes: '', additions: '', eliminations: '' });

    return models.map(model => {
        const id = (model as any)._id;

        return new ViewModel<T>(model, modelType, { id }, { clone }, { diff }, { fromRemote: true });
    });
}

export function mapModelIntoViewModel<T extends { _id: string, EntryType: EntryType }>(model: T): ViewModel<T> {
    const clone = (model) => _.cloneDeep(model);
    const diff = (source: T, dest: T) => ({ changes: '', additions: '', eliminations: '' });

    const id = model._id;
    const modelType = mapEntryTypeToViewModelType[model.EntryType];

    return new ViewModel<T>(model, modelType, { id }, { clone }, { diff }, { fromRemote: true });
}

export function mapTemplateModelIntoViewModel<T extends { _id: string, EntryType: EntryType }>(model: T): ViewModel<T> {
    const clone = (model) => _.cloneDeep(model);
    const diff = (source: T, dest: T) => ({ changes: '', additions: '', eliminations: '' });

    let templateModel = clone(model);
    delete templateModel._id;    
    const id = guidGenerator();
    const modelType = mapEntryTypeToViewModelType[templateModel.EntryType];
    let newModel = new ViewModel<T>(templateModel , modelType, { id }, { clone }, { diff }, { fromRemote: true });  
    newModel.state = ViewModelState.NEW;
    
    return newModel;
}

const reduceList = map((results: ViewModel<any>[][]) =>
    results.reduce((accumulator, value) => accumulator.concat(value))
);

const trimList = (n: number) => map((items: any[]) => items.splice(0, n));

const markEntriesWithType = map((entries: Entry[]) => {
    return entries.map(entry =>
        ({ model: entry, viewModelType: mapEntryTypeToViewModelType[entry.EntryType] })
    );
});

const markEntriesFromRemote = map((models: { model: Entry, viewModelType: ViewModelType }[]) => {
    return models.map(model => ({ ...model, fromRemote: true }));
});

const mapEntriesToViewModel = map((models: { model: Entry, viewModelType: ViewModelType, fromRemote?: boolean }[]) => {
    const clone = (model) => _.cloneDeep(model);
    const diff = (source, dest) => ({ changes: '', additions: '', eliminations: '' });

    return models.map(model => new ViewModel<Entry>(
        model.model,
        model.viewModelType,
        { id: model.model._id },
        { clone },
        { diff },
        { fromRemote: model.fromRemote }
    ));
});

type SaveFunction = (...items) => Observable<any>;

export function splitTranslationFromModel(viewModel: ViewModel<any>): { templateModel: any, translations: TranslationViewModel[] } {
    return { templateModel: viewModel.localModel, translations: viewModel.translations };
}

/**
 *
 */
@Injectable()
export class RemoteService {

    constructor(private remoteAPI: RemoteAPIService, private filter: FilterService, private log: LogService, private feedManager : FeedManagerService) {
        this.fillTranslationsCache();
    }

    public trimValue = 3;

    cachedFileResponse: { [key: string] : string[] } = {};

    cachedTranslationResponse: { Localization: Localization, Languages: Language[] };

    afterSaveCallbacks: ((savedViewModels?: ViewModel<any>[]) => void)[] = [];
    afterDiscardCallbacks: ((discardViewModels?: ViewModel<any>[]) => void)[] = [];
    afterDiscardSoloCallbacks: ((discardViewModels?: ViewModel<any>[]) => void)[] = [];

    private saveMap: { [type: string]: { newsave: SaveFunction, update: SaveFunction } } = {
        'EventEntry':     { newsave: this.remoteAPI.post_event          , update: this.remoteAPI.put_event           },
        'HighlightEntry': { newsave: this.remoteAPI.post_highlight      , update: this.remoteAPI.put_highlight       },
        'ShakeEntry':     { newsave: this.remoteAPI.post_shake          , update: this.remoteAPI.put_shake           },
        'QuestionEntry':  { newsave: this.remoteAPI.post_question       , update: this.remoteAPI.put_question        },
        'QuizEntry':      { newsave: this.remoteAPI.post_quiz           , update: this.remoteAPI.put_quiz            },
        'CampaignEntry':  { newsave: this.remoteAPI.post_campaign       , update: this.remoteAPI.put_campaign        },
        'Sports':         { newsave: this.remoteAPI.post_sport          , update: this.remoteAPI.put_sport           },
        'Tournaments':    { newsave: this.remoteAPI.post_tournament     , update: this.remoteAPI.put_tournament      },
        'Competitors':    { newsave: this.remoteAPI.post_competitor     , update: this.remoteAPI.put_competitor      },
        'Objectives':     { newsave: this.remoteAPI.post_goal           , update: this.remoteAPI.put_goal            },
        'Filters':        { newsave: this.remoteAPI.post_filter         , update: this.remoteAPI.put_filter          },
    //   'Results':        { newsave: this.remoteAPI.post_event          , update: this.remoteAPI.put_event         },
        'Envelops':       { newsave: this.remoteAPI.legacy_post_envelope       , update: this.remoteAPI.legacy_put_envelope        },
        'Coupons':        { newsave: this.remoteAPI.legacy_post_coupon         , update: this.remoteAPI.legacy_put_coupon          },
        'Questions':      { newsave: this.remoteAPI.post_questionOption , update: this.remoteAPI.put_questionOption  },
    //  "Levels":         this.remoteAPI. ... ()                    ,
    //  "Translations":   this.remoteAPI. ... ()                    ,
    //  "ContentByCode":  this.remoteAPI. ... ()                    ,
    //  "Media":          this.remoteAPI. ... ()                    ,
    };

    save(viewModels: ViewModel<any>[]) {
        

        // build the dependency tree
        const toNode = (viewModel: ViewModel<any>) => ({ viewModel, children: [] });

        let nodes = viewModels.map(viewModel => toNode(viewModel));

        console.log(nodes);

        const roots = nodes.filter(node => _.isNil(node.viewModel.requesterId));
        nodes = nodes.filter(node => !_.isNil(node.viewModel.requesterId));

        roots.forEach(root => {
            root.children = nodes.filter(node => node.viewModel.requesterId === root.viewModel.id);
            nodes = nodes.filter(node => node.viewModel.requesterId !== root.viewModel.id);
        });

        console.log(roots);

        this.log.info(`saving ${viewModels.length} models`);

        const saveQueue = [];

        viewModels.forEach(viewModel => {
            const controller = this.saveMap[viewModel.modelType];
            let envelopePrizes : EnvelopedPrize[] = [];
            if(viewModel.modelType == ViewModelType.ShakeEntry){
               envelopePrizes = viewModel.envelopedPrizes;
               if(viewModel.lastEnvelopedPrize){
                   envelopePrizes.push(viewModel.lastEnvelopedPrize);
               }
            }
            let save: SaveFunction;
            if (viewModel.state === ViewModelState.NEW) {
                save = controller.newsave.bind(this.remoteAPI);
            } else {
                save = controller.update.bind(this.remoteAPI);
            }

            console.log({ 'model': _.cloneDeep(viewModel.localModel) })
            const { templateModel, translations } = splitTranslationFromModel(viewModel);

            let models = [];

            if (viewModel.multipleFilterPackages.length > 0) {
                models = viewModel.multipleFilterPackages.map(filterPackage => {
                    const newModel = _.cloneDeep(templateModel)
                    return Object.assign(newModel, {
                        Filter: filterPackage.filter,
                        Date: filterPackage.start,
                        EndDate: filterPackage.end,
                    });
                });
            } else {
                models = [templateModel];
            }

            models.forEach(model => {
                if(viewModel.modelType == ViewModelType.ShakeEntry){
                    saveQueue.push(
                        save(model, envelopePrizes, translationsToRemoteTranslationModel(translations)).toPromise()
                    );    
                }
                else{
                    saveQueue.push(
                        save(model, translationsToRemoteTranslationModel(translations)).toPromise()
                    );
                }
                
            });
        });

        Promise.all(saveQueue)
            .then(async responses => {
                const newViewModels = responses.map(response => mapModelIntoViewModel(response));
                await this.reloadSupportData();
                this.log.success(`saved successfully ${responses.length} models`);
                this.afterSaveCallbacks.forEach(callback => callback(newViewModels)); 
                this.feedManager.updateFeed();
            })
            .catch(error => {
                this.log.error(`error: ${error.statusText}`);
                console.log(error);
                this.afterDiscardCallbacks.forEach(callback => callback(viewModels));
                this.feedManager.updateFeed();
            });
    }

    delete(viewModel: ViewModel<any>) {
        const deleteMap = {
            'EventEntry': this.remoteAPI.delete_events,
            'HighlightEntry': this.remoteAPI.delete_highlights,
            'ShakeEntry': this.remoteAPI.delete_shakes,
            'QuestionEntry': this.remoteAPI.delete_questions,
            'QuizEntry': this.remoteAPI.delete_quiz,
            'CampaignEntry': this.remoteAPI.delete_campaign
        };

        const deleteFn = deleteMap[viewModel.modelType].bind(this.remoteAPI);

        deleteFn(viewModel.remoteModel._id).toPromise()
            .then(async response => {
                console.log(response);
                await this.reloadSupportData();
                this.log.success(`deleted successfully`);
                this.afterSaveCallbacks.forEach(callback => callback([]));
            })
            .catch(error => {
                this.log.error(`error: ${error.statusText}`);
                console.log(error);
            });
    }

    saveTemplate(viewmodel: ViewModel<any>){
        let reference;
        const { templateModel, translations } = splitTranslationFromModel(viewmodel);
        let models = [];
        let save: SaveFunction;
        let queue = [];

        switch(viewmodel.modelType){
            case ViewModelType.EventEntry: reference = templateModel as EntryType.Event; break;
            case ViewModelType.CampaignEntry: reference = templateModel as EntryType.Campaign; break;
            case ViewModelType.HighlightEntry: reference = templateModel as EntryType.Highlight; break;
            case ViewModelType.ShakeEntry: reference = templateModel as EntryType.Legacy_Shake; break;
            case ViewModelType.QuizEntry: reference = templateModel as EntryType.Quiz; break;
            default: console.log("This entry type doesn't correspond to the ones included on the system"); break;
          }

        if (viewmodel.multipleFilterPackages.length > 0) {
            models = viewmodel.multipleFilterPackages.map(filterPackage => {
                const newModel = _.cloneDeep(templateModel);
                return Object.assign(newModel, {
                    Filter: filterPackage.filter,
                    Date: filterPackage.start,
                    EndDate: filterPackage.end,
                });
            });
        } else {
            models = [reference];
        }

        models.forEach(model => {
            if(viewmodel.state == ViewModelState.NEW){
                console.log(model);
                save = this.remoteAPI.post_template.bind(this.remoteAPI);
            }
            else{
                save = this.remoteAPI.put_template.bind(this.remoteAPI);
            }
                  
        queue.push(save(model, translationsToRemoteTranslationModel(translations)).toPromise());
        });

        Promise.all(queue).then(async response => {
            const newViewModels = response.map(response => mapModelIntoViewModel(response));
                await this.reloadSupportData();
                this.log.success(`saved successfully ${response.length} models`);
                this.afterSaveCallbacks.forEach(callback => callback(newViewModels)); 
                this.feedManager.updateFeed();
        }).catch(error => {
            this.log.error(`error: ${error.statusText}`);
            console.log(error);
        }); 
    }

    deleteTemplate(viewModel: ViewModel<any>){
        this.remoteAPI.delete_template(viewModel.id).toPromise()
            .then(async response => {
                console.log(response);
                await this.reloadSupportData();
                this.log.success(`deleted successfully`);
            })
            .catch(error => {
                this.log.error(`error: ${error.statusText}`);
                console.log(error);
            });
    }

    saveEntryTemplate(viewModel : ViewModel<any>){
        let reference;
        const { templateModel, translations } = splitTranslationFromModel(viewModel);
        let models = [];
        let save: SaveFunction;
        let queue = [];

        switch(viewModel.modelType){
            case ViewModelType.EventEntry: reference = templateModel as EntryType.Event; break;
            case ViewModelType.CampaignEntry: reference = templateModel as EntryType.Campaign; break;
            case ViewModelType.HighlightEntry: reference = templateModel as EntryType.Highlight; break;
            case ViewModelType.ShakeEntry: reference = templateModel as EntryType.Legacy_Shake; break;
            case ViewModelType.QuizEntry: reference = templateModel as EntryType.Quiz; break;
            default: console.log("This entry type doesn't correspond to the ones included on the system"); break;
          }

        if (viewModel.multipleFilterPackages.length > 0) {
            models = viewModel.multipleFilterPackages.map(filterPackage => {
                const newModel = _.cloneDeep(templateModel);
                return Object.assign(newModel, {
                    Filter: filterPackage.filter,
                    Date: filterPackage.start,
                    EndDate: filterPackage.end,
                });
            });
        } else {
            models = [reference];
        }
        
        models.forEach(model => {
            const controller = this.saveMap[viewModel.modelType];
            if(viewModel.state == ViewModelState.NEW){
                console.log(model);
                save = controller.newsave.bind(this.remoteAPI);
            }
            else{
                console.log(model);
                save = controller.update.bind(this.remoteAPI);
            }
                  
        queue.push(save(model, translationsToRemoteTranslationModel(translations)).toPromise());
        });

        Promise.all(queue).then(async response => {
            const newViewModels = response.map(response => mapModelIntoViewModel(response));
                await this.reloadSupportData();
                this.log.success(`saved successfully ${response.length} models`);
                this.afterSaveCallbacks.forEach(callback => callback(newViewModels)); 
                this.feedManager.updateFeed();
        }).catch(error => {
            this.log.error(`error: ${error.statusText}`);
            console.log(error);
        });
    }

    public reloadSupportData() {
        return Promise.all([
            this.fillTranslationsCache(),
            this.fillFilesCache(),
        ]);
    }

    public getViewModels(): Observable<ViewModel<any>[]> {
        return this.remoteAPI.get_list_entries(this.filter.remoteFeedPayload, this.filter.remoteFeedPageNumber, this.filter.remoteFeedLimitNumber, this.filter.remoteFeedStartDate.toISOString().slice(0, 10), this.filter.remoteFeedEndDate.toISOString().slice(0, 10))
            .pipe(markEntriesWithType, markEntriesFromRemote, mapEntriesToViewModel);
    }

    fillFilesCache() {
        this.getFiles();
    }

    getFiles(prefix: string = '', onlyFolders: boolean = false): Promise<string[]> {
        if (this.cachedFileResponse[prefix]) { return new Promise((resolve) => {  resolve(this.cachedFileResponse[prefix]) }); }
        else {
            return this.remoteAPI.get_mediaFiles(prefix, onlyFolders).toPromise().then(
                (response) => {
                    const s3response = response;
                    let files: string[] = s3response.Contents.map((item) => item.Key);
                    files = files.filter((file) => !file.endsWith('/'));
                    this.cachedFileResponse[prefix] = files;
                    return files;
                }
            );
        }
    }

    public uploadFiles(images: FormData, folder: string){
        return this.remoteAPI.post_uploadMedia(images, folder).toPromise();
    }

    getPrizeSets(){
        return this.remoteAPI.get_prizeSets().toPromise();
    }

    getPrizeSetById(id: string){
        return this.remoteAPI.get_prizeSetById(id).toPromise();
    }

    getPrizesFromPrizeSet(id: string){
        return this.remoteAPI.vnext_get_prizes_from_prizeSet(id).toPromise();
    }

    getFilters() {
        return this.remoteAPI.get_list_filters().toPromise();
    }

    getDashboards() {
        return this.remoteAPI.get_list_dashboards().toPromise();
    }

    getQuestions() {
        return this.remoteAPI.get_list_questionOptions().toPromise();
    }

    getCoupons() {
        return this.remoteAPI.legacy_get_list_coupons().toPromise();
    }

    getEnvelopes() {
        return this.remoteAPI.legacy_get_list_envelopes().toPromise();
    }

    getTournaments() {
        return this.remoteAPI.get_list_tournaments().toPromise();
    }

    getCompetitors() {
        return this.remoteAPI.get_list_competitors().toPromise();
    }

    getSports(): Promise<Sport[]> {
        return this.remoteAPI.get_list_sports().toPromise();
    }

    getGoals(): Promise<Goal[]> {
        return this.remoteAPI.get_list_goals().toPromise();
    }

    getLeagues(): Promise<League[]>{
        return this.remoteAPI.get_list_leagues().toPromise();
    }

    getStickerGroups(): Promise<StickerGroup[]>{
        return this.remoteAPI.get_list_sticker_group().toPromise();
    }

    getStickerGroupId(id: string){
        return this.remoteAPI.get_sticker_group(id).toPromise();
    }

    getNumberofShakes(id:string){
        return this.remoteAPI.get_number_of_shakes_sticker(id).toPromise();
    }

    getClients(){
        return this.remoteAPI.get_clients().toPromise();
    }

    async getSportGoals() {
        const goals = await this.getGoals();
        return [{
            sportKey: 'MMA', goalPackages: [
                {
                    name: 'Luta MMA com 3 Rondas', goals: [
                        goals.find(goal => goal.Name == "Vencedor Simples (Com empate)"),
                        goals.find(goal => goal.Name == "Tipo de Vitoria (MMA)"),
                        goals.find(goal => goal.Name == "3 Rounds (MMA)")
                    ]
                },
                {
                    name: 'Luta MMA com 5 rondas', goals: [
                        goals.find(goal => goal.Name == "Vencedor Simples (Com empate)"),
                        goals.find(goal => goal.Name == "Tipo de Vitoria (MMA)"),
                        goals.find(goal => goal.Name == "5 Rounds (MMA)")
                    ]
                }
            ]
        },
        {
            sportKey: 'Futebol', goalPackages: [
                {
                    name: 'Vencedor Numérico', goals: [
                        goals.find(goal => goal.Name == "Vencedor Numerico")
                    ]
                }
            ]
        },
        {
            sportKey: 'Formula 1', goalPackages: [
                {
                    name: 'Podium', goals: [
                        goals.find(goal => goal.Name == "Podium")
                    ]
                }
            ]
        },
        {
            sportKey: 'Stock Car Brasil', goalPackages: [
                {
                    name: 'Podium', goals: [
                        goals.find(goal => goal.Name == "Podium")
                    ]
                }
            ]
        },
        {
            sportKey: '*', goalPackages: goals.map(goal => ({ name: goal.Name, goals: [ goal ] }))
        }

    ];
    }
    
    getCampaigns(): Promise<CampaignEntry[]> {
        return this.remoteAPI.get_list_campaigns().toPromise();
    }
    getCampaign(id : string): Promise<CampaignEntry> {
        return this.remoteAPI.get_campaign(id).toPromise();
    }

    getTemplates(){
        return this.remoteAPI.get_templates().pipe(markEntriesWithType, markEntriesFromRemote, mapEntriesToViewModel);
    }

    fillTranslationsCache() {
        return this.remoteAPI.get_localization().toPromise().then(translations => {
            this.cachedTranslationResponse = translations;
        });
    }

    getTranslation(key: string): Promise<any> {
        if (this.cachedTranslationResponse) {
            return new Promise((resolve) => {
                resolve(this.cachedTranslationResponse.Localization.Values[key]);
            });
        } else {
            return this.updateCacheTranslation().then(response => {
                return response.Localization.Values[key];
            })
        }
    }

    updateCacheTranslation() : Promise<any> {
       return this.remoteAPI.get_localization().toPromise().then(translations => {
            this.cachedTranslationResponse = translations;
            return translations;
        });
    }

    getAvailableLanguages(): Promise<Language[]> {
        if (this.cachedTranslationResponse) { return new Promise((resolve) => { resolve(this.cachedTranslationResponse.Languages) }) }
        else {
            return this.remoteAPI.get_localization().toPromise().then(translations => {
                this.cachedTranslationResponse = translations;
                return translations.Languages;
            });
        }
    }

}
