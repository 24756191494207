import { Component, OnInit } from '@angular/core';
import { Sport } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { injectTranslation } from 'src/core/translations';

@Component({
  selector: 'app-editor-sport',
  templateUrl: './editor-sport.component.html',
  styleUrls: ['./editor-sport.component.scss']
})
export class EditorSportComponent extends Editor<Sport> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onNameChanged(nameTranslation) {
    this.model.NameKey = nameTranslation.key;
    injectTranslation(nameTranslation, this.viewModel);
  }

  onIconChanged(iconUrl) {
    this.model.IconUrl = iconUrl;
  }
}
