
export enum LogLevel {
    NONE    = 0,
    INFO    = 1 << 0,
    WARN    = 1 << 1,
    ERROR   = 1 << 2,
    SUCCESS = 1 << 3,

    ALL = INFO | WARN | ERROR | SUCCESS,
}

export enum LogOutput {
    NONE  = 0,
    CONSOLE  = 1 << 16,
    STORAGE  = 1 << 17,
    USERVIEW = 1 << 18,
    ALL = CONSOLE | STORAGE | USERVIEW,
}

export type LogMessage = {text: string, title?: string} | string

export function isMessageObject(message: LogMessage): message is {text: string, title?: string} {
    return (<{text: string, title?: string}>message).text ? true : false;
}

export abstract class LogImplementation {
    logLevelMap: { [key: number]: (text: string, title: string) => any } = {};

    private log(level: LogLevel, message: LogMessage, contextObject?: any, trace?: any): void {
        let title: string, text: string;

        if (isMessageObject(message)) {
            title = message.title ? message.title : "";
            text = message.text;
        }
        else {
            title = "";
            text = message;
        }

        this.logLevelMap[level](text, title);
    }
}
