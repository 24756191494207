import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Client } from '@gsegames/arena.models/lib/Client';
import { RemoteService } from 'src/services/RemoteService';

type ClientViewModel = {label: string, key: string}

@Component({
  selector: 'select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.css']
})
export class SelectClientComponent implements OnInit {
  
  @Input() initialClient: Client = null;
  @Input() editDisabled: boolean;
  @Output() clientChanged = new EventEmitter<Client>();

  remoteClientList: Client[]
  clientList: ClientViewModel[]
  client: ClientViewModel = { label: null, key: null }

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if (this.initialClient) {
      this.client = { label: this.initialClient.Name, key: this.initialClient._id }
    }

    this.updateClientListFromRemote();
  }

  updateClientListFromRemote() {
    this.remote.getClients().then(Clients => {
      this.remoteClientList = Clients;
      this.clientList = Clients.map( Client => ({ label: Client.Name, key: Client._id }) )
    })
  }

  onClientChanged(ClientKey) {
    this.client = this.clientList.find(client => client.key == ClientKey);
    let client = this.remoteClientList.find(remoteClient => remoteClient._id == this.client.key)
    this.clientChanged.emit(client);
  }
}
