import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EnvelopeType } from '@gsegames/arena.models';

@Component({
  selector: 'select-envelopeType',
  moduleId: module.id,
  templateUrl: 'select.envelope.type.component.html',
  styleUrls: ['select.envelope.type.component.css']
})
export class SelectEnvelopeTypeComponent implements OnInit{
  @Input() initialEnvelopeType: EnvelopeType ;
  @Input() editDisabled: boolean;
  @Output() envelopeTypeChanged = new EventEmitter<number>();

  EnvelopeTypes = EnvelopeType;
  name: string;
  names = Object.keys(this.EnvelopeTypes);
  index : number;

  keys() : Array<string> {
    return this.names.slice(this.names.length / 2);
  }

  constructor() { }

  ngOnInit() {
    if(this.initialEnvelopeType){
      console.log(this.initialEnvelopeType); 
       this.name = this.names.find(element =>{ if(Number(element) == this.initialEnvelopeType) return true; else{return false;} });
    }
    this.name = this.names.find(element =>{ if(Number(element) == this.initialEnvelopeType) return true; else{return false;} });
    console.log(this.EnvelopeTypes);
  }

  onEnvelopeTypeChanged(EnvelopeType){
    this.envelopeTypeChanged.emit(Number(EnvelopeType));
  }
}
