import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrizeSet, PrizeType } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';

type PrizeSetViewModel = { label: string, key: string };

@Component({
  selector: 'select-prize-set',
  moduleId: module.id,
  templateUrl: 'select.prize.set.component.html',
  styleUrls: ['select.prize.set.component.css']
})

export class SelectPrizeSetComponent implements OnInit{
  
  @Input() initialPrize: PrizeSet = null;
  @Input() editDisabled: boolean = null;
  @Input() last: boolean = null;
  @Output() prizeSetChanged = new EventEmitter<PrizeSet>();

  remoteprizeSetList: PrizeSet[];
  prizeSetList: PrizeSetViewModel[];
  prizeSet: PrizeSetViewModel = { label: null, key: null };

  constructor(private remote: RemoteService) { }

  ngOnInit() {

    if (this.initialPrize) {
      this.prizeSet = { label: this.initialPrize.Name.Translations['en'] , key: this.initialPrize._id };
    }

    this.updateprizeSetListFromRemote();
  }

  updateprizeSetListFromRemote() {
    this.remote.getPrizeSets().then(prizeSets => {
      console.log(prizeSets);
      this.remoteprizeSetList = prizeSets;
      this.prizeSetList = this.last == true ? prizeSets.filter(element => element.CanBeInfinite == true).map( prizeSet => ({ label: prizeSet.Name.Translations['en'] , key: prizeSet._id })) : prizeSets.map( prizeSet => ({ label: prizeSet.Name.Translations['en'] , key: prizeSet._id }));
    });
  }

  onPrizeSetChanged(prizeSetKey) {
    this.prizeSet = this.prizeSetList.find(prizeSet => prizeSet.key === prizeSetKey);
    const prizeSet = this.remoteprizeSetList.find(remoteprizeSet => remoteprizeSet._id === this.prizeSet.key);
    this.prizeSetChanged.emit(prizeSet);
  }
}
