import { Component, OnInit } from '@angular/core';
import { Viewer } from '../viewer';
import * as moment from 'moment';
import { CampaignEntry } from '@gsegames/arena.models/lib/Entries/CampaignEntry';

@Component({
  selector: 'app-viewer-campaign-entry',
  templateUrl: './viewer-campaign-entry.component.html',
  styleUrls: ['./viewer-campaign-entry.component.css']
})
export class ViewerCampaignEntryComponent extends Viewer<CampaignEntry> implements OnInit {


  days: number;

  constructor() {
    super();
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.emitViewData();
  }

  onViewerModelUpdate() {
    this.emitViewData();
  }
  emitViewData() {
    let StartDate;
    let EndDate;
    let days: number;
    let startDays: number;
    StartDate = moment(this.model.Date);
    EndDate = moment(this.model.EndDate);

    days = EndDate.diff(new Date(), 'days');
    startDays = StartDate.diff(new Date(), 'days');


    this.days = days;
    if (startDays > 0) {
      this.viewData.emit({
        title: `${this.model.Name}`,
        lead: `Campaign: ${moment(this.model.Date).format('llll')} - ${startDays} days for the campaign to start`,
        date: this.model.Date
      });
    }
    else if(days >= 0){
      this.viewData.emit({
        title: `${this.model.Name}`,
        lead: `Campaign: ${moment(this.model.Date).format('llll')} - Active / ${days} days until the campaign ends`,
        date: this.model.Date
      });
    }
    else if(days < 0){
      this.viewData.emit({
        title: `${this.model.Name}`,
        lead: `Campaign: ${moment(this.model.Date).format('llll')} - Finished`,
        date: this.model.Date
      });
    }
  }
}
