import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FilterWithDateTime } from '../../selectors/field-filter-with-datetime/field-filter-with-datetime.component';
import { ViewModel } from 'src/models/ViewModel';
import { EntryType, RewardType, ShakeType } from '@gsegames/arena.models';
import { translationPrefix } from 'src/constants/translation-prefixes';
import { getTranslations, injectTranslation, mainLanguage } from 'src/core/translations';
import { RemoteAPIService } from 'src/services/RemoteAPIService';
import { RemoteService } from 'src/services/RemoteService';

@Component({
  selector: 'insert-template',
  moduleId: module.id,
  templateUrl: 'insert.template.component.html',
  styleUrls: ['insert.template.component.css']
})
export class InsertTemplateComponent implements OnInit {
  
  @Input() templateModel;
  @Output() onSave = new EventEmitter<ViewModel<any>>();
  @Output() close = new EventEmitter<any>();

  translationType : string = '';
  numberShakes ;
  minutes = 0;

  rewardType = RewardType;
  entryType = EntryType;
  shakeType = ShakeType;
  startDate = new Date();

  constructor(private remote : RemoteService) {
  }

  ngOnInit(){

    if(!this.templateModel){
      this.templateModel = {}; 
    } 
    
    switch(this.templateModel.localModel.EntryType){
      case this.entryType.Event : this.translationType = translationPrefix.Event.Name; break;
      case this.entryType.Legacy_Shake : this.translationType = translationPrefix.Shake.Name;
      this.remote.getNumberofShakes(this.templateModel.localModel.StickerGroupId)
      .then( number => {
          let available: number = number.availableCount > 0 ? number.availableCount : 0; 
          this.numberShakes = available; 
        });
        this.templateModel.localModel.CloseDate = this.startDate;
       break;
      case this.entryType.Quiz : this.translationType = translationPrefix.Quiz.Name; break;
    }

    this.templateModel.localModel.Date = this.startDate;
    this.templateModel.localModel.EndDate = this.startDate;
    console.log(this.templateModel);
  }

  get filterWithDateTime(): FilterWithDateTime[] {
    if (this.templateModel.fromRemoteAndTemplate()) {
      return [{
        filter: this.templateModel.localModel.Filter,
        start: this.templateModel.localModel.Date,
        end: this.templateModel.localModel.EndDate
      }];
    } else if (this.templateModel.multipleFilterPackages.length > 0) {
      return this.templateModel.multipleFilterPackages;
    } else {
      return null;
    }
  }


  translationFor(key) {
    let translation;
    
    if(this.templateModel.localModel.TranslationsBundle){
      translation = this.templateModel.localModel.TranslationsBundle.Translations.find( element => element.FieldName == key );
    }

    return translation;
  }

  onNameChanged(translation) {
    this.templateModel.localModel.NameKey = translation.Identifier;
    if(!this.templateModel.localModel.TranslationsBundle) this.templateModel.TranslationsBundle = {Translations: []};
    let dataFound = this.templateModel.localModel.TranslationsBundle.Translations.find(element => element.FieldName == translation.FieldName);
      if(dataFound)
        this.templateModel.localModel.TranslationsBundle.Translations[this.templateModel.localModel.TranslationsBundle.Translations.indexOf(dataFound)] = translation;
      else
        this.templateModel.localModel.TranslationsBundle.Translations.push(translation);
        
    console.log(translation);
  }

  //Temporary solution until translations are done
  getNameModel(key): string{
    try{ return this.templateModel.localModel.TranslationsBundle.Translations.find( element => element.FieldName == key ).Translations[mainLanguage] }
    catch(err){ console.log(err); return "";}
  }

  onFilterWithDateTimeChanged(filterPackages: FilterWithDateTime[]) {
    this.templateModel.multipleFilterPackages = filterPackages;
    if(filterPackages.length == 1){
      this.templateModel.localModel.Date = filterPackages[0].start;
      this.templateModel.localModel.EndDate = filterPackages[0].end;
    }
  }

  onEndDateChanged(date: Date) {
    this.templateModel.localModel.EndDate = date;
  }

  onEndDateChangedShake(minutes) {
    let filterPackage = new FilterWithDateTime(); 
    this.minutes = minutes;
    if(this.templateModel.localModel.ShakeType == ShakeType.Question){
      this.templateModel.localModel.CloseDate = new Date(this.startDate.getTime() + this.minutes * 60000);
      this.templateModel.localModel.EndDate = new Date(this.templateModel.localModel.CloseDate);
      this.templateModel.localModel.EndDate.setDate(this.templateModel.localModel.EndDate.getDate() + 1);
    }
    else{
    this.templateModel.localModel.EndDate = new Date(this.startDate.getTime() + this.minutes * 60000);
    }  

    if(this.templateModel.multipleFilterPackages.length == 1 && this.templateModel.multipleFilterPackages){
      filterPackage.filter = this.templateModel.localModel.Filter;
      filterPackage.start = this.templateModel.localModel.Date;
      filterPackage.end = this.templateModel.localModel.EndDate;
    }
    console.log(this.templateModel.multipleFilterPackages);
    this.templateModel.multipleFilterPackages = [filterPackage];
  }

  onCampaignChanged(Campaign){
    this.templateModel.localModel.CampaignId = Campaign.key;
    this.templateModel.localModel.CampaignPosition = Campaign.position;
    console.log(Campaign);
  }

  save(){
    console.log(this.templateModel);
    this.onSave.emit(this.templateModel);
  }

  get CampaignIdAndPosition() {
    if (this.templateModel) {
      return {
      key : this.templateModel.localModel.CampaignId,
      position: this.templateModel.localModel.CampaignPosition,
      };
    }
  }

  closeModal(){
    this.close.emit();
  }
}
