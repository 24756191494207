import { TranslationViewModel } from 'src/core/translations';
import { EntryType, EnvelopedPrize } from '@gsegames/arena.models';
import * as _ from 'lodash';
import { ValidationChain } from 'src/core/validations';
import { FilterWithDateTime } from 'src/shell/feed/viewmodel-controller/selectors/field-filter-with-datetime/field-filter-with-datetime.component';

export enum ViewModelType {
    EventEntry     = "EventEntry",
    HighlightEntry = "HighlightEntry",
    ShakeEntry     = "ShakeEntry",
    QuestionEntry  = "QuestionEntry",
    QuizEntry      = "QuizEntry",
    CampaignEntry  = "CampaignEntry",
    Sports         = "Sports",
    Tournaments    = "Tournaments",
    Competitors    = "Competitors",
    Objectives     = "Objectives",
    Filters        = "Filters",
    ContentByCode  = "ContentByCode",
    Results        = "Results",
    Envelops       = "Envelops",
    Coupons        = "Coupons",
    Goals          = "Goals",
    Questions      = "Questions",
    Levels         = "Levels",
    Translations   = "Translations",
    Media          = "Media",
    Client         = "Client"
}

export const mapEntryTypeToViewModelType = {};
mapEntryTypeToViewModelType[EntryType.Quiz]      = ViewModelType.QuizEntry;
mapEntryTypeToViewModelType[EntryType.Event]     = ViewModelType.EventEntry;
mapEntryTypeToViewModelType[EntryType.Legacy_Shake]     = ViewModelType.ShakeEntry;
mapEntryTypeToViewModelType[EntryType.Question]  = ViewModelType.QuestionEntry;
mapEntryTypeToViewModelType[EntryType.Highlight] = ViewModelType.HighlightEntry;
mapEntryTypeToViewModelType[EntryType.Campaign] = ViewModelType.CampaignEntry;

/**
 * ViewModelState caption:
 *      CLEAN    - no local modification was made to the viewmodel
 *      NEW      - a new viewmodel, not yet synchronized with the remote
 *      EDITED   - a viewmodel with local changes, not yet synchronized with the remote
 *      DELETED  - a viewmodel was deleted locally, not yet synchronized with the remote
 *
 *  - NEW and EDITED viewmodels, upon successfully synchronizing with the remote, are set to CLEAN
 *  - DELETED viewmodels, if the remote accepts their elimination, are simply removed
 *  - CLEAN viewmodels can be set to EDITED or DELETED upon user actions
 */
export enum ViewModelState {
    NONE    = 0,
    CLEAN   = 1,
    NEW     = 2,
    EDITED  = 3,
    DELETED = 4,
}

export interface Identifiable {
    id: string;
}

export interface Clonable<T> {
    /**
     * @returns a deep clone of the model
     */
    clone(o: T): T;
}

export interface Diffable<T> {
    /**
     * @param source the object that will serve as base for the comparison
     * @param dest the object that will register the differences
     * @returns an object with the fields where differences were noted
     *
     * ´´´
     * source = { foo: "bar", baz: "xyz" }
     * dest   = { foo: "123", pie: true }
     * diff(source, dest) => {
     *   changes: { foo: "123" },
     *   additions: { pie: true },
     *   eliminations: { baz: "xyz" }
     * }
     * ´´´
     */
    diff(source: T, dest: T): { changes: any, additions: any, eliminations: any };
}

/**
 * The base for all ViewModels
 *
 * It has a reference for both the original model received from the remote
 * and a reference to the local model (which can be changed by the system and the user)
 */
export class ViewModel<T> implements Identifiable, Clonable<T>, Diffable<T> {
    remoteModel: T;
    localModel: T;

    id: string = null;
    modelType: string = null;
    state: ViewModelState = ViewModelState.NONE;
    fromRemote = false;
    fromTemplate = false;
    translations: TranslationViewModel[] = [];

    envelopedPrizes?: EnvelopedPrize[];
    lastEnvelopedPrize?: EnvelopedPrize;

    multipleFilterPackages: FilterWithDateTime[] = [];

    clone;
    diff;

    validators: { [key: string]: ValidationChain } = {};

    requesterId?: string;

    constructor(remoteModel: T, modelType: ViewModelType, {id}: Identifiable, {clone}: Clonable<T>, {diff}: Diffable<T>, options?: { fromRemote: boolean }) {
        if (options) {
            if (!_.isNil(options.fromRemote)) {
                this.fromRemote = options.fromRemote;
            }
        }

        this.id = id;
        this.modelType = modelType;
        this.clone = clone;
        this.diff = diff;

        this.state = ViewModelState.CLEAN;

        this.remoteModel = remoteModel;
        this.localModel = this.clone(remoteModel);
    }

    fromRemoteAndTemplate(): boolean{
        if(this.fromRemote || this.fromTemplate){
            return true;
        }

        return false;
    }
}
