import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GoalType } from '@gsegames/arena.models';

@Component({
  selector: 'select-goalType',
  moduleId: module.id,
  templateUrl: 'select.goal.type.component.html',
  styleUrls: ['select.goal.type.component.css']
})
export class SelectGoalTypeComponent {
  
  @Input() initialGoalType: GoalType = null;
  @Input() editDisabled: boolean;
  @Output() goalTypeChanged = new EventEmitter<number>();

  goalTypes = GoalType;
  name: string;
  names = Object.keys(this.goalTypes);
  index : number;

  keys() : Array<string> {
    return this.names.slice(this.names.length / 2);
  }

  constructor() { }

  ngOnInit() {
    if(this.initialGoalType){
       this.name = this.names.find(element =>{ if(Number(element) == this.initialGoalType) return true; else{return false;}});
    }
    console.log(this.names);
  }

  onGoalTypeChanged(goalType){
    this.goalTypeChanged.emit(Number(goalType));
  }
}
