import { Component, OnInit } from '@angular/core';
import { Tournament } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { injectTranslation } from 'src/core/translations';

@Component({
  selector: 'app-editor-tournament',
  templateUrl: './editor-tournament.component.html',
  styleUrls: ['./editor-tournament.component.scss']
})
export class EditorTournamentComponent extends Editor<Tournament> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onNameChanged(nameTranslation) {
    this.model.NameKey = nameTranslation.key;
    injectTranslation(nameTranslation, this.viewModel);
  }

  onIconChanged(iconUrl) {
    this.model.IconUrl = iconUrl;
  }

  onSportChanged(sport) {
    this.model.Sport = sport;
  }

}
