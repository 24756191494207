import { Component, OnInit } from '@angular/core';
import { Editor } from '../editor';
import { Envelope } from '@gsegames/arena.models';

@Component({
  selector: 'app-editor-envelop',
  templateUrl: './editor-envelop.component.html',
  styleUrls: ['./editor-envelop.component.scss']
})
export class EditorEnvelopComponent extends Editor<Envelope> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
