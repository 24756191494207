import { Injectable } from '@angular/core';
import { LogImplementation, LogLevel } from 'src/core/log';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class LogUserViewService extends LogImplementation {

    constructor(private toastr: ToastrService) {
        super();

        toastr.toastrConfig.positionClass = 'toast-bottom-right';

        this.logLevelMap[LogLevel.INFO]    = (text, title?) => this.toastr.info(text, title);
        this.logLevelMap[LogLevel.WARN]    = (text, title?) => this.toastr.warning(text, title);
        this.logLevelMap[LogLevel.ERROR]   = (text, title?) => this.toastr.error(text, title);
        this.logLevelMap[LogLevel.SUCCESS] = (text, title?) => this.toastr.success(text, title);
    }

}