import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Viewer } from '../viewer';
import { EventEntry, Competitor } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';
import { translationPrefix } from 'src/constants/translation-prefixes';
import * as moment from 'moment';
import { TranslationRemoteModel, translationKeyPrefixed, mainLanguage, getTranslations, mapTranslationsViewModelToRemoteModel } from 'src/core/translations';
import { ViewModelControllerComponent } from '../../viewmodel-controller.component';

@Component({
  selector: 'viewer-event-entry',
  templateUrl: './viewer-event-entry.component.html',
  styleUrls: ['./viewer-event-entry.component.scss']
})
export class ViewerEventEntryComponent extends Viewer<EventEntry> implements OnInit {

  eventEntry: Viewer<EventEntry>;

  translations: TranslationRemoteModel = {};

  selectedGoalName : string;

  message : string = "";

  translationPrefixMap = {
    'NameKey': translationPrefix.Event.Name,
    'LocationKey': translationPrefix.Event.Location,
    'Tournament.NameKey': translationPrefix.Tournament.Name,
  };

  constructor(private remote: RemoteService) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.eventEntry = this;
    this.emitViewData();
  }

  translation(key: string) {
     try{ return this.model.TranslationsBundle.Translations.find(element => element.FieldName == key).Translations[mainLanguage]; }
     catch(err){ console.log(err); return ""; };  
  }

  onViewerModelUpdate() {
    const translations = this.model.TranslationsBundle.Translations;
    if (translations.length > 0) {
      this.updateTranslations(translations);
    }
    this.emitViewData();
  }

  updateTranslations(translations) {
    this.translations = mapTranslationsViewModelToRemoteModel(translations);
  }

  VerifyGoals(){
    return this.model.Goals.find(goal => goal.Name == 'Podium');
  }

  emitViewData() {
    let eventName = 'Event';

    //Verify if the state of the model is new
    if (this.viewerModel.state == 2)
      eventName = 'New Event Entry'
    else
      eventName = this.translation("NameKey");

    console.log(eventName);
    this.viewData.emit({
      title: `${eventName}`,
      lead: `Event: ${moment(this.model.Date).format('llll')}`,
      date: this.model.Date
    });
  }

  getCompetitor(competitorIndex) {
    return this.model.Competitors && this.model.Competitors[competitorIndex]
      ? this.model.Competitors[competitorIndex]
      : {
        Name: '',
        IconUrl: '',
      };
  }

  getTournament() {
    return this.model.Tournament
      ? this.model.Tournament
      : {
        IconUrl: '',
      };
  }

  verifyResult(): boolean {
    let dateNow = new Date();
    let dateM = new Date(this.model.Date);
    return dateNow.getTime() > dateM.getTime() && this.eventEntry.viewerModel.state != 2; 
  }

  messageShow(): boolean{
    if(this.message != ''){
      console.log(this.message);
      return true;
    }
    return false;
  }

  warningResult(event){
    if(event == true)
      this.message = "The results were sent successfully";
    else
      this.message = "Something went wrong with the results";
  }
}
