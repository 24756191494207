import { ViewModel } from 'src/models/ViewModel';

export const mainLanguage = 'en';

export interface TranslationRemoteModel {
    [key: string]: { 'en': string, 'pt-BR': string } | any[];
}

export interface TranslationViewModel {
    key: string;
    path: string;
    translations: { [language: string]: string };
    isKeyEditable: boolean;
    guid: string | 'remote';
    data: {},
    oldKey: string;  
}

const injectionKey = '_backoffice_translation';

export function mapTranslationsViewModelToRemoteModel(translations: TranslationViewModel[]): TranslationRemoteModel {
    return translations.reduce((remoteModel, viewModel) => {
        remoteModel[viewModel.path] = viewModel.translations;
        return remoteModel;
    }, {});
}

export function injectTranslation(translation, viewModel: ViewModel<any>) {
    viewModel.translations = viewModel.translations
        .filter( t => t.key !== translation.key )
        .concat(translation);
}

export function getTranslations(viewModel: ViewModel<any>): TranslationViewModel[] {
    return viewModel.translations;
}

export function getTranslationByKey(object, key, language) {
    const translation = (object[injectionKey] || []).find(translation => translation.key === key);
    return translation && translation.translations && translation.translations[language] ? translation.translations[language] : '';
}

export function deleteTranslations(object) {
    delete object[injectionKey];
}

export function translationsToRemoteTranslationModel(translations: TranslationViewModel[]) {
    if (!translations) {
        return null;
    }

    const localizations = {};

    translations.reduce((localization, translation) => {
        localization[translation.path + translation.key] = translation.translations;
        return localization;
    }, localizations );

    return localizations;
}

export function translationKeyPrefixed(translationKey: string, prefix: string): string {
    if (translationKey.startsWith(prefix)) {
        return translationKey;
    } else {
        return prefix + translationKey;
    }
}
