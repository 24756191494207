import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Question, QuestionType } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';

type QuestionViewModel = {label: string, key: string, question: Question}

@Component({
  selector: 'select-question',
  templateUrl: './select-question.component.html',
  styleUrls: ['./select-question.component.scss']
})
export class SelectQuestionComponent implements OnInit {

  @Input() initialQuestion: Question = null
  @Input() questionTypes: QuestionType[] = [
    QuestionType.MultipleChoice,
    QuestionType.SimpleSide,
    QuestionType.SingleOption,
    QuestionType.Quiz,
  ];
  @Output() questionChanged = new EventEmitter<Question>()

  questionList: QuestionViewModel[]
  question: QuestionViewModel = { label: null, key: null, question: null };

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if (this.initialQuestion) {
      this.question = { label: this.initialQuestion.InstructionKey, key: this.initialQuestion._id, question: this.initialQuestion };
    }

    this.updateQuestionListFromRemote();
  }

  updateQuestionListFromRemote() {
    this.remote.getQuestions().then( questions => {
      this.questionList = questions
        .map( question => ({ label: question.InstructionKey, key: question._id, question }))
        .filter( question => this.questionTypes.includes(question.question.QuestionType) );
    });
  }

  onQuestionChanged(questionKey: string) {
    this.question = this.questionList.find(question => question.key == questionKey);
    this.questionChanged.emit(this.question.question);
  }

}
