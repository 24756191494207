import { Component, OnInit, AfterViewInit, Input, Output } from '@angular/core';
import { Viewer } from '../viewer';
import { QuizEntry, Question, TargetType, TextTarget, CompetitorTarget, Answer } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';
import { translationPrefix } from 'src/constants/translation-prefixes';
import { translationKeyPrefixed, TranslationRemoteModel, mainLanguage, getTranslations, mapTranslationsViewModelToRemoteModel } from 'src/core/translations';
import * as moment from 'moment';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-viewer-quiz-entry',
  templateUrl: './viewer-quiz-entry.component.html',
  styleUrls: ['./viewer-quiz-entry.component.scss']
})
export class ViewerQuizEntryComponent extends Viewer<QuizEntry> implements OnInit {

  @Input() tabindex: any;
  Collapsed: boolean = false;
  
  translations: TranslationRemoteModel = {};

  translationPrefixMap = {
    'NameKey': translationPrefix.Quiz.Name,
    'TermsKey': translationPrefix.Quiz.Terms,
    'ImageBannerKey': translationPrefix.Quiz.ImageBanner,
    'PrizeTextDescriptionKey': translationPrefix.Quiz.PrizeTextDescription,
    'ImageDefaultVideoKey': translationPrefix.Quiz.ImageDefaultVideo,
  };

  constructor(private remote: RemoteService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.emitViewData();
  }


  CheckTabIndex(index: string): boolean{
      if(index == this.tabindex)
      return true;

    return false;
  }

  GetAnswersFromQuestion(quest : Question){
    if(quest == null){
      return [];
    }
    else{
      return quest.Targets.map(target => {
        switch (target.TargetType) {
            case TargetType.Text:
                return (target as TextTarget).NameKey
            case TargetType.Competitor:
                return (target as CompetitorTarget).Competitor;
            default: 
              return "Not Available";
          }
      });
    }
  }

  onViewerModelUpdate() {
    const translations = this.model.TranslationsBundle.Translations;
    if (translations.length > 0) {
      this.updateTranslations(translations);
    }
    this.emitViewData();
  }

  ShowTimeLeft() : string{
    return `Date Start :- ${ moment(this.model.Date).format('llll') } Date End :- ${ moment(this.model.EndDate).format('llll') }`
  }

  updateTranslations(translations) {
    this.translations = mapTranslationsViewModelToRemoteModel(translations);
  }

  emitViewData() {
    let quizName = 'Quiz';

    if(this.viewerModel.state == 2)
      quizName = 'New Quiz Entry'
    else
      quizName = this.translation("NameKey");

    this.viewData.emit({
      title: `${quizName}`,
      lead: `Quiz - ${ moment(this.model.Date).format('llll') }`,
      date: this.model.Date
    });
}

  translation(key: string) {
    try{ return this.model.TranslationsBundle.Translations.find(element => element.FieldName == key).Translations[mainLanguage]; }
    catch(err){ console.log(err); return ""; };
  }

}
