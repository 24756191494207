import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Failure, FailureType, Result } from '../core/results';
import { environment } from 'src/environments/environment';
import { URL } from 'url';

type EnvironmentChangeCallback = (newEnvironment: string) => void;

@Injectable()
export class RemoteEnvironmentService {

    private onEnvironmentChange: EnvironmentChangeCallback[];

    constructor(private http: HttpClient) { }

    /** get the current environment */
    public get environment() { return environment; }

    /**
     * @param path relative path to the API base URL
     * @returns the complete path to the API
     */
    public apiURL(path: string): string {
        return environment.remote.apiBaseURL + path
    }

    /**
     * Change the current environment if it exists.
     * If the environment requested [[env]] exists, the other services are
     * notified of the change. They should take action if needed.
     * If it doesn't exist, nothing changes.
     *
     * @returns [[Success]] if the operation was a success, [[Failure]] otherwise
     */
    public changeEnvironment(env: string): Result {
        return new Failure(FailureType.NOT_IMPLEMENTED);
    }

    /**
     * @param listener a function that will be called when the environment is changed
     */
    public listenEnvironmentChange(listener: EnvironmentChangeCallback) {
        if (listener) {
            this.onEnvironmentChange.push(listener);
        }
        else {
            console.error("invalid listener was passed");
        }
    }
}