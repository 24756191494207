import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RemoteService } from 'src/services/RemoteService';
import { Filter } from '@gsegames/arena.models';

type FilterViewModel = {label: string, key: string}

@Component({
  selector: 'select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {

  @Input() initialFilter: Filter = null
  @Input() statisticMode  : boolean = false;
  @Output() filterChanged = new EventEmitter<Filter>()

  remoteFilterList: Filter[]
  filterList: FilterViewModel[]
  filter: FilterViewModel = { label: null, key: null }

  constructor(private remote: RemoteService) {

   }

  ngOnInit() {
    if (this.initialFilter && this.statisticMode == false) {
      this.filter = { label: this.initialFilter.Name, key: this.initialFilter._id };
    }
    this.updateFilterListFromRemote();
  }

  updateFilterListFromRemote() {
    this.remote.getFilters().then( filters => {
      this.remoteFilterList = filters.map( filter => {
        // the query and shadow query are a JSON string,
        // but it's being automatically transformed into an actual object
        // here, that automatic process is reverted
        filter.Query = JSON.stringify(filter.Query);
        filter.ShadowQuery = JSON.stringify(filter.ShadowQuery);

        return filter;
      });
      this.filterList = filters.map( filter => ( {label: filter.Name, key: filter._id}) );
      if(this.statisticMode == true){
        this.filter = this.filterList.find(filter => filter.label == "Todos");
        this.onFilterChanged(this.filter.key);
      }
    } );
  }

  onFilterChanged(filterKey) {
    this.filter = this.filterList.find(filter => filter.key === filterKey);
    const remoteFilter = this.remoteFilterList.find( filter => filter._id === filterKey);
    this.filterChanged.emit(remoteFilter);
  }
}
