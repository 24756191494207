import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { RemoteService } from 'src/services/RemoteService';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EntryType } from '@gsegames/arena.models';

@Component({
  selector: 'select-media',
  templateUrl: './select-media.component.html',
  styleUrls: ['./select-media.component.scss']
})
export class SelectMediaComponent implements OnInit {

  @Input() initialUrl: string = "";
  @Input() folder: string;
  @Output() mediaURL = new EventEmitter<any>()

  private id = 0;
  private static index = 0;
  get selectId() { return `server-files_${this.id}`; }

  fileList: string[];
  filename: string;

  message : string = '';
  filesRef: File[];
  modalRef: BsModalRef;

  constructor(private remote: RemoteService, private modal : BsModalService) {
    SelectMediaComponent.index += 1;
    this.id = SelectMediaComponent.index;
   }

  ngOnInit() {
    if (!this.initialUrl) { this.initialUrl = ''; }

    if (this.initialUrl.startsWith(this.baseUrl)) {
      this.initialUrl = this.initialUrl.slice(this.baseUrl.length);
    }


    if (!this.folder) { this.folder = ""; }
    
    
    this.filename = this.initialUrl;

    this.message = 'Are you sure you want to upload this image to the database?';

    this.updateFileListFromRemote();
    
  }

  updateFileListFromRemote() {

    if(this.folder && this.folder == ""){
      console.log("No folder");
      this.remote.getFiles().then( files => this.fileList = files);
    }
    else if(this.folder && this.folder != ""){
      this.remote.getFiles(this.folder, false).then( files =>{ 
        if(files){
        this.fileList = files;  
        }
        else{
          console.log("No results found for the specific folder");
        }
      });
    }
  }

  filenameChanged(filename) {
    this.mediaURL.emit(this.baseUrl + filename)
  }


  openConfirmation(template: TemplateRef<any>, images: any){
    this.modalRef = this.modal.show(template, {class:'modal-dialog-centered', ignoreBackdropClick: true });
    this.message = 'Are you sure you want to upload this image <strong>'+images.files[0].name+'</strong> to the database?';
    this.filesRef = images.files;
  }

  uploadImageChanged(image : File[]){
    const files: File[] = image;
    let nameFile: string;
    const formData = new FormData();

    for (let image of files) {
        formData.append('image', image);
        nameFile = image.name;
      }
    
    
    
    this.remote.uploadFiles(formData, this.folder).then(
      (res) => {
          this.filename = this.folder.concat(nameFile);
          this.filenameChanged(this.filename);
          this.updateFileListFromRemote();
      },
      (err) => {
          console.log(err);
      }
  )
  }

  checkConfirmation(validation){
    this.modalRef.hide();
    if(validation == true){
      this.uploadImageChanged(this.filesRef); 
    }
  }

  private readonly baseUrl: string = "https://imagecache.arenagse.com/";
}

