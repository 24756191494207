import { Component, TemplateRef, EventEmitter, Input, Output, ChangeDetectorRef, ComponentRef, OnInit } from '@angular/core';
import { Viewer } from 'src/shell/feed/viewmodel-controller/viewers/viewer';
import { EventEntry, EntryType } from '@gsegames/arena.models';
import { ViewModelState, ViewModel, ViewModelType } from 'src/models/ViewModel';
import { ViewModelTypeMap, ViewModelTypeMapComponentEntry } from 'src/shell/feed/viewmodel-controller/viewmodeltypemap';
import { Editor } from 'src/shell/feed/viewmodel-controller/editors/editor';
import { StorageService } from 'src/services/StorageService';
import { LogService } from 'src/services/LogService';
import { InliningService } from 'src/services/InliningService';
import { FeedInfoService, ViewData } from 'src/services/FeedInfo.service';
import { RemoteService} from 'src/services/RemoteService';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RemoteAPIService } from 'src/services/RemoteAPIService';
import { translationsToRemoteTranslationModel } from 'src/core/translations';

@Component({
  selector: 'view-template-controller',
  moduleId: module.id,
  templateUrl: 'view.template.controller.component.html',
  styleUrls: ['view.template.controller.component.css']
})
export class ViewTemplateControllerComponent implements OnInit {


  @Input() viewModel: ViewModel<any>;

  // To Do: gather the mode and other view state and aggregate inside an object
  @Input() canBeDiscarded: boolean = false;
  @Input() canBeDeleted: boolean = false;

  @Output() discard = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  

  modalRef: BsModalRef;
  message: string;

  constructor(private storage: StorageService, private log: LogService, private inlining: InliningService, private cdRef: ChangeDetectorRef, private feed: FeedInfoService, private remote: RemoteService, private modal : BsModalService, private remoteAPI: RemoteAPIService) {
    this.remote.afterSaveCallbacks.push(this.finishedCompiling.bind(this));
    this.remote.afterDiscardCallbacks.push(this.finishedCompiling.bind(this));
   }


  inputs;
  outputs;
  component;
  idRef;


  componentSwitch: { viewer: ViewModelTypeMapComponentEntry, editor: ViewModelTypeMapComponentEntry, tab:boolean  };
  componentRef: { viewer: Viewer<any>, editor: Editor<any> } = { viewer: null, editor: null };

  mode: 'viewer' | 'editor' | 'deleted' | 'queue' = 'viewer';

  viewData: ViewData = {
    title: '',
    lead: '',
    modelType: '',
    id: '', 
    state: '',
    valid: false,
    result: false
  };

  get viewValidations() {
    const validations = Object.keys(this.viewModel.validators)
      .map(validationId => this.viewModel.validators[validationId])
      .reduce(
        (aggregatedValidation, validation) => {
          aggregatedValidation.isValid = !aggregatedValidation.isValid ? false : validation.isValid;
          aggregatedValidation.messages = aggregatedValidation.messages.concat(validation.messages);
          return aggregatedValidation;
        }, { isValid: true, messages: [] }
      );
    return validations;
  }

  viewValidationTab(index : string){
    const validationTab = Object.keys(this.viewModel.validators)
    .map(validationId => {const obj = {validator: this.viewModel.validators[validationId], id: validationId}; return obj;} ).filter(elementType => { switch(index){
      case 'entry': if(elementType.id == 'NameKey' || elementType.id == 'ImageEntry' || elementType.id == 'PrizeTextDescriptionKey' || elementType.id == 'Prize' ) return elementType.validator; break;
      case 'waiting': if(elementType.id == 'Sponsor.Name' || elementType.id == 'Sponsor.Url' || elementType.id == 'ImageConsentSponsoredBy' || elementType.id == 'ImageBannerKey') return elementType.validator; ; break;
      case 'join': if(elementType.id == 'TermsKey' || elementType.id == 'ImageDefaultVideoKey' || elementType.id == 'IntroVideoUrl' || elementType.id == 'ImageLogoRanking') return elementType.validator; break;
      case 'date': if(elementType.id == 'FilterWithDate' || elementType.id == 'PublishDate') return elementType.validator; break;
      case 'questions': if(elementType.id == 'Questions') return elementType.validator; break;
    }
  })
  .reduce((aggregatedValidation, validation) => {
    aggregatedValidation.isValid = !aggregatedValidation.isValid ? false : validation.validator.isValid;
    aggregatedValidation.messages = aggregatedValidation.messages.concat(validation.validator.messages);
    return aggregatedValidation;
  }, { isValid: true, messages: [] });

  return validationTab;
  }

  ngOnInit() {
    this.setupComponent();
    this.message = "This entry will be deleted. Proceed ?";
    this.idRef = this.viewModel.id;
  }

  ngOnDestroy(){
    this.feed.RemoveRemoteEntriesData(this.viewModel);
  }

  setupComponent() {
    this.inputs = {
      viewModel: this.viewModel,
      viewerModel: this.viewModel,
      tabindex: 'entry'
    };
    this.outputs = {
      viewData: (data) => {
        this.viewData = data;
        this.InsertDataToIndex();
        this.cdRef.detectChanges();
      },
      changedViewModel: (viewModel) => {
        this.viewModel = viewModel;
        this.inputs.viewModel = viewModel;
        this.inputs.viewerModel = viewModel;
        this.componentRef.viewer.onViewerModelUpdate();
      }
    }
    
    this.componentSwitch = ViewModelTypeMap[this.viewModel.modelType];

    switch (this.viewModel.state) {
      case ViewModelState.CLEAN:
        this.component = this.componentSwitch.viewer;
        this.mode = 'viewer';
        break;
      case ViewModelState.NEW:
        this.component = this.componentSwitch.editor;
        this.mode = 'editor';
        break;
      case ViewModelState.EDITED:
        this.component = this.componentSwitch.editor;
        this.mode = 'editor';
        break;
      case ViewModelState.DELETED:
        this.component = null;
        this.mode = 'deleted';
        break;
      default:
        this.log.error(`viewModel in unknown state: ${this.viewModel.state}`);
    }
  }

  Changetab(index: string){
      this.inputs.tabindex = index;
  }

  Selected(index: string){
    return this.inputs.tabindex === index;
  }

  openModalDelete(template: TemplateRef<any>){ 
    if(this.viewModel.modelType == 'EventEntry'){
      
      let data = this.viewModel.localModel as EventEntry;
      let presentDay = new Date();
      
      if(new Date(presentDay.getTime() - new Date(data.Date).getTime()).getTime() / 1000 / 60 / 60 > 6 && data.CompilerStarted == false){
        this.message = "This entry still didn't send its results ! Are you sure you want to delete this event ? (You should send the results first)"
      }
    }
    this.modalRef = this.modal.show(template, {class:'modal-dialog-centered', ignoreBackdropClick: true });
  }

  onSave() {
    this.remote.saveTemplate(this.viewModel);
    this.log.info("Saving ...");
  }

  InsertDataToIndex(){
        console.log(this.viewModel.modelType);
        this.viewData.modelType = this.viewModel.modelType;
        this.viewData.id = this.viewModel.id;
        this.viewData.state = this.mode;
        this.viewData.valid = this.viewValidations.isValid;
        if(this.viewModel.modelType == "EventEntry"){
          let referenceViewer = this.componentRef.viewer as Viewer<EventEntry>;
          this.viewData.result = referenceViewer.model.CompilerStarted;
        }
        this.feed.InsertRemoteEntriesData(this.viewData);
  }

  onSaveDirectlyToRemote() {
    this.storage.saveToRemote(this.viewModel);
  }

  onDiscard() {
    this.discard.emit();
  }

  onDelete() {
    this.delete.emit();
  }

  onViewerCreated(viewer: ComponentRef<Viewer<any>>) {
    this.componentRef.viewer = viewer.instance;
  }

  onEditorCreated(editor: ComponentRef<Editor<any>>) {
    this.componentRef.editor = editor.instance;
  }

  switchToEditor() {
    this.component = this.componentSwitch.editor;
    console.log(this.component);
    this.mode = 'editor';
  }

  switchToViewer() {
    this.component = this.componentSwitch.viewer;
    console.log(this.component);
    this.mode = 'viewer';
  }

  debugResetContainer() {
    this.component = null;
  }

  finishedCompiling(savedViewModels: ViewModel<any>[]){
    let foundViewModel = savedViewModels.find(model => model.id === this.idRef); 
    this.viewModel = foundViewModel ? foundViewModel : this.viewModel;
    this.setupComponent(); 
    this.mode = "viewer";
  }

  isShowingToolsFor(mode: 'viewer' | 'editor'): boolean {
    return mode === this.mode;
  }

  checkConfirmation(validation){
    this.modalRef.hide();
    if(validation == true){
      this.onDelete();
    }
  }
}
