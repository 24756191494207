import { Component, Input, EventEmitter, Output } from '@angular/core';
import { League } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';


type LeagueViewModel = {label: string, key: string}
@Component({
  selector: 'select-league',
  moduleId: module.id,
  templateUrl: 'select.league.component.html',
  styleUrls: ['select.league.component.css']
})
export class SelectLeagueComponent {
  
  @Input() initialLeague: League = null
  @Output() leagueChanged = new EventEmitter<League>()

  remoteLeagueList: League[]
  leagueList: LeagueViewModel[]
  league: LeagueViewModel = { label: null, key: null }

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if (this.initialLeague) {
      this.league = { label: this.initialLeague.Name, key: this.initialLeague._id };
    }
    console.log("Initialized");
    this.updateLeagueListFromRemote();
  }

  updateLeagueListFromRemote() {
    this.remote.getLeagues().then( Leagues => {
      this.remoteLeagueList = Leagues.map( League => {
        return League;
      });
      this.leagueList = Leagues.map( League => ( {label: League.Name, key: League._id}) );
    } );
  }

  onLeagueChanged(LeagueKey) {
    this.league = this.leagueList.find( League => League.key === LeagueKey );
    const remoteLeague = this.remoteLeagueList.find( League => League._id === LeagueKey);
    this.leagueChanged.emit(remoteLeague);
  }


}
