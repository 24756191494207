import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'select-datetime',
  templateUrl: './select-datetime.component.html',
  styleUrls: ['./select-datetime.component.scss']
})
export class SelectDatetimeComponent implements OnInit {

  date: Date;

  dateFromRemote = false;

  @Input() identifier: string;
  @Input() initialDate ;
  @Input() minimumDate: Date ;
  @Input() maximumDate: Date ;
  @Output() dateChanged = new EventEmitter<Date>();

  get minimumTime() {
    return equalDate(this.date, this.minimumDate) ? this.minimumDate : null;
  }

  get maximumTime() {
    return equalDate(this.date, this.maximumDate) ? this.maximumDate : null;
  }

  constructor() {
  }

  ngOnInit() {
    if (this.initialDate) {
      this.date = new Date(this.initialDate);
    } else {
      this.date = new Date();
    }

    if(this.minimumDate){
      this.minimumDate = new Date(this.minimumDate);
    } else{
      console.log("The value for minimum date is empty");
    }

    if(this.maximumDate){
      this.maximumDate = new Date(this.maximumDate);
    } else{
      console.log("The value for maximum date is empty");
    }

    if (!equalOrGreaterDate(this.date, this.minimumDate)) {
      this.dateFromRemote = true;
    }

  }

  onDateChanged(date) {
    this.date = date;
    this.dateChanged.emit(this.date);
  }
  

  ngOnChanges(changes : SimpleChanges){
    console.log(changes.initialDate.currentValue);
    // this.onDateChanged(changes.initialDate.currentValue);
  }
  public raiseMinimum(date) {
    this.minimumDate = date;
    if (this.date.getTime() < this.minimumDate.getTime()) {
      this.date = new Date(this.minimumDate);
      this.dateChanged.emit(this.date);
    }
  }

  public lowerMaximum(date) {
    this.maximumDate = date;
    if (this.date.getTime() > this.maximumDate.getTime()) {
      this.date = new Date(this.maximumDate);
      this.dateChanged.emit(this.date);
    }
  }
}

function equalDate(date, minimumDate) {
  const inputDateNoHours = new Date(date);
  const minimumDateNoHours = new Date(minimumDate);

  inputDateNoHours.setHours(0, 0, 0, 0);
  minimumDateNoHours.setHours(0, 0, 0, 0);

  if (inputDateNoHours.getTime() === minimumDateNoHours.getTime()) {
    return true;
  } else {
    return false;
  }
}

function equalOrGreaterDate(date, minimumDate) {
  const inputDateNoHours = new Date(date);
  const minimumDateNoHours = new Date(minimumDate);

  inputDateNoHours.setHours(0, 0, 0, 0);
  minimumDateNoHours.setHours(0, 0, 0, 0);

  if (inputDateNoHours.getTime() >= minimumDateNoHours.getTime()) {
    return true;
  } else {
    return false;
  }
}

function equalOrLesserDate(date, maximumDate) {
  const inputDateNoHours = new Date(date);
  const maximumDateNoHours = new Date(maximumDate);

  inputDateNoHours.setHours(0, 0, 0, 0);
  maximumDateNoHours.setHours(0, 0, 0, 0);

  if (inputDateNoHours.getTime() <= maximumDateNoHours.getTime()) {
    return true;
  } else {
    return false;
  }
}