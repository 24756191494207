import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDatetimeComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-datetime/select-datetime.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SelectMediaComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-media/select-media.component';
import { ModalComponent } from 'src/Modal/Modal.component';
import { SelectStickersComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-stickers/select-stickers.component';
import { SelectRewardTypeComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-rewardType/select-rewardType.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SelectTournamentComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-tournament/select-tournament.component';
import { ViewModelControllerComponent } from 'src/shell/feed/viewmodel-controller/viewmodel-controller.component';
import { DynamicModule } from 'ng-dynamic-component';
import { GenericviewComponent } from 'src/shell/feed/genericview/genericview.component';
import { ViewerEventEntryComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-event-entry/viewer-event-entry.component';
import { ViewerHighlightEntryComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-highlight-entry/viewer-highlight-entry.component';
import { ViewerShakeEntryComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-shake-entry/viewer-shake-entry.component';
import { ViewerQuestionEntryComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-question-entry/viewer-question-entry.component';
import { ViewerQuizEntryComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-quiz-entry/viewer-quiz-entry.component';
import { ViewerCampaignEntryComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-campaign-entry/viewer-campaign-entry.component';
import { EditorEventEntryComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-event-entry/editor-event-entry.component';
import { EditorHighlightEntryComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-highlight-entry/editor-highlight-entry.component';
import { EditorQuestionEntryComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-question-entry/editor-question-entry.component';
import { EditorShakeEntryComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-shake-entry/editor-shake-entry.component';
import { EditorQuizEntryComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-quiz-entry/editor-quiz-entry.component';
import { EditorCampaignEntryComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-campaign-entry/editor-campaign-entry.component';
import { EditorSportComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-sport/editor-sport.component';
import { EditorCompetitorComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-competitor/editor-competitor.component';
import { EditorTournamentComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-tournament/editor-tournament.component';
import { EditorGoalComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-goal/editor-goal.component';
import { EditorFilterComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-filter/editor-filter.component';
import { EditorContentByCodeComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-content-by-code/editor-content-by-code.component';
import { EditorEnvelopComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-envelop/editor-envelop.component';
import { EditorResultComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-result/editor-result.component';
import { EditorCouponComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-coupon/editor-coupon.component';
import { EditorQuestionComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-question/editor-question.component';
import { EditorLevelComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-level/editor-level.component';
import { EditorTranslationComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-translation/editor-translation.component';
import { EditorMediaComponent } from 'src/shell/feed/viewmodel-controller/editors/editor-media/editor-media.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { EventResultComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-event-entry/event-result/event-result.component';
import { SimplewinnerComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-event-entry/event-result/components/simplewinner/simplewinner.component';
import { IntegerwinnerComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-event-entry/event-result/components/integerwinner/integerwinner.component';
import { OptiongoalComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-event-entry/event-result/components/optiongoal/optiongoal.component';
import { PodiumwinnerComponent } from 'src/shell/feed/viewmodel-controller/viewers/viewer-event-entry/event-result/components/podiumwinner/podiumwinner.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ToastrModule } from 'ngx-toastr';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FieldTranslationComponent } from 'src/shell/feed/viewmodel-controller/selectors/field-translation/field-translation.component';
import { SelectGoalsComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-goals/select-goals.component';
import { NewItemQueueComponent } from 'src/shell/new-item-queue/new-item-queue.component';
import { NewItemEditorComponent } from 'src/shell/new-item-editor/new-item-editor.component';
import { FieldNotificationComponent } from 'src/shell/feed/viewmodel-controller/selectors/field-notification/field-notification.component';
import { SelectCampaignComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-campaign/select-campaign.component';
import { SelectCompetitorComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-competitor/select-competitor.component';
import { SaveQueueComponent } from 'src/shell/save-queue/save-queue.component';
import { SelectQuestionComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-question/select-question.component';
import { SelectFilterComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-filter/select-filter.component';
import { FieldFilterWithDatetimeComponent } from 'src/shell/feed/viewmodel-controller/selectors/field-filter-with-datetime/field-filter-with-datetime.component';
import { SelectSportComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-sport/select-sport.component';
import { ViewTemplateControllerComponent } from 'src/development/template/view-template-controller/view.template.controller.component';
import { SelectTemplateComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-template/select.template.component';
import { InsertTemplateComponent } from 'src/shell/feed/viewmodel-controller/editors/insert-template/insert.template.component';
import { SelectQuestionLevelComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-question-level/select.question.level.component';
import { SelectGoalTypeComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-goal-type/select.goal.type.component';
import { SelectSingleGoalComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-single-goal/select.single.goal.component';
import { SelectEnvelopeTypeComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-envelope-type/select.envelope.type.component';
import { FiledTranslationOldComponent } from 'src/shell/feed/viewmodel-controller/selectors/filed-translation-old/filed.translation.old.component';
import { SelectLeagueComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-league/select.league.component';
import { SelectContentCodeComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-content-code/select.content.code.component';
import { SelectPrizeSetComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-prize-set/select.prize.set.component';
import { SelectEnvelopeComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-envelope/select.envelope.component';
import { SelectClientComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-client/select-client.component';

@NgModule({
  imports: [
    CommonModule, 
    FormsModule,
    AlertModule,
    BsDatepickerModule,
    TimepickerModule,
    ModalModule,
    ToastrModule,
    TimepickerModule,
    PopoverModule,
    TooltipModule,
    SortableModule,
    ButtonsModule,
    BsDropdownModule,
    AccordionModule,
    CollapseModule,
    PaginationModule,
    ReactiveFormsModule,
    DynamicModule.withComponents([
      GenericviewComponent,
      ViewerEventEntryComponent,
      ViewerHighlightEntryComponent,
      ViewerShakeEntryComponent,
      ViewerQuestionEntryComponent,
      ViewerQuizEntryComponent,
      ViewerCampaignEntryComponent,
      EditorEventEntryComponent,
      EditorHighlightEntryComponent,
      EditorShakeEntryComponent,
      EditorQuestionEntryComponent,
      EditorQuizEntryComponent,
      EditorCampaignEntryComponent,
      EditorSportComponent,
      EditorTournamentComponent,
      EditorCompetitorComponent,
      EditorGoalComponent,
      EditorFilterComponent,
      EditorContentByCodeComponent,
      EditorResultComponent,
      EditorEnvelopComponent,
      EditorCouponComponent,
      EditorQuestionComponent,
      EditorLevelComponent,
      EditorTranslationComponent,
      EditorMediaComponent,
    ]),
  ],
  declarations: [SelectDatetimeComponent, SelectMediaComponent, SelectTournamentComponent, ModalComponent, SelectStickersComponent, SelectRewardTypeComponent, ViewModelControllerComponent,GenericviewComponent, ViewerEventEntryComponent,ViewerHighlightEntryComponent,
    ViewerShakeEntryComponent,
    ViewerQuestionEntryComponent,
    ViewerQuizEntryComponent,
    ViewerCampaignEntryComponent,
    EditorEventEntryComponent,
    EditorHighlightEntryComponent,
    EditorQuestionEntryComponent,
    EditorQuizEntryComponent,
    EditorShakeEntryComponent,
    EditorCampaignEntryComponent,
    EditorSportComponent,
    EditorTournamentComponent,
    EditorCompetitorComponent,
    EditorGoalComponent,
    EditorFilterComponent,
    EditorContentByCodeComponent,
    EditorResultComponent,
    EditorEnvelopComponent,
    EditorCouponComponent,
    EditorQuestionComponent,
    EditorLevelComponent,
    EditorTranslationComponent,
    EditorMediaComponent,
    EventResultComponent,
    SimplewinnerComponent,
    IntegerwinnerComponent,
    OptiongoalComponent,
    PodiumwinnerComponent,
    FieldTranslationComponent,
    SelectGoalsComponent,
    SelectFilterComponent,
    SelectQuestionComponent,
    FieldTranslationComponent,
    SaveQueueComponent,
    SelectCompetitorComponent,
    SelectCampaignComponent,
    FieldNotificationComponent,
    NewItemEditorComponent,
    NewItemQueueComponent,
    FieldFilterWithDatetimeComponent,
    SelectSportComponent,
    ViewTemplateControllerComponent,
    SelectTemplateComponent,
    InsertTemplateComponent,
    SelectQuestionLevelComponent,
    SelectGoalTypeComponent,
    SelectSingleGoalComponent,
    SelectEnvelopeTypeComponent,
    FiledTranslationOldComponent,
    SelectLeagueComponent,
    SelectContentCodeComponent,
    SelectPrizeSetComponent,
    SelectEnvelopeComponent,
    SelectClientComponent],
  entryComponents: [ModalComponent, SelectDatetimeComponent, SelectTournamentComponent, SelectMediaComponent, ModalComponent, SelectStickersComponent, SelectRewardTypeComponent, ViewModelControllerComponent, GenericviewComponent, ViewerEventEntryComponent, ViewerHighlightEntryComponent,
    ViewerShakeEntryComponent,
    ViewerQuestionEntryComponent,
    ViewerQuizEntryComponent,
    ViewerCampaignEntryComponent,
    EditorEventEntryComponent,
    EditorHighlightEntryComponent,
    EditorQuestionEntryComponent,
    EditorQuizEntryComponent,
    EditorShakeEntryComponent,
    EditorCampaignEntryComponent,
    EditorSportComponent,
    EditorTournamentComponent,
    EditorCompetitorComponent,
    EditorGoalComponent,
    EditorFilterComponent,
    EditorContentByCodeComponent,
    EditorResultComponent,
    EditorEnvelopComponent,
    EditorCouponComponent,
    EditorQuestionComponent,
    EditorLevelComponent,
    EditorTranslationComponent,
    EditorMediaComponent,
    EventResultComponent,
    SimplewinnerComponent,
    IntegerwinnerComponent,
    OptiongoalComponent,
    PodiumwinnerComponent,
    FieldTranslationComponent,
    SelectGoalsComponent,
    SelectFilterComponent,
    SelectQuestionComponent,
    FieldTranslationComponent,
    SaveQueueComponent,
    SelectCompetitorComponent,
    SelectCampaignComponent,
    FieldNotificationComponent,
    NewItemEditorComponent,
    NewItemQueueComponent,
    FieldFilterWithDatetimeComponent,
    SelectSportComponent,
    ViewTemplateControllerComponent,
    SelectTemplateComponent,
    InsertTemplateComponent,
    SelectQuestionLevelComponent,
    SelectGoalTypeComponent,
    SelectSingleGoalComponent,
    SelectEnvelopeTypeComponent,
    FiledTranslationOldComponent,
    SelectLeagueComponent,
    SelectContentCodeComponent,
    SelectPrizeSetComponent,
    SelectEnvelopeComponent,
    SelectClientComponent],
  exports:[CommonModule , FormsModule, SelectDatetimeComponent, SelectMediaComponent, ModalComponent, FormsModule, SelectStickersComponent, SelectRewardTypeComponent, AlertModule, SelectTournamentComponent, ViewModelControllerComponent, GenericviewComponent, ViewerEventEntryComponent, ViewerHighlightEntryComponent,
    ViewerShakeEntryComponent,
    ViewerQuestionEntryComponent,
    ViewerQuizEntryComponent,
    ViewerCampaignEntryComponent,
    EditorEventEntryComponent,
    EditorHighlightEntryComponent,
    EditorQuestionEntryComponent,
    EditorQuizEntryComponent,
    EditorShakeEntryComponent,
    EditorCampaignEntryComponent,
    EditorSportComponent,
    EditorTournamentComponent,
    EditorCompetitorComponent,
    EditorGoalComponent,
    EditorFilterComponent,
    EditorContentByCodeComponent,
    EditorResultComponent,
    EditorEnvelopComponent,
    EditorCouponComponent,
    EditorQuestionComponent,
    EditorLevelComponent,
    EditorTranslationComponent,
    EditorMediaComponent,
    EventResultComponent,
    SimplewinnerComponent,
    IntegerwinnerComponent,
    OptiongoalComponent,
    PodiumwinnerComponent,
    FieldTranslationComponent,
    SelectGoalsComponent,
    SelectFilterComponent,
    SelectQuestionComponent,
    FieldTranslationComponent,
    SaveQueueComponent,
    SelectCompetitorComponent,
    SelectCampaignComponent,
    FieldNotificationComponent,
    NewItemEditorComponent,
    NewItemQueueComponent,
    FieldFilterWithDatetimeComponent,
    SelectSportComponent,
    ViewTemplateControllerComponent,
    SelectTemplateComponent,
    InsertTemplateComponent,
    SelectQuestionLevelComponent,
    SelectGoalTypeComponent,
    SelectSingleGoalComponent,
    SelectEnvelopeTypeComponent,
    FiledTranslationOldComponent,
    SelectLeagueComponent,
    SelectContentCodeComponent,
    SelectPrizeSetComponent,
    SelectEnvelopeComponent,
    SelectClientComponent]
})
export class SharedModule { }
