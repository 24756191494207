import { Component, OnInit, Input, Output , EventEmitter} from '@angular/core';
import { OptionGoal } from "@gsegames/arena.models";
import { Goal } from '@gsegames/arena.models';

@Component({
  selector: 'option-goal',
  templateUrl: './optiongoal.component.html',
  styleUrls: ['./optiongoal.component.css']
})
export class OptiongoalComponent implements OnInit {

  @Input() step : number;
  @Input() goal : OptionGoal;
  @Output() answer = new EventEmitter();

  real: boolean;
  expected: boolean;

  constructor() { this.goal = new OptionGoal();  }

  ngOnInit() {
    this.real = this.step == 0 ? true : false;
    this.expected = this.step == 1 ? true : false;

    console.log(this.goal.GoalType);
  }

  onChange(optionValue){
    this.answer.emit(optionValue);
  }

}
