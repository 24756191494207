import { Component, OnInit } from '@angular/core';
import { Filter } from '@gsegames/arena.models';
import { Editor } from '../editor';

@Component({
  selector: 'app-editor-filter',
  templateUrl: './editor-filter.component.html',
  styleUrls: ['./editor-filter.component.scss']
})
export class EditorFilterComponent extends Editor<Filter> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
