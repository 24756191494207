import { OnInit, Input, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { ViewModel } from 'src/models/ViewModel';
import { ViewData } from 'src/services/FeedInfo.service';

export class Viewer<T> implements OnInit {
  @Input() viewerModel: ViewModel<T>;
  model: T;

  @Output() viewData = new EventEmitter<ViewData>();

  ngOnInit() {
    this.model = this.viewerModel.localModel;
  }

  onViewerModelUpdate() {
    console.log('viewer updated');
  }
}