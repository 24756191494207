import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { StickerGroup, ResultNotification } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';

interface StickerGroupViewModel { label: string; key: string; numberShakes: number;}

@Component({
  selector: 'select-stickers',
  templateUrl: './select-stickers.component.html',
  styleUrls: ['./select-stickers.component.css']
})
export class SelectStickersComponent implements OnInit {

  @Input() initialStickerGroup;
  @Output() stickerGroupChanged = new EventEmitter<any>();

  remoteStickerList: StickerGroup[]
  stickerGroupList: StickerGroupViewModel[]
  stickerGroup: StickerGroupViewModel = { label: '', key: null, numberShakes: null }

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    console.log(this.initialStickerGroup);
    if (this.initialStickerGroup) {
       this.remote.getStickerGroupId(this.initialStickerGroup).then( stickerGroup => {
        this.remote.getNumberofShakes(this.initialStickerGroup).then(result => { 
          this.stickerGroup = { label: stickerGroup.Name.Translations['en'], key: stickerGroup._id, numberShakes: result.availableCount }; 
        });
      });
    }
    this.updateStickerListFromRemote();
  }

 async updateStickerListFromRemote() {
    const stickerGroups = await this.remote.getStickerGroups(); 
    this.remoteStickerList = stickerGroups;
    
    const responses = await Promise.all(stickerGroups.map(stickerGroup => this.remote.getNumberofShakes(stickerGroup._id)));
    this.stickerGroupList = stickerGroups
      .map((stickerGroup, index) => ({ label: stickerGroup.Name.Translations['en'], key: stickerGroup._id , numberShakes: responses[index].availableCount }))
      .filter((stickerGroup) => stickerGroup.numberShakes > 0); 
  }

  onStickerChanged(stickerGroupKey) {
    this.stickerGroup = this.stickerGroupList.find(stickerGroup => stickerGroup.key == stickerGroupKey);
    let stickerGroup = this.remoteStickerList.find(remoteSticker => remoteSticker._id == this.stickerGroup.key)
    this.stickerGroupChanged.emit(stickerGroup._id);
  }

}
