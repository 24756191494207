import { Component, OnInit } from '@angular/core';
import { Editor } from '../editor';
import { QuizQuestionLevel } from '@gsegames/arena.models';
import { injectTranslation } from 'src/core/translations';

@Component({
  selector: 'app-editor-level',
  templateUrl: './editor-level.component.html',
  styleUrls: ['./editor-level.component.scss']
})
export class EditorLevelComponent extends Editor<QuizQuestionLevel> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onNameChanged(nameTranslation) {
    this.model.NameKey = nameTranslation.key;
    injectTranslation(nameTranslation, this.viewModel);
  }
}
