import * as log from 'src/core/log';

export const environment = {
  remote: {
    apiBaseURL: "https://api-admin.arenagse.com",
    version: "B.1.3",
    tag: "live"
  },

  log: {
    levels: log.LogLevel.ALL,
    output: log.LogOutput.ALL
  },

  production: false
};
