import { ViewModel, ViewModelType } from './ViewModel';
import * as _ from 'lodash';

export const NO_TYPE_SELECTED = '- undefined view model type -';

export class NewItem {
    viewModel: ViewModel<any>;
    id: string;

    type: string;

    isTypeChangeAllowed = false;

    constructor(viewModelType?: ViewModelType, public requester?: { requester, setViewModelCallback, discardCallback }) {
        this.id = _.uniqueId();

        if (this.requester) {
            this.isTypeChangeAllowed = false;
        } else {
            this.isTypeChangeAllowed = true;
        }

        this.type = viewModelType ? viewModelType : NO_TYPE_SELECTED;
    }

    setupViewModel(viewModel: ViewModel<any>) {
        this.viewModel = viewModel;
        if (this.requester) {
            this.viewModel.requesterId = this.requester.requester;
            this.requester.setViewModelCallback();
        }
    }
}
