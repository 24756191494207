import { Injectable } from '@angular/core';
import { ViewModelType, ViewModel } from 'src/models/ViewModel';
import { NewItem } from 'src/models/NewItem';

type NewItemListener = (viewModelType: ViewModelType, requester: any, discardCallback) => Promise<NewItem>;
type SaveInlineItemListener = (requester: string) => void;

@Injectable({
  providedIn: 'root'
})
export class InliningService {

  newItemListener: NewItemListener;
  saveInlineItemsListener: SaveInlineItemListener;

  constructor() { }

  registerNewItemListener(listener: NewItemListener) {
    this.newItemListener = listener;
  }

  registerSaveInlineItemsListener(listener: SaveInlineItemListener) {
    this.saveInlineItemsListener = listener;
  }

  requestNewItem(viewModelType: ViewModelType, requester, discardCallback): Promise<NewItem> {
    const newItemPromise = this.newItemListener(viewModelType, requester, discardCallback);
    return newItemPromise;
  }

  saveRequestedInlineItems(requesterId: string) {
    this.saveInlineItemsListener(requesterId);
  }

  discardInlinedItem() {

  }
}
