import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewModelType } from 'src/models/ViewModel';
import { RemoteService } from 'src/services/RemoteService';
import { NewItemQueueComponent } from 'src/shell/new-item-queue/new-item-queue.component';
import { Router } from '@angular/router';

@Component({
  selector: 'NavBar',
  templateUrl: './NavBar.component.html',
  styleUrls: ['./NavBar.component.css']
})
export class NavBarComponent implements OnInit {
  
  availableViewModelTypes = [
    ViewModelType.EventEntry,
    ViewModelType.ShakeEntry,
    ViewModelType.HighlightEntry,
    ViewModelType.QuestionEntry,
    ViewModelType.QuizEntry,
  ];

  constructor(private remote: RemoteService, private router: Router) { }

  ngOnInit() {
    console.log(this.router.url);
  }

  urlContains(){
    return this.router.url.includes('/dev');
  }
}
