import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Viewer } from '../../viewer';
import { RemoteService } from 'src/services/RemoteService';
import { EventEntry, GoalType, IntegerWinnerAnswer, SimpleWinnerAnswer, OptionAnswer, PodiumAnswer, Answer, AnswerType } from '@gsegames/arena.models';
import { RemoteAPIService } from 'src/services/RemoteAPIService';
import * as _ from 'lodash';
import { FeedManagerService } from 'src/services/FeedManager.service';

@Component({
  selector: 'event-result',
  templateUrl: './event-result.component.html',
  styleUrls: ['./event-result.component.css']
})
export class EventResultComponent implements OnInit {
  @Input() event : Viewer<EventEntry>;
  @Output() resultInfo = new EventEmitter();

  savingResult : boolean = false;
  disable = false;
  step : number;
  drawReal: boolean = false;
  drawExpected: boolean = false;
  expectedAnswers : Answer[];
  goalType = GoalType;
  answerType = AnswerType;
  results;

  constructor(private remoteAPI : RemoteAPIService, private remoteService: RemoteService, private feedManager: FeedManagerService) {  }

  ngOnInit() {
    this.step = 0;
    this.createAnswersIfNotExists();
    this.expectedAnswers = _.cloneDeep(this.event.model.RealAnswers);
    this.results = this.event.model.RealAnswers.map( answer => { let value;
       switch(answer.AnswerType){
        case this.answerType.IntegerWinner:
        value = answer as IntegerWinnerAnswer;
        return { answer: value , type: value.AnswerType }; break;
        case this.answerType.Option:
        value = answer as OptionAnswer;
        return { answer: value, type: value.AnswerType }; break;
        case this.answerType.SimpleWinner:
        value = answer as SimpleWinnerAnswer;
        return { answer: value, type: value.AnswerType }; break;
        case this.answerType.Podium:
        value = answer as PodiumAnswer;
        return { answer: value, type: value.AnswerType }; break;
        }
      });
    }

  getCompetitorById(competitorId){
    return this.event.model.Competitors && this.event.model.Competitors.find(element => element._id == competitorId)
      ? this.event.model.Competitors.find(element => element._id == competitorId)
      : {
        Name: 'not founding it',
        IconUrl: '',
      };
  }

  private createAnswersIfNotExists() {
    
    // Cria as respostas vazias para biding do ngmodel
        if (!this.event.model.RealAnswers) {
            this.event.model.RealAnswers = [];
            this.event.model.Goals.forEach(g => {
                switch (g.GoalType) {
                    case GoalType.IntegerWinner:
                        let integerAnswer = new IntegerWinnerAnswer();
                        integerAnswer.LeftContenderId = "";
                        integerAnswer.LeftContenderScore = null;
                        integerAnswer.RightContenderId = "";
                        integerAnswer.RightContenderScore = null;
                        integerAnswer._id = g._id;
                        this.event.model.RealAnswers.push(integerAnswer);
                        break;
                    case GoalType.SimpleWinner:
                        let simpleAnswer = new SimpleWinnerAnswer();
                        simpleAnswer._id = g._id;
                        simpleAnswer.WinnerId = "";
                        this.event.model.RealAnswers.push(simpleAnswer);
                        break;
                    case GoalType.Option:
                        let optionAnswer = new OptionAnswer();
                        optionAnswer._id = g._id;
                        optionAnswer.ValueKey = "";
                        this.event.model.RealAnswers.push(optionAnswer);
                        break;
                    case GoalType.Podium:
                        let podiumAnswer = new PodiumAnswer();
                        podiumAnswer._id = g._id;
                        podiumAnswer.WinnersIds = ['', '', ''];
                        this.event.model.RealAnswers.push(podiumAnswer);
                        break;
                    default:
                        console.warn(`GoalType.${GoalType[g.GoalType]} is not being handled`);
                } 
            });
            console.log(this.event.model.RealAnswers.length);
        }
        else{
          return;
        }
}

updateEventAnswers(index, type: GoalType, content){
  console.log("Changing RealAnswers");
  switch(type){
    case this.goalType.IntegerWinner :
        let integerWinnerAnswer = this.event.model.RealAnswers[index] as IntegerWinnerAnswer;
        integerWinnerAnswer.LeftContenderId = content.competitorsId[0]._id;
        integerWinnerAnswer.LeftContenderScore = content.scores[0];
        integerWinnerAnswer.RightContenderId = content.competitorsId[1]._id;
        integerWinnerAnswer.RightContenderScore = content.scores[1];
        this.event.model.RealAnswers[index] = integerWinnerAnswer; 
      break;
    case this.goalType.SimpleWinner :
      let simpleWinnerAnswer = this.event.model.RealAnswers[index] as SimpleWinnerAnswer;
      simpleWinnerAnswer.WinnerId = content;
      this.event.model.RealAnswers[index] = simpleWinnerAnswer;
      console.log(content);
      if(content == '__draw__no_winner__'){
        this.drawReal = true;
      }
      else{
        this.drawReal = false;
      }
    break;
    case this.goalType.Option :
      let optionAnswer = this.event.model.RealAnswers[index] as OptionAnswer;
      optionAnswer.ValueKey = content;
      this.event.model.RealAnswers[index] = optionAnswer;
    break;
    case this.goalType.Podium : 
      let podiumAnswer = this.event.model.RealAnswers[index] as PodiumAnswer;
      podiumAnswer.WinnersIds = content;
      this.event.model.RealAnswers[index] = podiumAnswer;
    break;
    default: console.log("The goaltype you're trying to reach doesnt exist"); break;

  }
}

updateExpectedAnswers(index, type: GoalType, content){
  console.log("Changing ExpectedAnswers");
  switch(type){
    case this.goalType.IntegerWinner :
        let integerWinnerAnswer = this.expectedAnswers[index] as IntegerWinnerAnswer;
        integerWinnerAnswer.LeftContenderId = content.competitorsId[0]._id;
        integerWinnerAnswer.LeftContenderScore = content.scores[0];
        integerWinnerAnswer.RightContenderId = content.competitorsId[1]._id;
        integerWinnerAnswer.RightContenderScore = content.scores[1];
        this.expectedAnswers[index] = integerWinnerAnswer;  
      break;
    case this.goalType.SimpleWinner :
      let simpleWinnerAnswer = this.expectedAnswers[index] as SimpleWinnerAnswer;
      simpleWinnerAnswer.WinnerId = content;
      this.expectedAnswers[index] = simpleWinnerAnswer;
      console.log(content);
      if(content == '__draw__no_winner__'){
        this.drawExpected = true;
      }
      else{
        this.drawExpected = false;
      }
    break;
    case this.goalType.Option :
      let optionAnswer = this.expectedAnswers[index] as OptionAnswer;
      optionAnswer.ValueKey = content;
      this.expectedAnswers[index] = optionAnswer;
    break;
    case this.goalType.Podium : 
      let podiumAnswer = this.expectedAnswers[index] as PodiumAnswer;
      podiumAnswer.WinnersIds = content;
      this.expectedAnswers[index] = podiumAnswer;
    break;
    default: console.log("The goaltype you're trying to reach doesnt exist for confirmation"); break;
  }
}

verifyConfirmation() : boolean {
  return  this.savingResult == true || !_.isEqual(this.event.model.RealAnswers, this.expectedAnswers) ;
}

sendAnswers(){
  this.savingResult = true;
  this.remoteAPI.post_calculatescore(this.event.model._id, this.event.model.RealAnswers)
  .subscribe(() => {
    this.remoteAPI.post_startCompiler(this.event.model._id).subscribe((value) => { this.event.model.RealAnswers = null; if(value == true){ this.event.model.CompilerStarted = true; this.resultInfo.emit(true); this.feedManager.updateFeed(); } else this.event.model.CompilerStarted = false;});
  }, (error) => {
    console.log(error);
    this.resultInfo.emit(false);
    this.savingResult = false;
  });
}

nextStep(){
    if(this.step == 0){
      this.step = +1;
    }
    else{
      this.step = 0;
    }
  }
}
