import { Injectable } from '@angular/core';
import { ViewModel } from 'src/models/ViewModel';


export interface ViewData {
  title?: string;
  lead?: string;
  modelType?: string;
  id?: string;
  state?: string;
  valid?: boolean;
  result?:boolean;
  date?: Date;
}

@Injectable({
  providedIn: 'root'
})

export class FeedInfoService {
  viewData: ViewData[] = [];
  index: number;
  callbacks: ((viewDataList?: ViewData[]) => void)[] = [];

  constructor() { }

  InsertRemoteEntriesData(data: ViewData) {
    let dataFound = this.viewData.find(dataArray => dataArray.id == data.id)
    if(dataFound)
      this.viewData[this.viewData.indexOf(dataFound)] = data;
    else
      this.viewData.push(data);

    this.reorganizeFeedIndex();

    this.callbacks.forEach((callback) => callback(this.viewData));
  }

  RemoveRemoteEntriesData(data: ViewModel<any>){
    let dataFound = this.viewData.find(dataArray => dataArray.id == data.id);
    if(dataFound)
      this.viewData.splice(this.viewData.indexOf(dataFound), 1);
    else
      console.log("Can't find the respective entry on index");

    this.reorganizeFeedIndex();
  }

  RemoveFromQueueEntriesData(data: ViewModel<any>){
    let dataFound = this.viewData.find(dataArray => dataArray.id == data.id);
    if(dataFound)
      this.viewData[this.viewData.indexOf(dataFound)].state = 'viewer';
    else
      console.log("Can't find the respective entry on index to change the status");
      
      this.reorganizeFeedIndex();
  }

  reorganizeFeedIndex(){
    this.viewData.sort((element1 , element2) => {
      let date1 = new Date(element1.date);
      let date2 = new Date(element2.date);
      
      return date1.getTime() - date2.getTime();
    })
  }

  ResetRemoteEntriesData() {
    this.viewData = [];
    this.callbacks.forEach((callback) => callback(this.viewData));
  }
}
