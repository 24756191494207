import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'genericview',
  templateUrl: './genericview.component.html',
  styleUrls: ['./genericview.component.scss']
})
export class GenericviewComponent implements OnInit {

  @Input() viewModel: any;
  @Input() title: string;

  constructor() { }

  ngOnInit() { }

  extractData(viewModel: any): {key: string, value: any}[] {
    return _.toPairs(viewModel).map( pair => ({'key': pair[0], 'value': pair[1] }) )
  }

  isObject(value: any): boolean {
    return _.isObject(value);
  }
}
