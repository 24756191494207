import { Injectable } from '@angular/core';
import { ViewModelType, ViewModel } from 'src/models/ViewModel'
import * as _ from 'lodash';
import { RemoteFeedPayload, RemoteFeedMode } from 'src/core/remote-feed';

@Injectable({
    providedIn: 'root'
  })

export class FilterService {

    remoteFeedPayload: RemoteFeedPayload = { mode: RemoteFeedMode.AllEntries };
    remoteFeedStartDate: Date = new Date();
    remoteFeedEndDate: Date = new Date();
    remoteFeedPageNumber: number = 1;
    remoteFeedLimitNumber: number = 10; 
    
    changeRemoteFeedMode(mode: RemoteFeedPayload) {
        this.remoteFeedPayload = mode;
    }

    changeRemoteStartDate(StartDate : Date) {
        this.remoteFeedStartDate = StartDate;
    }

    changeRemoteEndDate(EndDate: Date) {
        this.remoteFeedEndDate = EndDate;
    }

    changeRemotePageNumber(page: number) {
        this.remoteFeedPageNumber = page;
    }

    changeRemoteLimitNumber(limit: number) {
        this.remoteFeedLimitNumber = limit;
    }
}
