import { Component, OnInit, Input, ViewChild, ContentChild, AfterViewInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { createEmptyViewModel, createViewModelFromTemplate } from 'src/core/viewmodel-creator';
import { ViewModelControllerComponent } from '../feed/viewmodel-controller/viewmodel-controller.component';
import { ViewModel } from 'src/models/ViewModel';
import { NewItem, NO_TYPE_SELECTED } from 'src/models/NewItem';
import { FeedInfoService } from 'src/services/FeedInfo.service';

@Component({
  selector: 'new-item-editor',
  templateUrl: './new-item-editor.component.html',
  styleUrls: ['./new-item-editor.component.scss']
})
export class NewItemEditorComponent implements OnInit, AfterViewInit {


  @Input() item: NewItem;
  @Input() type: string;
  @Input() itemTypes: string[] = [];
  @Output() discard = new EventEmitter<NewItem>();
  @Output() save = new EventEmitter<NewItem>();

  typeChangeAllowed = true;

  viewModelController: ViewModelControllerComponent;

  @ViewChild(ViewModelControllerComponent) set viewModelControllerComponent(controller) {
    this.viewModelController = controller;
  }

  constructor(private feed : FeedInfoService) {
  }

  ngOnInit() {
    this.typeChangeAllowed = this.item.isTypeChangeAllowed;

    if (this.item.type !== NO_TYPE_SELECTED) {
      this.onItemTypeChanged(this.item, this.item.type);
    }
  }

  ngAfterViewInit() {
  }

  onItemTypeChanged(item: NewItem, itemType) {
    item.type = itemType;
    if(!item.viewModel){
      item.setupViewModel(createEmptyViewModel( item.type ));
    }
    if (this.viewModelController) {
      this.viewModelController.viewModel = item.viewModel;
      this.viewModelController.setupComponent();
    }
  }

  onItemDiscarded() {
    this.discard.emit(this.item);
    this.feed.RemoveRemoteEntriesData(this.item.viewModel);
    if (this.item.requester) {
      this.item.requester.discardCallback();
    }
  }
}
