import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'field-notification',
  templateUrl: './field-notification.component.html',
  styleUrls: ['./field-notification.component.scss']
})
export class FieldNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
