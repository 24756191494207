import { Component, OnInit } from '@angular/core';
import { Competitor } from '@gsegames/arena.models';
import { Editor } from '../editor';

@Component({
  selector: 'app-editor-competitor',
  templateUrl: './editor-competitor.component.html',
  styleUrls: ['./editor-competitor.component.scss']
})
export class EditorCompetitorComponent extends Editor<Competitor> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onTeamChanged(team) {
    this.model.Team = team;
  }

  onSportChanged(sport) {
    this.model.Sport = sport;
  }
}
