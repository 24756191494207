import { Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, Input } from '@angular/core';
import { Filter } from '@gsegames/arena.models';
import { SelectDatetimeComponent } from '../select-datetime/select-datetime.component';

export class FilterWithDateTime {
  filter: Filter;
  start: Date;
  end: Date;
  closed?: Date;
}

@Component({
  selector: 'field-filter-with-datetime',
  templateUrl: './field-filter-with-datetime.component.html',
  styleUrls: ['./field-filter-with-datetime.component.scss']
})
export class FieldFilterWithDatetimeComponent implements OnInit {

  @Input() initialPackage: FilterWithDateTime[] = [];
  @Input() hasEndDate = true;
  @Output() packageChanged = new EventEmitter<FilterWithDateTime[]>();

  filterPackages: FilterWithDateTime[] = [];

  startDate = new Date();
  endDate = new Date();

  message: string;
  messageShowStartDate: boolean = false;
  messageShowEndDate: boolean = false;

  maxPackages = Infinity;

  @ViewChildren(SelectDatetimeComponent) childrenSelectDate: QueryList<SelectDatetimeComponent>;

  constructor() { }

  ngOnInit() {
    if (this.initialPackage) {
      this.filterPackages = this.initialPackage;

    } else {
      //this.endDate.setDate(this.startDate.getDate() + 1);
      this.addFilterPackage();
    }
  }

  addFilterPackage() {
    this.filterPackages.push({
      filter: new Filter(),
      start: this.startDate,
      end: this.hasEndDate ? this.endDate : null
    });
    this.filterPackages[0].filter.StartDate = new Date();
    this.filterPackages[0].filter.StartDate.setDate(this.filterPackages[0].filter.StartDate.getDate() - 7);
    this.filterPackages[0].filter.EndDate.setDate(this.filterPackages[0].filter.StartDate.getDate() + 14); 
  }

  removeFilterPackage(index) {
    this.filterPackages.splice(index, 1);
  }

  onFilterChanged(filter: Filter, filterPackage: FilterWithDateTime, index: number) {
    filter.StartDate = new Date(filter.StartDate);
    filter.EndDate = new Date(filter.EndDate);

    filterPackage.filter = filter;

    console.log(this);

    const startDate = filter.StartDate ? new Date(filter.StartDate) : null;
    const endDate = filter.EndDate ? new Date(filter.EndDate) : null;

    this.childrenSelectDate
      .forEach(dateSelect => {
        if (dateSelect.identifier && dateSelect.identifier === `start-date-${index}`) {
          dateSelect.raiseMinimum(startDate);
          if (this.hasEndDate) {
            dateSelect.lowerMaximum(endDate);
          }
        } else if (dateSelect.identifier && dateSelect.identifier === `end-date-${index}`) {
          dateSelect.raiseMinimum(startDate);
          if (this.hasEndDate) {
            dateSelect.lowerMaximum(endDate);
          }
        }
      });

    this.packageChanged.emit(this.filterPackages);
  }

  onStartDateChanged(startDate: Date, filterPackage: FilterWithDateTime) {
    const minimum : Date = new Date(this.filterPackages[0].filter.StartDate);
    this.messageShowStartDate = startDate.getTime() < minimum.getTime() ? true : false;
    filterPackage.start = startDate;

    this.childrenSelectDate
      .filter(item => item.identifier && item.identifier === 'end-date')
      .forEach(dateSelect => {
        dateSelect.raiseMinimum(startDate);
      });
      
    this.packageChanged.emit(this.filterPackages);
  }

  onEndDateChanged(endDate: Date, filterPackage: FilterWithDateTime) {
    const maximum : Date = new Date(this.filterPackages[0].filter.EndDate);
    this.messageShowEndDate = endDate.getTime() > maximum.getTime() ? true : false;
    filterPackage.end = endDate;
    this.packageChanged.emit(this.filterPackages);
  }
}
