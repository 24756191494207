import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Competitor } from '@gsegames/arena.models';

@Component({
  selector: 'integer-winner',
  templateUrl: './integerwinner.component.html',
  styleUrls: ['./integerwinner.component.css']
})
export class IntegerwinnerComponent implements OnInit {

  @Input() isMain: boolean;
  @Input() competitors: Competitor [];
  @Input() step : number;
  @Output() answer = new EventEmitter();

  scores : number[] = [];
  real : boolean;
  expected : boolean;
  constructor() { }

  ngOnInit() {
    this.real = this.step == 0 ? true : false;
    this.expected = this.step == 1 ? true : false;
  }

  onChange(event){
    let target = event.target;
    if(event.target.name == "left"){
      if(this.real == true)
      this.scores[0] = target.value;
      else if(this.expected == true)
      this.scores[1] = target.value;
    }
    else if(event.target.name == "right"){
      if(this.real == true)
      this.scores[1] = target.value;
      else if(this.expected == true)
      this.scores[0] = target.value;
    }
    this.answer.emit({
      competitorsId: this.competitors,
      scores : this.scores
    });
  }
}
