import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Tournament } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';
import { InliningService } from 'src/services/InliningService';
import { ViewModelType, ViewModel } from 'src/models/ViewModel';
import { getTranslationByKey, mainLanguage } from 'src/core/translations';

interface TournamentViewModel { label: string; key: string; inlinedViewModel?: ViewModel<Tournament>; tournament: Tournament; }


@Component({
  selector: 'select-tournament',
  templateUrl: './select-tournament.component.html',
  styleUrls: ['./select-tournament.component.scss']
})
export class SelectTournamentComponent implements OnInit {

  @Input() parentId: string = null;
  @Input() initialTournament: Tournament = null;
  @Output() tournamentChanged = new EventEmitter<Tournament>();

  tournamentList: TournamentViewModel[]
  tournament: TournamentViewModel = { label: null, key: null, inlinedViewModel: null, tournament: null };

  constructor(private remote: RemoteService, private inlining: InliningService) { }

  ngOnInit() {
    if (this.initialTournament) {
      this.tournament = { label: this.initialTournament.NameKey, key: this.initialTournament._id, tournament: this.initialTournament };
    }

    this.updateTournamentListFromRemote();
  }

  updateTournamentListFromRemote() {
    this.remote.getTournaments().then( tournaments => {
      this.tournamentList = tournaments.map( tournament => ({ label: tournament.NameKey, key: tournament._id, tournament }));
    })
  }

  onTournamentChanged(tournamentKey: string) {
    this.tournament = this.tournamentList.find(tournament => tournament.key == tournamentKey);
    this.tournamentChanged.emit(this.tournament.tournament);
  }

  onNewTournament() {
    this.inlining.requestNewItem(ViewModelType.Tournaments, this.parentId, this.onInlinedTournamentDiscard.bind(this))
    .then((newItem) => {
      this.tournament.inlinedViewModel = newItem.viewModel;
    });
  }

  inlinedTournamentIdentifier() {
    return getTranslationByKey(this.tournament.inlinedViewModel.localModel, this.tournament.inlinedViewModel.localModel.NameKey, mainLanguage);
  }

  onInlinedTournamentDiscard() {
    this.tournament = { label: null, key: null, inlinedViewModel: null, tournament: null };
  }
}
