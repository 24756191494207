import { Injectable } from '@angular/core';
import { ViewModel } from 'src/models/ViewModel';
import { FilterService } from './FilterService';

@Injectable({
  providedIn: 'root'
})
export class FeedManagerService {

  updateCallback : ((resetFeed?:boolean, update?:boolean) => void)[] = [];

  resetFeed : boolean;

  update: boolean = false;

constructor(private filter: FilterService) { }


updateFeed(){
  if(this.resetFeed == false && this.update == true && this.filter.remoteFeedPageNumber > 1){
      const previousPage = this.filter.remoteFeedPageNumber;
      this.filter.changeRemotePageNumber(1);
      let entries = (previousPage + 1) * 10; 
      this.filter.changeRemoteLimitNumber(entries);
      this.updateCallback.forEach(callback => { callback(this.resetFeed, this.update);} );
  }
  else if(this.resetFeed == false && this.update == false){
    this.updateCallback.forEach(callback => { callback(this.resetFeed);});
  }
  else{
    this.updateCallback.forEach(callback => { callback();} );
  }
}


}


