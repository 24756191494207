import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { QuizEntry, CampaignEntry } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { injectTranslation, getTranslations } from 'src/core/translations';
import { translationPrefix } from 'src/constants/translation-prefixes';
import { ValidationChain } from 'src/core/validations';
import { SelectDatetimeComponent } from '../../selectors/select-datetime/select-datetime.component';
import { FilterWithDateTime } from '../../selectors/field-filter-with-datetime/field-filter-with-datetime.component';

@Component({
  selector: 'editor-quiz-entry',
  templateUrl: './editor-quiz-entry.component.html',
  styleUrls: ['./editor-quiz-entry.component.scss']
})
export class EditorQuizEntryComponent extends Editor<QuizEntry> implements OnInit {

  @ViewChildren(SelectDatetimeComponent) childrenSelectDate: QueryList<SelectDatetimeComponent>;
  @Input() tabindex: any;

  constructor() { super() }

  translationPrefixMap = {
    'NameKey': translationPrefix.Quiz.Name,
    'PrizeTextDescriptionKey': translationPrefix.Quiz.PrizeTextDescription,
    'TermsKey': translationPrefix.Quiz.Terms,
    'ImageDefaultVideoKey': translationPrefix.Quiz.ImageDefaultVideo,
    'ImageBannerKey': translationPrefix.Quiz.ImageBanner,
  };

  get minimumDate() {
    return this.model.PublishDate ? this.model.PublishDate : new Date();
  }

  ngOnInit() {
    super.ngOnInit();

    if(!this.model.CampaignId){
      this.model.CampaignId = "";
    }

    this.viewModel.validators = {
      'NameKey': new ValidationChain('NameKey').translation(),
      'PrizeTextDescriptionKey': new ValidationChain('PrizeTextDescriptionKey').translation(),
      'Prize': new ValidationChain('Prize').min(0),
      'IntroVideoUrl': new ValidationChain('IntroVideoUrl').notEmpty(),
      'TermsKey': new ValidationChain('TermsKey').translation(),
      'PublishDate': new ValidationChain('PublishDate').debug(),
      'FilterWithDate': new ValidationChain('FilterWithDate').notNull().notEmpty().withFilter(),
      'Sponsor.Name': new ValidationChain('Sponsor.Name').notEmpty(),
      'Sponsor.Url': new ValidationChain('Sponsor.Url').notEmpty(),
      'ImageEntry': new ValidationChain('ImageEntry').notEmpty(),
      'ImageConsentSponsoredBy': new ValidationChain('ImageConsentSponsoredBy').notEmpty(),
      'ImageDefaultVideoKey': new ValidationChain('ImageDefaultVideoKey').translation(),
      'ImageBannerKey': new ValidationChain('ImageBannerKey').translation(),
      'ImageLogoRanking': new ValidationChain('ImageLogoRanking').notEmpty(),
      'Questions': new ValidationChain('Questions').notEmpty().noNullInList(),
      'Campaign': new ValidationChain('Campaign').notNull()

    };

    this.viewModel.validators['NameKey'].execute(this.model.NameKey);
    this.viewModel.validators['PrizeTextDescriptionKey'].execute(this.model.PrizeTextDescriptionKey);
    this.viewModel.validators['Prize'].execute(this.model.Prize);
    this.viewModel.validators['IntroVideoUrl'].execute(this.model.IntroVideoUrl);
    this.viewModel.validators['TermsKey'].execute(this.model.TermsKey);
    this.viewModel.validators['PublishDate'].execute(this.model.PublishDate);
    this.viewModel.validators['FilterWithDate'].execute(this.viewModel.multipleFilterPackages);
    this.viewModel.validators['Sponsor.Name'].execute(this.model.Sponsor.Name);
    this.viewModel.validators['Sponsor.Url'].execute(this.model.Sponsor.Url);
    this.viewModel.validators['ImageEntry'].execute(this.model.ImageEntry);
    this.viewModel.validators['ImageConsentSponsoredBy'].execute(this.model.ImageConsentSponsoredBy);
    this.viewModel.validators['ImageDefaultVideoKey'].execute(this.model.ImageDefaultVideoKey);
    this.viewModel.validators['ImageBannerKey'].execute(this.model.ImageBannerKey);
    this.viewModel.validators['ImageLogoRanking'].execute(this.model.ImageLogoRanking);
    this.viewModel.validators['Questions'].execute(this.model.Questions);
    this.viewModel.validators['Campaign'].execute(this.CampaignIdAndPosition);

  }

  CheckTabIndex(index: string): boolean{
    if(index == this.tabindex)
    return true;
    
  return false;
  }

  translationFor(key) {
    let translation;

    if(this.model.TranslationsBundle){
      translation = this.model.TranslationsBundle.Translations.find( element => element.FieldName == key );
    }

    return translation;
  }

  onNameChanged(translation) {
    this.viewModel.validators['NameKey'].execute(translation);
    this.model.NameKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }
  onPrizeDescriptionChanged(translation) {
    this.viewModel.validators['PrizeTextDescriptionKey'].execute(translation);
    this.model.PrizeTextDescriptionKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }
  onTermsChanged(translation) {
    this.viewModel.validators['TermsKey'].execute(translation);
    this.model.TermsKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }
  onFilterChanged(filter) {
    this.viewModel.validators['Filter'].execute(filter);
    this.model.Filter = filter

    const startDate = filter.StartDate ? new Date(filter.StartDate) : null;

    this.childrenSelectDate
      .forEach(dateSelect => {
        if (dateSelect.identifier && dateSelect.identifier === 'publish-date') {
          dateSelect.raiseMinimum(startDate);
        }
      });


    this.emitChanges();
  }
  onPublishDateChanged(date) {
    this.viewModel.validators['PublishDate'].execute(date);
    this.model.PublishDate = date

    this.childrenSelectDate
      .filter(item => item.identifier && item.identifier === 'start-date')
      .forEach(dateSelect => {
        dateSelect.raiseMinimum(date);
      });

    this.emitChanges();
  }
  onStartDateChanged(date) {
    this.viewModel.validators['Date'].execute(date);
    this.model.Date = date
    this.emitChanges();
  }

  get filterWithDateTime(): FilterWithDateTime[] {
    if (this.viewModel.fromRemoteAndTemplate()) {
      return [{
        filter: this.model.Filter,
        start: this.model.Date,
        end: this.model.EndDate,
      }];
    } else if (this.viewModel.multipleFilterPackages.length > 0) {
      return this.viewModel.multipleFilterPackages;
    } else {
      return null;
    }
  }

  onFilterWithDateTimeChanged(filterPackages: FilterWithDateTime[]) {
    this.viewModel.validators['FilterWithDate'].execute(filterPackages);
    this.viewModel.multipleFilterPackages = filterPackages;
    if(filterPackages.length == 1){
      this.model.Date = filterPackages[0].start;
      this.model.EndDate = filterPackages[0].end;
    }
    this.emitChanges();
  }

  onSponsorImageChanged(url) {
    this.viewModel.validators['Sponsor.Url'].execute(url);
    this.model.Sponsor.Url = url
    this.emitChanges();
  }
  onEntryImageChanged(url) {
    this.viewModel.validators['ImageEntry'].execute(url);
    this.model.ImageEntry = url
    this.emitChanges();
  }
  onConsentSponsoredByImageChanged(url) {
    this.viewModel.validators['ImageConsentSponsoredBy'].execute(url);
    this.model.ImageConsentSponsoredBy = url
    this.emitChanges();
  }
  onDefaultVideoKeyImageChanged(translation) {
    this.viewModel.validators['ImageDefaultVideoKey'].execute(translation);
    this.model.ImageDefaultVideoKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }
  onBannerKeyImageChanged(translation) {
    this.viewModel.validators['ImageBannerKey'].execute(translation);
    this.model.ImageBannerKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }
  onLogoRankingImageChanged(url) {
    this.viewModel.validators['ImageLogoRanking'].execute(url);
    this.model.ImageLogoRanking = url
    this.emitChanges();
  }
  onQuestionChanged(question, index) {
    this.model.Questions[index] = question
    this.viewModel.validators['Questions'].execute(this.model.Questions);
    this.emitChanges();
  }
  onQuestionRemoved(index) {
    this.model.Questions.splice(index, 1);
    this.viewModel.validators['Questions'].execute(this.model.Questions);
    this.emitChanges();
  }
  onQuestionAdded() {
    this.model.Questions.push(null);
    this.viewModel.validators['Questions'].execute(this.model.Questions);
    this.emitChanges();
  }
  onPrizeChanged(prize) {
    this.viewModel.validators['Prize'].execute(prize);
    this.emitChanges();
  }
  onIntroVideoUrlChanged(url) {
    this.viewModel.validators['IntroVideoUrl'].execute(url);
    this.emitChanges();
  }
  onSponsorNameChanged(name) {
    this.viewModel.validators['Sponsor.Name'].execute(name);
    this.emitChanges();
  }

  translationVerification(translation){
    if(!this.model.TranslationsBundle) this.model.TranslationsBundle = {Translations: [], Version: 0};
    let dataFound = this.model.TranslationsBundle.Translations.find( element => element.FieldName == translation.FieldName);
    if(dataFound)
      this.model.TranslationsBundle.Translations[this.model.TranslationsBundle.Translations.indexOf(dataFound)] = translation;
    else
      this.model.TranslationsBundle.Translations.push(translation);

    if(this.viewModel.remoteModel.TranslationsBundle.Version == this.model.TranslationsBundle.Version){
      this.model.TranslationsBundle.Version++;
    }
    console.log(this.model.TranslationsBundle);
  }

  get CampaignIdAndPosition() {
    if (this.viewModel.fromRemote) {
      return {
      key : this.model.CampaignId,
      position: this.model.CampaignPosition,
      };
    }
  }

  onCampaignChanged(Campaign){
    this.viewModel.validators['Campaign'].execute(Campaign);
    this.model.CampaignId = Campaign.key;
    this.model.CampaignPosition = Campaign.position;
    this.emitChanges();
  }

}
