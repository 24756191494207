import { ViewerEventEntryComponent } from './viewers/viewer-event-entry/viewer-event-entry.component';
import { ViewerHighlightEntryComponent } from './viewers/viewer-highlight-entry/viewer-highlight-entry.component';
import { ViewerShakeEntryComponent } from './viewers/viewer-shake-entry/viewer-shake-entry.component';
import { ViewerQuestionEntryComponent } from './viewers/viewer-question-entry/viewer-question-entry.component';
import { ViewerQuizEntryComponent } from './viewers/viewer-quiz-entry/viewer-quiz-entry.component';
import { ViewerCampaignEntryComponent } from './viewers/viewer-campaign-entry/viewer-campaign-entry.component';
import { GenericviewComponent } from '../genericview/genericview.component';
import { EditorEventEntryComponent } from './editors/editor-event-entry/editor-event-entry.component';
import { EditorHighlightEntryComponent } from './editors/editor-highlight-entry/editor-highlight-entry.component';
import { EditorShakeEntryComponent } from './editors/editor-shake-entry/editor-shake-entry.component';
import { EditorQuestionEntryComponent } from './editors/editor-question-entry/editor-question-entry.component';
import { EditorQuizEntryComponent } from './editors/editor-quiz-entry/editor-quiz-entry.component';
import { EditorCampaignEntryComponent } from './editors/editor-campaign-entry/editor-campaign-entry.component';
import { EditorSportComponent } from './editors/editor-sport/editor-sport.component';
import { EditorTournamentComponent } from './editors/editor-tournament/editor-tournament.component';
import { EditorCompetitorComponent } from './editors/editor-competitor/editor-competitor.component';
import { EditorGoalComponent } from './editors/editor-goal/editor-goal.component';
import { EditorFilterComponent } from './editors/editor-filter/editor-filter.component';
import { EditorResultComponent } from './editors/editor-result/editor-result.component';
import { EditorEnvelopComponent } from './editors/editor-envelop/editor-envelop.component';
import { EditorCouponComponent } from './editors/editor-coupon/editor-coupon.component';
import { EditorQuestionComponent } from './editors/editor-question/editor-question.component';
import { EditorLevelComponent } from './editors/editor-level/editor-level.component';
import { EditorTranslationComponent } from './editors/editor-translation/editor-translation.component';
import { EditorMediaComponent } from './editors/editor-media/editor-media.component';
import { EditorContentByCodeComponent } from './editors/editor-content-by-code/editor-content-by-code.component';

export type ViewModelTypeMapComponentEntry = any
export type ViewModelTypeMap = { [modelType: string]: { viewer: ViewModelTypeMapComponentEntry, editor: ViewModelTypeMapComponentEntry , tab: boolean } }

export let ViewModelTypeMap: ViewModelTypeMap = {
  'EventEntry':     { viewer: ViewerEventEntryComponent    , editor: EditorEventEntryComponent,     tab:false},
  'HighlightEntry': { viewer: ViewerHighlightEntryComponent, editor: EditorHighlightEntryComponent, tab:false},
  'ShakeEntry':     { viewer: ViewerShakeEntryComponent    , editor: EditorShakeEntryComponent,     tab:false},
  'QuestionEntry':  { viewer: ViewerQuestionEntryComponent , editor: EditorQuestionEntryComponent,  tab:false},
  'QuizEntry':      { viewer: ViewerQuizEntryComponent     , editor: EditorQuizEntryComponent,      tab:true},
  'CampaignEntry':  { viewer: ViewerCampaignEntryComponent , editor: EditorCampaignEntryComponent,  tab:false},
  'Sports':         { viewer: GenericviewComponent         , editor: EditorSportComponent,          tab:false},
  'Tournaments':    { viewer: GenericviewComponent         , editor: EditorTournamentComponent,     tab:false},
  'Competitors':    { viewer: GenericviewComponent         , editor: EditorCompetitorComponent,     tab:false},
  'Objectives':     { viewer: GenericviewComponent         , editor: EditorGoalComponent,           tab:false},
  'Filters':        { viewer: GenericviewComponent         , editor: EditorFilterComponent,         tab:false},
  'Results':        { viewer: GenericviewComponent         , editor: EditorResultComponent,         tab:false},
  'Envelops':       { viewer: GenericviewComponent         , editor: EditorEnvelopComponent,        tab:false},
  'Coupons':        { viewer: GenericviewComponent         , editor: EditorCouponComponent,         tab:false},
  'Questions':      { viewer: GenericviewComponent         , editor: EditorQuestionComponent,       tab:false},
  'Levels':         { viewer: GenericviewComponent         , editor: EditorLevelComponent,          tab:false},
  'Translations':   { viewer: GenericviewComponent         , editor: EditorTranslationComponent,    tab:false},
  'Media':          { viewer: GenericviewComponent         , editor: EditorMediaComponent,          tab:false},
  'ContentByCode':  { viewer: GenericviewComponent         , editor: EditorContentByCodeComponent,  tab:false},
  'Goals':          { viewer: GenericviewComponent         , editor: EditorGoalComponent,           tab:false},
}
