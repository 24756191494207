import { TranslationViewModel } from './translations';
import { FilterWithDateTime } from 'src/shell/feed/viewmodel-controller/selectors/field-filter-with-datetime/field-filter-with-datetime.component';
import * as _ from 'lodash';
import { Translation } from '@gsegames/arena.models';

export class Validation {
    isValid: boolean = true;
    messages: string[] = [];
}

export type Validator = (item) => Validation;

export class ValidationChain implements Validation {

    isValid = true;
    messages: string[] = [];

    chain = [];

    constructor(public name: string) {}

    execute(item) {
        this.isValid = true;
        this.messages = [];

        this.chain.forEach((validator: Validator) => {
            const validationResult = validator(item);

            if (!validationResult.isValid) {
                this.isValid = false;
                this.messages.concat(validationResult.messages);
            }
        });

        return this;
    }

    debug() {
        const f = (item: any) => {
            console.log(item);
            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    requiredIf(cond: (item?: any) => boolean) {
        const f = (item: any) => {
            if (!cond(item)) {
                this.messages.push(`condition not met`);
                this.isValid = true;
            }

            return this;
        };

        this.chain.push( f.bind(this) );
        return this;
    }

    min(value) {
        const f = (item: any) => {
            if (item < value) {
                this.messages.push(`${this.name} is not defined`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    bool(){
        const f = (item: any) => {
            if (item === false) {
                this.messages.push(`${this.name} is not defined`);
                this.isValid = false;
            }
            else{
                this.isValid = true;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    minLength(value) {
        const f = (items: any[]) => {
            if (items.length < value) {
                this.messages.push(`${this.name} needs at least ${value} items`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    allUnique(key: string = null) {
        const f = (items: any[]) => {
            const result = {
                foundTwin: false,
                index1: -1,
                index2: -1,
            };

            outerloop:
            for ( let i = 0; i < items.length; ++i ) {
                const item = items[i];
                for ( let j = 0; j < items.length; ++j ) {
                    if (i === j) {
                        continue;
                    } else {
                        console.log(item[key]);
                        if ( item[key] === items[j][key] ) {
                            result.foundTwin = true;
                            result.index1 = i;
                            result.index2 = j;
                            break outerloop;
                        }
                    }
                }
            }

            if (result.foundTwin) {
                this.messages.push(`#${result.index1} and #${result.index2} are the same.`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    noNullInList(key: string = null) {
        const f = (items: any[]) => {
            let nNulls = 0;
            items.forEach(item => {
                const value = _.isNil(key) ? item : item[key];
                if (_.isNil(value)) {
                    ++nNulls;
                }
            });

            if (nNulls > 0) {
                this.isValid = false;
                this.messages.push(`Some items are not defined`);
            }

            return this;
        };

        this.chain.push( f.bind(this) );
        return this;
    }

    translation() {
        const f = (item: Translation | string) => {
            if (typeof item === 'string') {
                if (item === '') {
                    this.isValid = false;
                    this.messages.push(`${this.name} doesn't have any translation yet`);
                }
            } else {
                const availableLanguages = ['en', 'pt-BR'];
                let validTranslations = 0;
                availableLanguages.forEach(language => {
                    const translation = item.Translations[language]
                    if (translation && translation !== '') {
                        ++validTranslations;
                    }
                });

                if (availableLanguages.length !== validTranslations) {
                    this.isValid = false;
                    this.messages.push(`${availableLanguages.length - validTranslations} translations missing`);
                }
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    notNull() {
        const f = (item: any) => {
            if (item === null) {
                this.messages.push(`${this.name} is not defined`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    withFilter() {
        const f = (item: FilterWithDateTime[] | null) => {
            if (item[0] && item[0].filter === null) {
                this.messages.push(`filter is not defined`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    empty() {
        const f = (item: string | any[]) => {
            if (item.length !== 0) {
                this.messages.push(`${this.name} is not empty`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    notEmpty() {
        const f = (item: string | any[]) => {
            if (item.length === 0) {
                this.messages.push(`${this.name} is empty`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }

    regex(regex: RegExp) {
        const f = (item: string) => {

            const matched = regex.test(item);

            if (!matched) {
                this.messages.push(`${this.name} is not a valid`);
                this.isValid = false;
            }

            return this;
        };

        this.chain.push( f.bind(this) );

        return this;
    }
}
