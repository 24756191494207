import { Component, OnInit } from '@angular/core';
import { Goal } from '@gsegames/arena.models';
import { Editor } from '../editor';

@Component({
  selector: 'app-editor-goal',
  templateUrl: './editor-goal.component.html',
  styleUrls: ['./editor-goal.component.scss']
})
export class EditorGoalComponent extends Editor<Goal> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
