import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Sport } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';

type SportViewModel = { label: string, key: string };

@Component({
  selector: 'select-sport',
  templateUrl: './select-sport.component.html',
  styleUrls: ['./select-sport.component.scss']
})
export class SelectSportComponent implements OnInit {

  @Input() initialSport: Sport = null;
  @Output() sportChanged = new EventEmitter<Sport>();

  remoteSportList: Sport[];
  sportList: SportViewModel[];
  sport: SportViewModel = { label: null, key: null };

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if (this.initialSport) {
      this.sport = { label: this.initialSport.NameKey, key: this.initialSport._id }
    }

    this.updateSportListFromRemote();
  }

  updateSportListFromRemote() {
    this.remote.getSports().then(sports => {
      this.remoteSportList = sports;
      this.sportList = sports.map( sport => ({ label: sport.NameKey, key: sport._id }) );
    });
  }

  onSportChanged(sportKey) {
    this.sport = this.sportList.find(sport => sport.key === sportKey);
    const sport = this.remoteSportList.find(remoteSport => remoteSport._id === this.sport.key);
    this.sportChanged.emit(sport);
  }

}
