import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Envelope } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';

type EnvelopeViewModel = { label: string, key: string };

@Component({
  selector: 'select-envelope',
  moduleId: module.id,
  templateUrl: 'select.envelope.component.html',
  styleUrls: ['select.envelope.component.css']
})
export class SelectEnvelopeComponent {
 
  @Input() initialEnvelope: Envelope = null;
  @Input() editDisabled: boolean = null;
  @Output() envelopeChanged = new EventEmitter<Envelope>();

  remoteenvelopeList: Envelope[];
  envelopeList: EnvelopeViewModel[];
  envelope: EnvelopeViewModel = { label: null, key: null };

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    
    if (this.initialEnvelope) {
      this.envelope = { label: this.initialEnvelope.Name , key: this.initialEnvelope._id };
    }

    this.updateEnvelopeListFromRemote();
  }

  updateEnvelopeListFromRemote() {
    this.remote.getEnvelopes().then(envelopes => {
      this.remoteenvelopeList = envelopes;
      this.envelopeList = envelopes.map( envelope => ({ label: envelope.Name , key: envelope._id }) );
    });
  }

  onEnvelopeChanged(envelopeKey) {
    this.envelope = this.envelopeList.find(envelope => envelope.key === envelopeKey);
    const envelope = this.remoteenvelopeList.find(remoteenvelope => remoteenvelope._id === this.envelope.key);
    this.envelopeChanged.emit(envelope);
  }

}
