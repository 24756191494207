import { ViewModelType, ViewModel, ViewModelState } from 'src/models/ViewModel';
import {
    EventEntry,
    HighlightEntry,
    ShakeEntry,
    QuestionEntry,
    QuizEntry,
    Sport,
    Tournament,
    Competitor,
    Goal,
    Filter,
    ContentCode,
    Envelope,
    Coupon,
    Question,
    Localization,
    QuizQuestionLevel,
    EntryType,
    PushNotification,
    EnvelopeStatus,
} from '@gsegames/arena.models/';
import { CampaignEntry } from '@gsegames/arena.models/lib/Entries/CampaignEntry';
import * as _ from 'lodash';
import * as moment from 'moment';
import { guidGenerator } from './utilities';
import { TranslationsBundle } from "@gsegames/arena.models/lib/Abstraction/Translation";
import { pick } from 'lodash';
import { TournamentRound } from '@gsegames/arena.models/lib/Rounds';
import { Client } from '@gsegames/arena.models/lib/Client';
export function createEmptyViewModel(type) {
    const model = createEmptyModel(type);

    const id = guidGenerator();
    const clone = (originalModel) => _.cloneDeep(originalModel);
    const diff = () => ({ changes: '', additions: '', eliminations: '' });

    const viewModel = new ViewModel(model, type, {id}, {clone}, {diff});
    viewModel.state = ViewModelState.NEW;

    return viewModel;
}

export function createViewModelFromTemplate(type, savedModel) {
    let model = createEmptyModel(type);
    model = Object.assign(model , savedModel);
    delete model._id; // It is done to not create errors in the server

    const id = guidGenerator();
    const clone = (originalModel) => _.cloneDeep(originalModel);
    const diff = () => ({ changes: '', additions: '', eliminations: '' });

    const viewModel = new ViewModel(model, type, {id}, {clone}, {diff});
    viewModel.state = ViewModelState.NEW;
    viewModel.fromTemplate = true;

    return viewModel;
}

export function createEmptyModel(type: ViewModelType) {
    let result: any = null;
    switch (type) {
        case ViewModelType.EventEntry:
            result = createEventEntry();
            break;
        case ViewModelType.HighlightEntry:
            result = createHighlightEntry();
            break;
        case ViewModelType.ShakeEntry:
            result = createShakeEntry();
            break;
        case ViewModelType.QuestionEntry:
            result = createQuestionEntry();
            break;
        case ViewModelType.QuizEntry:
            result = createQuizEntry();
            break;
        case ViewModelType.CampaignEntry:
            result = createCampaignEntry();
            break;
        case ViewModelType.Sports:
            result = createSport();
            break;
        case ViewModelType.Tournaments:
            result = createTournament();
            break;
        case ViewModelType.Competitors:
            result = createCompetitor();
            break;
        case ViewModelType.Objectives:
            result = createObjective();
            break;
        case ViewModelType.Filters:
            result = createFilter();
            break;
        case ViewModelType.ContentByCode:
            result = createContentByCode();
            break;
        // case ViewModelType.Results:
        //     result = createResults();
        //     break;
        case ViewModelType.Envelops:
            result = createEnvelope();
            break;
        case ViewModelType.Coupons:
            result = createCoupon();
            break;
        case ViewModelType.Goals:
            result = createGoal();
            break;
        case ViewModelType.Questions:
            result = createQuestion();
            break;
        case ViewModelType.Levels:
            result = createLevel();
            break;
        case ViewModelType.Translations:
            result = createTranslation();
            break;
        case ViewModelType.Client:
            result = createClient();
            break;
        // case ViewModelType.Media:
        //     result = createMedia();
        //     break;
        default:
            console.error('no model type for: ', type);
    }

    return result;
}

function createEventEntry(): EventEntry {
    return {
        EntryType: EntryType.Event,
        Tournament: null,
        EnergyCost: 0,
        LightningEnergy: 0,
        IsHot: false,
        WasNotified: '',
        HasResult: false,
        LocationKey: '',
        LocationImageUrl: '',
        NameKey: '',
        Competitors: [],
        AvaiableDuelsNotified: false,
        MaxTrophyReward: 0,
        MaxCouponReward: 0,
        RealAnswers: [],
        PlayersResultAnswers: [],
        PlayersAnswers: [],
        Goals: [],
        OddsV2: [],
        GameWeight: 0,
        AccumulatedGameWeight: 0,
        IsSuggested: false,
        CompilerCompleted: false,
        CompilerStarted: false,
        Origins: [],
        Round: new TournamentRound(), 

        ...createEmptyCommonHeader(),
        ...createEmptyEntryCommonHeader(),
    };
}

function createCampaignEntry(): CampaignEntry {
    return {
        EntryType: EntryType.Campaign,
        Name: '',
        SponsorIconUrl :"",
        DescriptionImageUrl:"",
        TermsAndConditionsUrl:"",
        ConsentText:"",
        ConsentImage:"",
        LightningEnergy: null,
        Entries: [],
        ClientAssociated:"",
        
        ...createEmptyCommonHeader(),
        ...createEmptyEntryCommonHeader(),
    };
}
function createHighlightEntry(): HighlightEntry {
    return {
        EntryType: EntryType.Highlight,
        Name: '',
        ImageUrl: '',
        LinkUrl: '',
        IsEnabled: false,
        Position: 1,

        ...createEmptyCommonHeader(),
        ...createEmptyEntryCommonHeader(),
    };
}
function createShakeEntry(): ShakeEntry {
    return {
        EntryType: EntryType.Legacy_Shake,
        ShakeType: -1,
        NameKey: '',
        Cost: 0,
        LightningEnergy: 0,
        ImageBackground: '',
        ImageSponsoredUrl: '',
        ImageTeam1Url: '',
        ImageTeam2Url: '',
        Delayed: false,
        DashboardIds: [],
        Question: null,
        ShakePlayersAnswers: [],
        ShakeTimePlayers: [],
        WinnerPositions: [],
        Running: false,
        Finished: false,
        MinimumShakeTime: 0,
        RewardType: null,
        CloseDate: null,
        TimeRanking: null,
        VoteShakeRanking: undefined,
        Prizes: [],

        ...createEmptyCommonHeader(),
        ...createEmptyEntryCommonHeader(),
    };
}
function createQuestionEntry(): QuestionEntry {
    return {
        EntryType: EntryType.Question,
        Name: '',
        Question: null,
        Reward: 0,
        LightningEnergy: 0,
        Odds: [],
        OddsV2: [],
        PlayersAnswers: [],
        QuestionEntryOdds: [],

        ...createEmptyCommonHeader(),
        ...createEmptyEntryCommonHeader(),
    };
}
function createQuizEntry(): QuizEntry {
    return {
        EntryType: EntryType.Quiz,
        NameKey: '',
        Prize: 0,
        PrizeTextDescriptionKey: '',
        InvitationPush: createPushNotification(),
        AlertPushs: [],
        AlertConsentedUsersPushs: [],
        IntroVideoUrl: '',
        TermsUrl: '',
        TermsKey: '',
        Sponsor: {
            Url: '',
            Name: '',
        },
        Questions: [],
        States: [],
        QuestionsMaxPoints: 0,
        QuestionsCount: 0,
        IsCalculated: false,
        ImageEntry: '',
        ImageConsentSponsoredBy: '',
        ImageTerms: '',
        ImageDefaultVideoKey: '',
        ImageBannerKey: '',
        ImageLogoRanking: '',
        LightningEnergy: 0,

        ...createEmptyCommonHeader(),
        ...createEmptyEntryCommonHeader(),
    };
}
function createSport(): Sport {
    return {
        NameKey: '',
        IconUrl: '',

        ...createEmptyCommonHeader(),
    };
}
function createTournament(): Tournament {
    return {
        NameKey: '',
        IconUrl: '',
        Sport: createSport(),
        GameWeight: 0,
        Tags: '',
        EventCount: 0,
        IsFollowing: false,

        ...createEmptyCommonHeader(),
    };
}
function createCompetitor(): Competitor {
    return {
        IsAthlete: false,
        Team: null,
        Athletes: [],
        Name: '',
        Alias: '',
        FlagUrl: '',
        IconUrl: '',
        Sport: createSport(),
        GameWeight: 0,
        Color: '#ffffff',
        IsSelectableForFavorite: false,

        ...createEmptyCommonHeader(),
    };
}
function createObjective(): Goal {
    return {
        IsMain: false,
        GoalType: -1,
        Name: '',

        ...createEmptyCommonHeader(),
    };
}
function createFilter(): Filter {
    return {
        Name: '',
        Query: null,
        ShadowQuery: null,
        EndDate: null,
        StartDate: null,
        IsEnabled: false,
        RecentlyFound: 0,
        IsCompiled: false,
        DashboardConfigs: null,

        ...createEmptyCommonHeader(),
    };
}
function createContentByCode(): ContentCode {
    return {
        Name: '',
        StartDate: moment().toDate(),
        EndDate: moment().add(1, 'month').toDate(),
        IsEnabled: false,
        Code: '',
        CanDelete: false,
        Entries: [],
        ContentType: -1,
        IconUrl: '',
        LightningEnergy: 0,
        HasKeys: false,

        ...createEmptyCommonHeader(),
    };
}
function createEnvelope(): Envelope {
    return {
        Name: '',
        Status: EnvelopeStatus.Empty,
        Prize: undefined,
        Recipient: undefined,
        Media: {
            Icon: "",
            Logo: "",
            Image: "",
            Body: "",
            Background: "",
            FlapOpened: "",
            FlapClosed: ""
        },

        ...createEmptyCommonHeader(),
    };
}
function createCoupon(): Coupon {
    return {
        ReservedCodes: [],
        Codes: [],
        Code: '',
        MaxDaysForUse: 0,
        MaxDaysForUseWhenOpen: 0,
        ExpirationDate: moment().add(1, 'month').toDate(),  // it's the same as DueDate
        DueDate: moment().add(1, 'month').toDate(),         // it's the same as ExpirationDate
        MaxDueDate: null,   // is not used anywhere in the project, can we delete it?
        CouponRulesKey: '',
        CouponTitleKey: '',
        WinnerPosition: 0,
        WasConsumed: false,

        ...createEmptyCommonHeader(),
    };
}
function createGoal(): Goal {
    return {
        IsMain: false,
        GoalType: 0,
        Name: '',

        ...createEmptyCommonHeader(),
    }
}
function createQuestion(): Question {
    return {
        InstructionKey: '',
        QuestionType: -1,
        Targets: [],
        TargetsType: -1,

        ...createEmptyCommonHeader(),
    };
}
function createLevel(): QuizQuestionLevel {
    return {
        UIBackgroundColor: '',
        NameKey: '',
        Points: 0,

        ...createEmptyCommonHeader(),
    };
}
function createTranslation(): Localization {
    return {
        _id: '',
        Values: null,
        Name: '',
        Version: -1,
    };
}

function createClient(): Client {
    return {
        Name : '',
        ...createEmptyCommonHeader()
    }
}
// function createMedia() : Media {}
function createPushNotification(defaultDateType: 'SendDate' | 'MinutesBeforeSendMessage' = 'MinutesBeforeSendMessage'): PushNotification {
    return {
        Header: {
            en: '',
            pt: '',
        },
        Body: {
            en: '',
            pt: '',
        },
        DateType: defaultDateType,
        MinutesBeforeSendMessage: 5,
    };
}
function createEmptyCommonHeader() {
    return {
        DateOfCreation: null,
        _id: null,
        CreatorId: null,
        CreatedTimestamp: new Date(),
        UpdatedTimestamp: new Date(),
    };
}

function createEmptyEntryCommonHeader() {
    return {
        Date: moment().toDate(),
        EndDate: moment().add(1, 'hour').toDate(),
        PublishDate: moment().toDate(),
        TranslationsBundle: null,
        Filter: null,
        NotificationsSent: null,
        ScheduledNotificationIds: null,
    };
}
