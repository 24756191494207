import { Component, OnInit } from '@angular/core';
import { Coupon } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { injectTranslation } from 'src/core/translations';

@Component({
  selector: 'app-editor-coupon',
  templateUrl: './editor-coupon.component.html',
  styleUrls: ['./editor-coupon.component.scss']
})
export class EditorCouponComponent extends Editor<Coupon> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onCouponNameChanged(nameTranslation) {
    this.model.CouponTitleKey = nameTranslation.key;
    injectTranslation(nameTranslation, this.viewModel);
  }

  onCouponRuleChanged(ruleTranslation) {
    this.model.CouponTitleKey = ruleTranslation.key;
    injectTranslation(ruleTranslation, this.viewModel);
  }

  onDueDateChanged(dueDate) {
    this.model.DueDate = dueDate;
  }
}
