import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Competitor } from '@gsegames/arena.models';

@Component({
  selector: 'simple-winner',
  templateUrl: './simplewinner.component.html',
  styleUrls: ['./simplewinner.component.css']
})
export class SimplewinnerComponent implements OnInit {
  private static index = 0;
  idOption : number;
  @Input() isMain: boolean;
  @Input() isDraw: boolean;
  @Input() competitors: Competitor [];
  @Input() step :number;
  @Output() answer = new EventEmitter();

  real : boolean;
  expected : boolean; 


  constructor() { }

  ngOnInit() {
    SimplewinnerComponent.index = SimplewinnerComponent.index + 1;
    this.idOption = SimplewinnerComponent.index;
    this.real = this.step == 0 ? true : false;
    this.expected = this.step == 1 ? true : false;
  }

  onSelectChange(event){
   let target = event.target;
   this.answer.emit(target.value);
  }


}
