import { Component, OnInit } from '@angular/core';
import { ContentCode } from '@gsegames/arena.models';
import { Editor } from '../editor';

@Component({
  selector: 'app-editor-content-by-code',
  templateUrl: './editor-content-by-code.component.html',
  styleUrls: ['./editor-content-by-code.component.scss']
})
export class EditorContentByCodeComponent extends Editor<ContentCode> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onStartDateChanged(endDate) {
    this.model.StartDate = endDate;
  }

  onEndDateChanged(startDate) {
    this.model.EndDate = startDate;
  }

}
