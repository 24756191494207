import { Component, OnInit, ViewChild, ViewChildren, Input } from '@angular/core';
import { ViewModel, ViewModelType } from 'src/models/ViewModel';
import { createViewModelFromTemplate } from 'src/core/viewmodel-creator';
import * as _ from 'lodash';
import { InliningService } from 'src/services/InliningService';
import { NewItemEditorComponent } from '../new-item-editor/new-item-editor.component';
import { StorageService } from 'src/services/StorageService';
import { NewItem } from 'src/models/NewItem';
import { RemoteService } from 'src/services/RemoteService';
import { template } from '@angular/core/src/render3';

@Component({
  selector: 'new-item-queue',
  templateUrl: './new-item-queue.component.html',
  styleUrls: ['./new-item-queue.component.scss']
})
export class NewItemQueueComponent implements OnInit {

  @Input() Type : string;
  queue: NewItem[] = [];

  itemTypes: string[];

  constructor(private inlining: InliningService, private storage: StorageService, private remote: RemoteService) {
    this.itemTypes = Object.keys(ViewModelType);

    this.inlining.registerNewItemListener(this.onNewInlinedItem.bind(this));
    this.inlining.registerSaveInlineItemsListener(this.onSaveInlinedNewItems.bind(this));

    this.remote.afterSaveCallbacks.push(this.onAfterRemotePublish.bind(this));
  }

  ngOnInit() {
  }

  onNewItem(viewModelType?: ViewModelType, model?) : ViewModel<any> {
    const newItem = new NewItem(viewModelType);
    if(model){
      newItem.setupViewModel(createViewModelFromTemplate(newItem.type, model));
    }
    newItem.isTypeChangeAllowed = false; 
    this.queue.unshift(newItem);
    console.log(newItem);
    return newItem.viewModel;
  }

  onNewInlinedItem(viewModelType: ViewModelType, requester: any, discardCallback): Promise<NewItem> {

    const promise = new Promise<NewItem>((resolve, reject) => {
      const newItem = new NewItem(viewModelType, {
        requester,
        discardCallback,
        setViewModelCallback: () => {
          resolve(newItem);
        },
      });
      this.queue.unshift(newItem);
    });

    return promise;
  }

  onNewItemDiscarded(discardedItem: NewItem) {
    const itemIndex = this.queue.findIndex(item => item.id === discardedItem.id);
    this.queue.splice(itemIndex, 1);
  }

  onSaveInlinedNewItems(requesterId: string) {
    const inlinedItems = this.queue.filter(item => item.requester && item.requester.requester === requesterId);
    inlinedItems.forEach(inlinedItem => this.storage.saveToQueue(inlinedItem.viewModel));
  }

  onAfterRemotePublish(savedViewModels: ViewModel<any>[]) {
    //TO DO: Clean only entrys that were saved
    this.queue = [];
  }

  trackID(index, element) {
    return element.id;
  }
}
