import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Viewer } from '../viewer';
import { QuizEntry, ShakeEntry, Target, TargetType, ShakeType } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';
import { translationPrefix } from 'src/constants/translation-prefixes';
import { TranslationRemoteModel, translationKeyPrefixed, mainLanguage, getTranslations, mapTranslationsViewModelToRemoteModel } from 'src/core/translations';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-viewer-shake-entry',
  templateUrl: './viewer-shake-entry.component.html',
  styleUrls: ['./viewer-shake-entry.component.scss']
})
export class ViewerShakeEntryComponent extends Viewer<ShakeEntry> implements OnInit {

  translations: TranslationRemoteModel = {};

  translationPrefixMap = {
    'Shake.Name': translationPrefix.Shake.Name,
    'Question.InstructionKey': translationPrefix.Question.Instruction,
    'Question.Targets': translationPrefix.Target.Name,
  };

  constructor(private remote: RemoteService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.emitViewData();
  }

  onViewerModelUpdate() {
    
    const translations = this.model.TranslationsBundle.Translations;
    if (translations.length > 0) {
      this.updateTranslations(translations);
    }
    this.emitViewData();
  }

  updateTranslations(translations) {
    this.translations = mapTranslationsViewModelToRemoteModel(translations);
  }

  emitViewData() {
    
    let shakeName = 'Shake';
    //Verify if the state of the model is new
    if(this.viewerModel.state == 2)
      shakeName = 'New Shake Entry'
    else
      shakeName = this.translation("NameKey");

    this.viewData.emit({
      title: shakeName,
      lead: `Shake ${this.shakeTypeName(this.model.ShakeType)} - ${moment(this.model.Date).format('llll')}`,
      date: this.model.Date
    });
  }

  shakeTypeName(shakeType: ShakeType): string {
    const names = Object.keys(ShakeType);
    names.splice(0, names.length / 2);
    return names[shakeType];
  }

  translation(key: string) {
    let translation;
    if(this.model.TranslationsBundle){
      
     translation = this.model.TranslationsBundle.Translations.find(element => element.FieldName == key);
     return translation.Translations[mainLanguage];
    }
    else{
      return "No Translation Available Yet"
    }
    
  }

}
