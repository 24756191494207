import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LogLevel, LogMessage } from 'src/core/log';
import { LogUserViewService } from './LogUserViewService';
import { LogStorageService } from './LogStorageService';
import { LogConsoleService } from './LogConsoleService';


@Injectable()
export class LogService {

    activeLevels;
    activeOutput;

    constructor(
        private logUserViewService: LogUserViewService,
        private logStorageService:  LogStorageService,
        private logConsoleService:  LogConsoleService
    ) {
        this.activeLevels = environment.log.levels;
        this.activeOutput = environment.log.output;
    }

    public success(message: LogMessage, contextObject?: any, trace?: any) {
        this.log(LogLevel.SUCCESS,  message, contextObject, trace);
    }
    public info (message: LogMessage, contextObject?: any, trace?: any) {
        this.log(LogLevel.INFO,  message, contextObject, trace);
    }
    public warn (message: LogMessage, contextObject?: any, trace?: any) {
        this.log(LogLevel.WARN,  message, contextObject, trace);
    }
    public error(message: LogMessage, contextObject?: any, trace?: any) {
        this.log(LogLevel.ERROR, message, contextObject, trace);
    }

    private log(level: LogLevel, message: LogMessage, contextObject?: any, trace?: any) {
        let text = message
        let title = ""
        this.logUserViewService.logLevelMap[level](<string>text, title);
    }

}