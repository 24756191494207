import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Goal, Competitor } from '@gsegames/arena.models';
import { element } from 'protractor';


@Component({
  selector: 'podium-winner',
  templateUrl: './podiumwinner.component.html',
  styleUrls: ['./podiumwinner.component.css']
})
export class PodiumwinnerComponent implements OnInit {
  
  @Input() goal: Goal;
  @Input() competitors: Competitor[];
  @Input() step :number;
  @Output() answer = new EventEmitter<string[]>();

  winners: string[] = [];
  real : boolean;
  expected : boolean;


  constructor() { 
 
  }

  ngOnInit() {
    //initialize array
    for (let i = 3; i > 0; --i) {
      this.winners.push('');
  }

  this.real = this.step == 0 ? true : false ;
  this.expected = this.step == 1 ? true : false ;


  }

  setCompetitor(placement, competitorId) {
    this.winners[placement] = competitorId;
    this.answer.emit(this.winners);
}

getCompetitor(competitorId) : string {
let competitor = this.competitors.find(element => element._id == competitorId);
if(competitor)
return competitor.Name;
else
return 'Not Selected';
}

verifySelect(): boolean{
  let count = 0;
  this.winners.forEach( (element) => {
    for(let i = 0; i< this.winners.length; i++){
      if(element == this.winners[i] && element != "")
      count++;
    }
  });
 
  if(count > 3 )
  return true;
  else
  return false;
}

}
