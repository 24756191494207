import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'Modal',
  templateUrl: './Modal.component.html',
  styleUrls: ['./Modal.component.css']
})
export class ModalComponent implements OnInit {

  constructor() { }

  @Input() message: string;
  @Input() type: string;
  @Input() title: string;
  @Output() answer = new EventEmitter<boolean>();

  ngOnInit() {
    console.log(this.message);
  }

  confirm(){
    this.answer.emit(true);
  }

  decline(){
    this.answer.emit(false);
  }

}
