/**
 * This file aggregates elements for binary returns of results.
 *
 * [[Success]] can be used for successfull operations.
 *
 * [[Failure]] can be used for failed operations.
 *
 * [[Result]] is an union type, it can be used in operations that can both succeed or fail
 *
 * [[isFailure]] and [[isSuccess]] can be used to determine how the operation went.
 * They are both guards, so combined with `if`, the code can use their members.
 * ```
 * let result : Result = someFunctionThatReturnsResult();
 * if (isFailure(result)) {
 *   console.log(result.message); // the result is of type Failure inside this if
 * }
 * ```
 */


export enum FailureType {
    NONE = 0,
    EXCEPTION = 1,
    NETWORK = 2,
    NOT_IMPLEMENTED = 3,
}

export class Success {
    constructor() { }
}

export class Failure {
    constructor(public type: FailureType, public message?: string) { }
}

export type Result = Success | Failure;

export function isFailure(result: Result): result is Failure {
    return (<Failure>result).type !== undefined;
}

export function isSuccess(result: Result): result is Success {
    return !isFailure(result);
}
