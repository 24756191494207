import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Viewer } from '../viewer';
import { QuestionEntry, TargetType, Target } from '@gsegames/arena.models';
import * as moment from 'moment';
import { TranslationRemoteModel, mainLanguage } from 'src/core/translations';
import { RemoteService } from 'src/services/RemoteService';
import { translationPrefix } from 'src/constants/translation-prefixes';
import * as _ from 'lodash';

@Component({
  selector: 'app-viewer-question-entry',
  templateUrl: './viewer-question-entry.component.html',
  styleUrls: ['./viewer-question-entry.component.scss']
})
export class ViewerQuestionEntryComponent extends Viewer<QuestionEntry> implements OnInit {

  translations: TranslationRemoteModel = {};

  translationPrefixMap = {
    'Question.InstructionKey': translationPrefix.Question.Instruction,
    'Question.Targets': translationPrefix.Target.Name,
  };

  questionTargetType: TargetType;

  constructor(private remote: RemoteService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.emitViewData();

    if (this.model.Question) {
      const textTargetTranslationsRequest = [];
      if (this.model.Question.TargetsType === TargetType.Text) {
        textTargetTranslationsRequest.push(
          ...this.model.Question.Targets.map(
            (target: Target & {NameKey}) => this.remote.getTranslation(translationPrefix.Target.Name + target.NameKey)
          )
        );
      }

      Promise.all([
        this.remote.getTranslation(translationPrefix.Question.Instruction + this.model.Question.InstructionKey),
        ...textTargetTranslationsRequest,
      ])
      .then(([questionInstructionTranslation, ...textTargetTranslations]) => {
        this.translations[translationPrefix.Question.Instruction] = questionInstructionTranslation;
        this.translations[translationPrefix.Target.Name] = textTargetTranslations;
      });
    }
  }

  onViewerModelUpdate() {
    this.emitViewData();
  }

  updateTranslations(translations) {}

  emitViewData() {
    this.viewData.emit({
      title: `${this.model.Name}`,
      lead: `Question - (until ${moment(this.model.EndDate).format('llll')})`,
      date: this.model.Date
    });
  }

  translation(key: string, index?: number) {
    const prefix = this.translationPrefixMap[key];
    if (_.isNil(index)) {
      return this.translations[prefix] ? this.translations[prefix][mainLanguage] : `[prefix]${prefix}`;
    } else {
      return this.translations[prefix] && this.translations[prefix][index] ? this.translations[prefix][index][mainLanguage] : `[key]${prefix}[${index}]`;
    }
  }

}
