import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoldersComponent } from './folders.component';
import { ShellComponent } from '../shell/shell.component';
import { ShellModule } from '../shell/shell.module';
import { DevelopmentComponent } from '../development/development.component';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DevelopmentModule } from 'src/development/development.module';
import { NavBarComponent } from './NavBar/NavBar.component';
import { LoginComponent } from 'src/folders/login/login.component';
import { RemoteService } from 'src/services/RemoteService';
import { RemoteAPIService } from 'src/services/RemoteAPIService';
import { RemoteEnvironmentService } from 'src/services/RemoteEnvironmentService';
import { FilterService } from 'src/services/FilterService';
import { LogService } from 'src/services/LogService';
import { LogUserViewService } from 'src/services/LogUserViewService';
import { LogConsoleService } from 'src/services/LogConsoleService';
import { LogStorageService } from 'src/services/LogStorageService';
import { StorageService } from 'src/services/StorageService';
import { InliningService } from 'src/services/InliningService';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AutorizationHeaderInterceptor } from 'src/interceptors/AutorizationHeaderInterceptor';
import { FeedInfoService } from 'src/services/FeedInfo.service';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SelectDatetimeComponent } from 'src/shell/feed/viewmodel-controller/selectors/select-datetime/select-datetime.component';
import { SharedModule } from 'src/shared/shared.module';
import { ModalComponent } from 'src/Modal/Modal.component';
import { BsModalService } from 'ngx-bootstrap/modal/';
import { FeedManagerService } from 'src/services/FeedManager.service';
import { EditorSportsComponent } from 'src/development/sports/editor-sports/editor.sports.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../shell/shell.module').then(m => m.ShellModule)
  },
  {
    path: 'dev', 
    loadChildren: () => import('../development/development.module').then(m => m.DevelopmentModule),
  },
  {
    path: 'stat', 
    loadChildren: () => import('../statistics/statistics.module').then(m => m.StatisticsModule),
  }
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    AlertModule.forRoot(),
    PopoverModule.forRoot(),
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    SortableModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    PaginationModule.forRoot()
  ],
  declarations: [
    FoldersComponent,
    NavBarComponent,
    LoginComponent
  ],
  providers:[
    RemoteService,
    RemoteAPIService,
    LogService,
    RemoteEnvironmentService,
    FeedManagerService,
    FilterService,
    BsModalService,
    LogService,
    LogUserViewService,
    LogConsoleService,
    LogStorageService,
    StorageService,
    InliningService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutorizationHeaderInterceptor,
      multi: true,
    }, 
    FeedInfoService
  ],
  exports : [
    RouterModule,
    SharedModule
    
],
bootstrap : [FoldersComponent]

})
export class FoldersModule { }
