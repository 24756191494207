import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Goal } from '@gsegames/arena.models';
import { RemoteService } from 'src/services/RemoteService';

type GoalViewModel = {label: string, key: string}

@Component({
  selector: 'select-single-goal',
  moduleId: module.id,
  templateUrl: 'select.single.goal.component.html',
  styleUrls: ['select.single.goal.component.css']
})
export class SelectSingleGoalComponent implements OnInit{
  
  @Input() initialGoal: Goal = null;
  @Input() editDisabled: boolean;
  @Output() goalChanged = new EventEmitter<Goal>();

  remoteGoalList: Goal[]
  goalList: GoalViewModel[]
  goal: GoalViewModel = { label: null, key: null }

  constructor(private remote: RemoteService) { }

  ngOnInit() {
    if (this.initialGoal) {
      this.goal = { label: this.initialGoal.Name, key: this.initialGoal._id }
    }
    this.updateGoalListFromRemote();
  }

  updateGoalListFromRemote() {
    this.remote.getGoals().then(goals => {
      this.remoteGoalList = goals;
      this.goalList = goals.map( goal => ({ label: goal.Name, key: goal._id }) )
    })
  }

  onGoalChanged(goalKey) {
    this.goal = this.goalList.find(goal => goal.key == goalKey);
    let goal = this.remoteGoalList.find(remotegoal => remotegoal._id == this.goal.key)
    this.goalChanged.emit(goal);
  }

}
