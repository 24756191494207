import { Injectable } from '@angular/core';
import { LogImplementation, LogLevel } from 'src/core/log';


@Injectable()
export class LogStorageService extends LogImplementation {

    constructor() {
        super();

        this.logLevelMap[LogLevel.INFO]    = (text, title?) => console.error(   `info: storage log not yet implemented`);
        this.logLevelMap[LogLevel.WARN]    = (text, title?) => console.error(   `warn: storage log not yet implemented`);
        this.logLevelMap[LogLevel.ERROR]   = (text, title?) => console.error(  `error: storage log not yet implemented`);
        this.logLevelMap[LogLevel.SUCCESS] = (text, title?) => console.error(`success: storage log not yet implemented`);
    }

}