import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { QuestionEntry, CampaignEntry } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { ValidationChain } from 'src/core/validations';
import { SelectDatetimeComponent } from '../../selectors/select-datetime/select-datetime.component';
import { FilterWithDateTime } from '../../selectors/field-filter-with-datetime/field-filter-with-datetime.component';

@Component({
  selector: 'editor-question-entry',
  templateUrl: './editor-question-entry.component.html',
  styleUrls: ['./editor-question-entry.component.scss']
})
export class EditorQuestionEntryComponent extends Editor<QuestionEntry> implements OnInit {

  @ViewChildren(SelectDatetimeComponent) childrenSelectDate: QueryList<SelectDatetimeComponent>;

  constructor() { super() }

  ngOnInit() {
    super.ngOnInit();
    if(!this.model.CampaignId){
      this.model.CampaignId = "";
    }

    this.viewModel.validators = {
      Name: new ValidationChain('Name').notEmpty(),
      LightningEnergy: new ValidationChain('LightningEnergy').min(0),
      Question: new ValidationChain('Question').notNull(),
      FilterWithDate: new ValidationChain('FilterWithDate').notNull().notEmpty().withFilter(),
      Campaign: new ValidationChain('Campaign').notNull(),
    };

    this.viewModel.validators['Name'].execute(this.model.Name);
    this.viewModel.validators['LightningEnergy'].execute(this.model.LightningEnergy);
    this.viewModel.validators['Question'].execute(this.model.Question);
    this.viewModel.validators['FilterWithDate'].execute(this.viewModel.multipleFilterPackages);
    this.viewModel.validators['Campaign'].execute(this.CampaignIdAndPosition);
  }

  onNameChange(name) {
    this.viewModel.validators['Name'].execute(name);
    this.emitChanges();
  }

  onLightningEnergyChange(energy) {
    this.viewModel.validators['LightningEnergy'].execute(energy);
    this.emitChanges();
  }

  onPublishDateChanged(date) {
    this.model.Date = date;
    this.viewModel.validators['Date'].execute(date);

    this.childrenSelectDate
    .filter(item => item.identifier && item.identifier === 'removal-date')
    .forEach(dateSelect => {
      dateSelect.raiseMinimum(date);
    });


    this.emitChanges();
  }
  
  onRemovalDateChanged(date) {
    this.model.EndDate = date;
    this.viewModel.validators['EndDate'].execute(date);
    this.emitChanges();
  }
  
  onQuestionChanged(question) {
    this.model.Question = question;
    this.viewModel.validators['Question'].execute(question);
    this.emitChanges();
  }
  
  onFilterChanged(filter) {
    this.model.Filter = filter;
    this.viewModel.validators['Filter'].execute(filter);

    const startDate = filter.StartDate ? new Date(filter.StartDate) : null;
    const endDate = filter.EndDate ? new Date(filter.EndDate) : null;

    console.log(filter);

    this.childrenSelectDate
      .forEach(dateSelect => {
        if (dateSelect.identifier && dateSelect.identifier === 'publish-date') {
          dateSelect.raiseMinimum(startDate);
          dateSelect.lowerMaximum(endDate);
        } else if (dateSelect.identifier && dateSelect.identifier === 'removal-date') {
          dateSelect.raiseMinimum(startDate);
          dateSelect.lowerMaximum(endDate);
        }
      });

    this.emitChanges();
  }


  get filterWithDateTime(): FilterWithDateTime[] {
    if (this.viewModel.fromRemoteAndTemplate()) {
      return [{
        filter: this.model.Filter,
        start: this.model.Date,
        end: this.model.EndDate,
      }];
    } else if (this.viewModel.multipleFilterPackages.length > 0) {
      return this.viewModel.multipleFilterPackages;
    } else {
      return null;
    }
  }

  onFilterWithDateTimeChanged(filterPackages: FilterWithDateTime[]) {
    this.viewModel.validators['FilterWithDate'].execute(filterPackages);
    this.viewModel.multipleFilterPackages = filterPackages;
    if(filterPackages.length == 1){
      this.model.Date = filterPackages[0].start;
      this.model.EndDate = filterPackages[0].end;
    }
    this.emitChanges();
  }

  get CampaignIdAndPosition() {
    if (this.viewModel.fromRemote) {
      return {
      key : this.model.CampaignId,
      position: this.model.CampaignPosition,
      };
    }
  }

  onCampaignChanged(Campaign){
    this.viewModel.validators['Campaign'].execute(Campaign);
    this.model.CampaignId = Campaign.key;
    this.model.CampaignPosition = Campaign.position;
    this.emitChanges();
  }



}
