export const translationPrefix = {
    Sport: {
        Name: '[Sport_Name]'
    },
    Tournament: {
        Name: '[Tournament_Name]'
    },
    Competitor: {
        Name: '[Competitor_Name]'
    },
    Event: {
        Name: '[EventEntry_Name]',
        Location: '[EventEntry_Location]'
    },
    OptionGoal: {
        OptionName: '[Option_Name]',
        Value: '[Option_Value]'
    },
    Coupon: {
        Rules: '[Coupon_Rules]',
        Title: '[Coupon_Title]'
    },
    Question: {
        Instruction: '[Question_Instruction]'
    },
    Target: {
        Name: '[TextTarget_Name]'
    },
    Shake: {
        Name: '[Shake_Name]'
    },
    Quiz: {
        Name: '[Quiz_Name]',
        PrizeTextDescription: '[Quiz_PrizeTextDescription]',
        InvitationPushHeader: '[Quiz_InvitationPushHeader]',
        InvitationPushBody: '[Quiz_InvitationPushBody]',
        AlertPushHeader: '[Quiz_AlertPushHeader]',
        AlertPushBody: '[Quiz_AlertPushBody]',
        AlertConsentedUsersPushHeader: '[Quiz_AlertConsentedUsersPushHeader]',
        AlertConsentedUsersPushBody: '[Quiz_AlertConsentedUsersPushBody]',
        ImageDefaultVideo: '[Quiz_ImageDefaultVideo]',
        ImageBanner: '[Quiz_ImageBanner]',
        Terms: '[Terms]'
    },
    QuizQuestionLevel: {
        Name: '[QuizQuestionLevel_Name]'
    }
}
