import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Viewer } from '../viewer';
import { HighlightEntry } from '@gsegames/arena.models';
import * as moment from 'moment';

@Component({
  selector: 'viewer-highlight-entry',
  templateUrl: './viewer-highlight-entry.component.html',
  styleUrls: ['./viewer-highlight-entry.component.scss']
})
export class ViewerHighlightEntryComponent extends Viewer<HighlightEntry> implements OnInit {

  constructor() { super() }

  ngOnInit() {
    super.ngOnInit();
    this.emitViewData();
  }

  onViewerModelUpdate() {
    this.emitViewData();
  }

  updateTranslations(translations) {}

  emitViewData() {
    this.viewData.emit({
      title: `${this.model.Name}`,
      lead: `Highlight - ${this.model.IsEnabled ? 'enabled': 'disabled'} - (until ${moment(this.model.EndDate).format('llll')}) - Filter : ${this.model.Filter.Name} - Position : ${this.model.Position} `,
      date: this.model.Date
    });
  }

}
