import { Component, OnInit, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { RemoteAPIService } from 'src/services/RemoteAPIService';
import { LogService } from 'src/services/LogService';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EntryType, CampaignEntry, ShakeEntry, RewardType } from '@gsegames/arena.models';
import { FilterWithDateTime } from '../field-filter-with-datetime/field-filter-with-datetime.component';
import { FeedManagerService } from 'src/services/FeedManager.service';
import { NewItemQueueComponent } from 'src/shell/new-item-queue/new-item-queue.component';
import { ViewModelTypeMap } from '../../viewmodeltypemap';
import { ViewModelType, ViewModel, mapEntryTypeToViewModelType } from 'src/models/ViewModel';
import { SaveQueueComponent } from 'src/shell/save-queue/save-queue.component';
import { mapModelIntoViewModel, RemoteService, mapTemplateModelIntoViewModel } from 'src/services/RemoteService';
import { translationPrefix } from 'src/constants/translation-prefixes';
import { getTranslations, TranslationRemoteModel, translationKeyPrefixed, mainLanguage } from 'src/core/translations';

@Component({
  selector: 'select-template',
  moduleId: module.id,
  templateUrl: 'select.template.component.html',
  styleUrls: ['select.template.component.css']
})

export class SelectTemplateComponent implements OnInit{

  @Output() newTemplate = new EventEmitter<any>();
  @ViewChild(NewItemQueueComponent) newQueue: NewItemQueueComponent;
  @ViewChild(NewItemQueueComponent) saveQueue: SaveQueueComponent;

  modalRef: BsModalRef;
  message: string; 
  newViewModel : ViewModel<any>;
  
  viewModelType : ViewModelType;
  translations: TranslationRemoteModel = {};
  entryType = EntryType;
  rewardTypes = RewardType;
  availableTemplate;

  constructor(private remoteAPI: RemoteAPIService, private remote: RemoteService, private log: LogService, private modal: BsModalService, private feedManager: FeedManagerService) {

  }

  ngOnInit(): void {
    this.loadTemplate();
    this.message = "Please insert the rest properties that need to be completed";
  }

  async loadTemplate(){
    await this.remoteAPI.get_templates().toPromise().then(templates =>{
      this.availableTemplate = templates;
    },
    e =>{
      this.log.error("Error:"+e.statusText);
    });
    this.loadTranslations();
}

//TO DO : Later , all the entryTypes will have translations so need to add those
async loadTranslations(){
  await this.availableTemplate.forEach(element => {
    let translationType;

    switch(element.EntryType as EntryType){
      case EntryType.Event: translationType = translationPrefix.Event.Name; break;
      case EntryType.Legacy_Shake: translationType = translationPrefix.Shake.Name; break;
      case EntryType.Quiz: translationType = translationPrefix.Quiz.Name; break;
    }
  }); 
}

  openModalConfirmation(html: TemplateRef<any>, template){ 
    this.modalRef = this.modal.show(html, {class:'modal-lg modal-dialog-centered', ignoreBackdropClick: true });

    this.newViewModel = mapTemplateModelIntoViewModel(template);
    console.log(template);
    console.log(this.newViewModel);
  }

  translationFor(key, templateModel) {
    try{ return templateModel.TranslationsBundle.Translations.find(element => element.FieldName == key).Translations[mainLanguage]; }
    catch(err){ console.log(err); return ""; };
  }


  closeModal(){
    this.modalRef.hide();
  }

  onSave(viewModel){
    console.log(viewModel);
    this.remote.saveEntryTemplate(viewModel);
    this.modalRef.hide();
  }
}
