import * as _ from 'lodash';
import { EntryType } from '@gsegames/arena.models';

export enum RemoteFeedMode {
    AllEntries = 'all',
    ByFilterId = 'by-filter',
    ByDashboardId = 'by-dashboard',
    ByCampaign = 'by-campaign',
    ByResults = 'events-awaiting-result'
}

export interface RemoteFeedPayload {
    mode: RemoteFeedMode;
}

export interface AllEntries extends RemoteFeedPayload {}
export interface ByResults extends RemoteFeedPayload {}
export interface ByFilterId extends RemoteFeedPayload {
    filterId: string;
}
export interface ByDashboardId extends RemoteFeedPayload {
    dashboardId: string;
}

export interface ByCampaign extends RemoteFeedPayload {
    campaignId: string;
}

export function        isAllEntries(payload: RemoteFeedPayload): payload is AllEntries    {
    return payload.mode === RemoteFeedMode.AllEntries;
}

export function        isByResults(payload: RemoteFeedPayload): payload is ByResults    {
    return payload.mode === RemoteFeedMode.ByResults;
}
export function    isByFilterIdData(payload: RemoteFeedPayload): payload is ByFilterId    {
    const byFilter = payload as ByFilterId;
    return payload.mode === RemoteFeedMode.ByFilterId
        && !_.isNil(byFilter.filterId);
}
export function isByDashboardIdData(payload: RemoteFeedPayload): payload is ByDashboardId {
    const byDashboard = payload as ByDashboardId;
    return payload.mode === RemoteFeedMode.ByDashboardId
        && !_.isNil(byDashboard.dashboardId);
}

export function isByCampaign(payload: RemoteFeedPayload): payload is ByCampaign {
    const ByCampaign = payload as ByCampaign;
    return payload.mode === RemoteFeedMode.ByCampaign
        && !_.isNil(ByCampaign.campaignId);
}