import { Injectable } from '@angular/core';
import { LogImplementation, LogLevel } from 'src/core/log';


@Injectable()
export class LogConsoleService extends LogImplementation {

    constructor() {
        super();

        this.logLevelMap[LogLevel.INFO]    = (text, title?) => console.log(   `info: ${title ? title : ''}: ${text}`);
        this.logLevelMap[LogLevel.WARN]    = (text, title?) => console.log(   `warn: ${title ? title : ''}: ${text}`);
        this.logLevelMap[LogLevel.ERROR]   = (text, title?) => console.log(  `error: ${title ? title : ''}: ${text}`);
        this.logLevelMap[LogLevel.SUCCESS] = (text, title?) => console.log(`success: ${title ? title : ''}: ${text}`);
    }

}