import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { RewardType } from '@gsegames/arena.models';

@Component({
  selector: 'select-rewardType',
  templateUrl: './select-rewardType.component.html',
  styleUrls: ['./select-rewardType.component.css']
})
export class SelectRewardTypeComponent implements OnInit {

  @Input() initialRewardType: RewardType = null;
  @Input() editDisabled: boolean;
  @Output() rewardTypeChanged = new EventEmitter<number>();

  rewardTypes = RewardType;
  name: string;
  names = Object.keys(this.rewardTypes);
  index : number;

  keys() : Array<string> {
    return this.names.slice(this.names.length / 2);
  }

  constructor() { }

  ngOnInit() {
    if(this.initialRewardType){
       this.name = this.names.find(element =>{ if(Number(element) == this.initialRewardType) return true; else{return false;}});
    }
    console.log(this.rewardTypes);
  }

  onRewardTypeChanged(rewardType){
    this.rewardTypeChanged.emit(Number(rewardType));
  }
}
