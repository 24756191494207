import { Injectable } from '@angular/core';
import { ViewModel } from 'src/models/ViewModel';
import { Subject } from 'rxjs';


@Injectable()
export class StorageService {

  queueStream: Subject<ViewModel<any>>
  remoteStream: Subject<ViewModel<any>>

  constructor() {
    this.queueStream = new Subject();
    this.remoteStream = new Subject();
  }

  saveToQueue(viewModel: ViewModel<any>) {
    this.queueStream.next(viewModel);
  }

  saveToRemote(viewModel: ViewModel<any>) {
    this.remoteStream.next(viewModel);
  }

}