import { Component, OnInit, ViewChild } from '@angular/core';
import { EventEntry, Goal, Competitor, Tournament, CampaignEntry } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { injectTranslation, getTranslations, getTranslationByKey } from 'src/core/translations';
import { translationPrefix } from 'src/constants/translation-prefixes';
import { ValidationChain } from 'src/core/validations';
import { SelectGoalsComponent } from '../../selectors/select-goals/select-goals.component';

@Component({
  selector: 'editor-event-entry',
  templateUrl: './editor-event-entry.component.html',
  styleUrls: ['./editor-event-entry.component.scss']
})
export class EditorEventEntryComponent extends Editor<EventEntry> implements OnInit {

  @ViewChild(SelectGoalsComponent) select: SelectGoalsComponent;
  translationPrefixMap = {
    'NameKey': translationPrefix.Event.Name,
    'LocationKey': translationPrefix.Event.Location,
  };

  constructor() { super(); }

  ngOnInit() {
   
    super.ngOnInit();
    //TO DO : add validator for the initial value for model.campaignId
    if(!this.model.Tournament){
      this.model.Tournament = new Tournament();
    }
    if(!this.model.CampaignId){
      this.model.CampaignId = "";
    }

    if(!this.model.Round.Week){
      this.model.Round = { Week : 0 };
    }
    
    this.viewModel.validators = {
      'NameKey': new ValidationChain('NameKey').translation(),
      'IsHot': new ValidationChain('IsHot').debug(),
      'Competitors': new ValidationChain('Competitors').noNullInList('_id').minLength(2).allUnique('_id'),
      'LocationKey': new ValidationChain('LocationKey').translation(),
      'LocationImageUrl': new ValidationChain('LocationImageUrl').notEmpty(),
      'Date': new ValidationChain('Date').debug(),
      'Round': new ValidationChain('Round').min(0).notEmpty(),
      'Tournament': new ValidationChain('Tournament').notNull(),
      'LightningEnergy': new ValidationChain('LightningEnergy').min(0),
      'Goals': new ValidationChain('Goals').notNull().notEmpty(),
      'Campaign': new ValidationChain('Campaign').notNull(),
    };

    this.viewModel.validators['NameKey'].execute(this.model.NameKey);
    this.viewModel.validators['IsHot'].execute(this.model.IsHot);
    this.viewModel.validators['Competitors'].execute(this.model.Competitors);
    this.viewModel.validators['LocationKey'].execute(this.model.LocationKey);
    this.viewModel.validators['LocationImageUrl'].execute(this.model.LocationImageUrl);
    this.viewModel.validators['Date'].execute(this.model.Date);
    if(this.model.Round){
      this.viewModel.validators['Round'].execute(this.model.Round['Week']);
    }
    this.viewModel.validators['Tournament'].execute(this.model.Tournament);
    this.viewModel.validators['LightningEnergy'].execute(this.model.LightningEnergy);
    this.viewModel.validators['Goals'].execute(this.model.Goals);
    this.viewModel.validators['Campaign'].execute(this.CampaignIdAndPosition);
    //TO DO : add validator for the initial value for model.campaignId
    if(!this.model.Tournament){
      this.model.Tournament = new Tournament();
    }
    console.log(this.model.NameKey);
  }

  translationFor(key) {
    let translation;
    if(this.model.TranslationsBundle){
      translation = this.model.TranslationsBundle.Translations.find( element => element.FieldName == key);
    }
    return translation;
  }

  onNameChanged(translation) {
    this.viewModel.validators['NameKey'].execute(translation);
    this.model.NameKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }

  onLocalChanged(translation) {
    this.viewModel.validators['LocationKey'].execute(translation);
    this.model.LocationKey = translation.Identifier;
    this.translationVerification(translation);
    this.emitChanges();
  }

  translationVerification(translation){
    if(!this.model.TranslationsBundle) this.model.TranslationsBundle = {Translations: [], Version: 0};
    let dataFound = this.model.TranslationsBundle.Translations.find( element => element.FieldName == translation.FieldName);
    if(dataFound)
      this.model.TranslationsBundle.Translations[this.model.TranslationsBundle.Translations.indexOf(dataFound)] = translation;
    else
      this.model.TranslationsBundle.Translations.push(translation);

    if(this.viewModel.remoteModel.TranslationsBundle.Version == this.model.TranslationsBundle.Version){
      this.model.TranslationsBundle.Version++;
    }
    console.log(this.model.TranslationsBundle);
  }

  onAddCompetitor() {
    this.model.Competitors.push({} as Competitor);
  }

  onRemoveCompetitor(index) {
    this.model.Competitors.splice(index, 1);
  }

  onCompetitorChanged(competitor, index) {
    this.model.Competitors[index] = competitor;
    this.viewModel.validators['Competitors'].execute(this.model.Competitors);
    this.emitChanges();
  }

  onLocationImageChange(locationImage) {
    this.viewModel.validators['LocationImageUrl'].execute(locationImage);
    this.model.LocationImageUrl = locationImage;
    this.emitChanges();
  }

  onTournamentChanged(tournament) {
    this.viewModel.validators['Tournament'].execute(tournament);
    this.model.Tournament = tournament;
    this.select.updateGoalListFromRemote();
    this.emitChanges();
  }

  onDateChanged(date: Date) {
    this.viewModel.validators['Date'].execute(date);
    this.model.Date = date;
    this.emitChanges();
  }

  onRoundChanged() {
    this.viewModel.validators['Round'].execute(this.model.Round['Week']);
    this.emitChanges();
  }

  onGoalsChanged(goals: Goal[]) {
    this.viewModel.validators['Goals'].execute(goals);
    this.model.Goals = goals;
    this.emitChanges();
  }

  onCampaignChanged(Campaign){
    this.viewModel.validators['Campaign'].execute(Campaign);
    this.model.CampaignId = Campaign.key;
    this.model.CampaignPosition = Campaign.position;
    this.emitChanges();
  }

  onIsHotChanged(isHot: boolean) {
    this.viewModel.validators['IsHot'].execute(isHot);
    this.model.IsHot = isHot;
    this.emitChanges();
  }

  get CampaignIdAndPosition() {
    if (this.viewModel.fromRemote) {
      return {
      key : this.model.CampaignId,
      position: this.model.CampaignPosition,
      };
    }
  }
  onLightningEnergyChanged(energy) {
    this.viewModel.validators['LightningEnergy'].execute(this.model.LightningEnergy);
    this.emitChanges();
  }
}
