import { Component, OnInit } from '@angular/core';
import { Question } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { injectTranslation } from 'src/core/translations';

@Component({
  selector: 'app-editor-question',
  templateUrl: './editor-question.component.html',
  styleUrls: ['./editor-question.component.scss']
})
export class EditorQuestionComponent extends Editor<Question> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onInstructionChanged(instructionTranslation) {
    this.model.InstructionKey = instructionTranslation.key;
    injectTranslation(instructionTranslation, this.viewModel);
  }
}
