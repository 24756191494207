import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { HighlightEntry, Filter, CampaignEntry, Validation } from '@gsegames/arena.models';
import { Editor } from '../editor';
import { SelectDatetimeComponent } from '../../selectors/select-datetime/select-datetime.component';
import { ValidationChain } from 'src/core/validations';
import { FilterWithDateTime } from '../../selectors/field-filter-with-datetime/field-filter-with-datetime.component';

@Component({
  selector: 'editor-highlight-entry',
  templateUrl: './editor-highlight-entry.component.html',
  styleUrls: ['./editor-highlight-entry.component.scss']
})
export class EditorHighlightEntryComponent extends Editor<HighlightEntry> implements OnInit {

  constructor() { super() }
  
  @ViewChildren(SelectDatetimeComponent) childrenSelectDate: QueryList<SelectDatetimeComponent>;

  ngOnInit() {
    
    super.ngOnInit();

    this.model.Date = new Date(this.model.Date);
    this.model.EndDate = new Date(this.model.EndDate);
    if(!this.model.CampaignId){
      this.model.CampaignId = "";
    }

    this.viewModel.validators = {
      Name: new ValidationChain('Name').notEmpty(),
      LinkUrl: new ValidationChain('LinkUrl').notEmpty().regex(/(https*:\/\/.+)|(arena:\/\/.+)/),
      ImageUrl: new ValidationChain('ImageUrl').notEmpty(),
      IsEnabled: new ValidationChain('IsEnabled').debug(),
      Position: new ValidationChain('Position').min(1),
      FilterWithDate: new ValidationChain('FilterWithDate').notNull().notEmpty().withFilter(),
      Campaign: new ValidationChain('Campaign').notNull(),
      // FilterWithDate: new ValidationChain('FilterWithDate').notNull().recursive([
      //   new ValidationChain('filter').notNull(),
      //   new ValidationChain('start').debug(),
      //   new ValidationChain('end').debug(),
      // ]),
    };

    this.viewModel.validators['Name'].execute(this.model.Name);
    this.viewModel.validators['LinkUrl'].execute(this.model.LinkUrl);
    this.viewModel.validators['ImageUrl'].execute(this.model.ImageUrl);
    this.viewModel.validators['IsEnabled'].execute(this.model.IsEnabled);
    this.viewModel.validators['Position'].execute(this.model.Position);
    this.viewModel.validators['FilterWithDate'].execute(this.viewModel.multipleFilterPackages);
    this.viewModel.validators['Campaign'].execute(this.CampaignIdAndPosition);
  }

  onNameChanged(name: string) {
    this.viewModel.validators['Name'].execute(name);
    this.emitChanges();
  }

  onLinkUrlChanged(url: string) {
    this.viewModel.validators['LinkUrl'].execute(url);
    this.emitChanges();
  }

  onStartDateChanged(date) {
    this.model.Date = date;

    this.childrenSelectDate
      .filter(item => item.identifier && item.identifier === 'end-date')
      .forEach(dateSelect => {
        dateSelect.raiseMinimum(date);
      });

    this.viewModel.validators['Date'].execute(date);
    this.emitChanges();
  }
  onEndDateChanged(date) {
    this.viewModel.validators['EndDate'].execute(date);
    this.model.EndDate = date;
    this.emitChanges();
  }

  onFilterChanged(filter: Filter) {
    this.viewModel.validators['Filter'].execute(filter);

    const startDate = filter.StartDate ? new Date(filter.StartDate) : null;
    const endDate = filter.EndDate ? new Date(filter.EndDate) : null;

    this.childrenSelectDate
      .forEach(dateSelect => {
        if (dateSelect.identifier && dateSelect.identifier === 'start-date') {
          dateSelect.raiseMinimum(startDate);
          dateSelect.lowerMaximum(endDate);
        } else if (dateSelect.identifier && dateSelect.identifier === 'end-date') {
          dateSelect.raiseMinimum(startDate);
          dateSelect.lowerMaximum(endDate);
        }
      });


    this.model.Filter = filter;
    this.emitChanges();
  }


  get filterWithDateTime(): FilterWithDateTime[] {
    if (this.viewModel.fromRemoteAndTemplate()) {
      return [{
        filter: this.model.Filter,
        start: this.model.Date,
        end: this.model.EndDate
      }];
    } else if (this.viewModel.multipleFilterPackages.length > 0) {
      return this.viewModel.multipleFilterPackages;
    } else {
      return null;
    }
  }

  onFilterWithDateTimeChanged(filterPackages: FilterWithDateTime[]) {
    this.viewModel.validators['FilterWithDate'].execute(filterPackages);
    this.viewModel.multipleFilterPackages = filterPackages;
    if(filterPackages.length == 1){
      this.model.Date = filterPackages[0].start;
      this.model.EndDate = filterPackages[0].end;
    }
    this.emitChanges();
  }

  onImageURLChanged(url) {
    this.viewModel.validators['ImageUrl'].execute(url);
    this.model.ImageUrl = url;
    this.emitChanges();
  }

  onScrollPosition(event) {
    event.preventDefault();

    let dir = 0

    if (event.deltaY > 0)      { dir = -1 }
    else if (event.deltaY < 0) { dir =  1 }

    this.model.Position = Math.max(1, this.model.Position + dir);
    this.viewModel.validators['Position'].execute(this.model.Position);
    this.emitChanges();
  }

  get CampaignIdAndPosition() {
    if (this.viewModel.fromRemote) {
      return {
      key : this.model.CampaignId,
      position: this.model.CampaignPosition,
      };
    }
  }

  onCampaignChanged(Campaign){
    this.viewModel.validators['Campaign'].execute(Campaign);
    this.model.CampaignId = Campaign.key;
    this.model.CampaignPosition = Campaign.position;
    this.emitChanges();
  }
}
