import { OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ViewModel } from 'src/models/ViewModel';
import { ViewData } from 'src/services/FeedInfo.service';

export class Editor<T> implements OnInit {
  @Input() viewModel: ViewModel<T>;
  model: T;

  @Output() viewData = new EventEmitter<ViewData>();
  @Output() changedViewModel = new EventEmitter<ViewModel<T>>();

  ngOnInit() {
    this.model = this.viewModel.localModel;
  }

  validationContext(itemId: string) {
    return this.viewModel.validators[itemId];
  }

  emitChanges() {
    this.changedViewModel.emit( this.viewModel );
  }
}
